import {Action} from "../actions/app";
import * as constants from "../constants";


export interface StatusState {
    verdictStatus: string;
    lawsStatus: string;
}

const getInitialState = () => {
    return {
        verdictStatus: "UP",
        lawsStatus: "UP"
    }
}

const status = (state: StatusState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCH_STATUS_LAWS: {
            return {...state, lawsStatus: action.payload};
        }

        case constants.FETCH_STATUS_VERDICTS: {
            return {...state, verdictStatus: action.payload};
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        default: {
            return {...state};
        }

    }
}

export default status;
