import API from "../../utils/api";
import {FETCHED_FORMS, FETCHED_JOURNALS, FETCHED_KEYWORDS, FETCHED_LAW_TERMS} from "../constants";
import {localStorageAccessTokenKey} from "../../utils/constants";

export const getJournals = () => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("forms/folders/revistat/file-names", {
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCHED_JOURNALS, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};

export const downloadJournal = (name: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("forms/folders/revistat/files?fileName=" + name, {
            headers: {"Authorization": "Bearer " + token}
        });
        return response;
    } catch (err) {
        return err;
    }
};
