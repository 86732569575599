import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Drawer, IconButton, styled} from "@mui/material";
import {RootState} from "../../redux/reducers/rootState";
import {setRightDrawer} from "../../redux/actions/app";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
    title: string;
    children: any;
    onClose?: any;
}

const RightDrawerHeader = styled('div')(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2)
}));

const RightDrawerContainer = styled('div')(({theme}) => ({
    padding: theme.spacing(2),
}));

const RightDrawer = (props: Props) => {

    const dispatch = useDispatch();
    const isDrawerOpen = useSelector((state: RootState) => state.app.isRightDrawerOpen);

    const sxConfig = {
        width: 420,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: 420,
            boxSizing: 'border-box',
        }
    };

    const handleCloseDrawer = () => {
        dispatch(setRightDrawer(false));
        props.onClose && props.onClose();
    }

    return (
        <Drawer
            sx={sxConfig}
            anchor="right"
            open={isDrawerOpen}
            onClose={handleCloseDrawer}>
            <RightDrawerHeader>
                <h2>{props.title}</h2>
                <IconButton aria-label="delete" size="medium" onClick={handleCloseDrawer}>
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            </RightDrawerHeader>
            <RightDrawerContainer>
                {props.children}
            </RightDrawerContainer>
        </Drawer>
    );
}

export default RightDrawer;
