import API from "../../utils/api";
import {FETCHED_KEYWORDS} from "../constants";
import {localStorageAccessTokenKey} from "../../utils/constants";

export const getKeywords = () => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("frequent-keywords/four-grams", {
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCHED_KEYWORDS, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};
