import React from 'react';
import {Box, Typography, Link} from "@mui/material";

type LabeledValueTypes = "normal" | "date" | "link" | "status";

type LabeledValueProps = {
    type: LabeledValueTypes;
    label: string;
    value?: string;
    styles?: any;
    onSaveSearch?: () => void;
};

export default function LabeledValue({ type, label, value, onSaveSearch }: LabeledValueProps) {

    return (
        <Box sx={{ mt: 1 }}>
            {type === "date" && (
                <>
                    <Typography variant="body2" color="text.secondary">
                        {label}
                    </Typography>
                    <Typography variant="body2" sx={{fontWeight: 'bold'}} color="text.secondary">
                        {value && value !== "null" && value !== 'dd.mm.yyyy' ? value.substring(0, 10) : 'Shih brenda dokumentit'}
                    </Typography>
                </>
            )}
            {type === "normal" && (
                <>
                    <Typography variant="body2" color="text.secondary">
                        {label}
                    </Typography>
                    <Typography variant="body2" sx={{fontWeight: 'bold'}} color="text.secondary">
                        {value && value !== "null" && value}
                    </Typography>
                </>
            )}
            {type === "status" && (
                <>
                    <Typography variant="body2" color="text.secondary">
                        {label}
                    </Typography>
                    <Typography variant="body2" sx={{ padding: '1px 5px 1px 5px', width: '90px', textAlign: 'center', borderRadius: '7px', fontWeight: 'bold', color: 'white', backgroundColor: value === 'NDRYSHUAR' ? '#fec752' : value === 'SHFUQIZUAR' ? '#DB4437' : '#5DB847'}} color="text.secondary">
                        {value}
                    </Typography>
                </>
            )}
            {type === "link" && (
                <>
                    <Typography sx={{display: 'none'}} variant="body2" color="text.secondary">
                        {label}
                    </Typography>
                    <Typography onClick={onSaveSearch && onSaveSearch} variant="subtitle1" color="text.secondary" gutterBottom>
                        {value && value !== "null" && <Link href={value} target="_blank">Linku zyrtar</Link>}
                    </Typography>
                </>
            )}
        </Box>
    );
};