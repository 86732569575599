import {Button, Card, CardContent, Typography, Container} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

import styles from "./FormCard.module.scss";

interface Props {
    form: any;
    onDownloadClick: any;
}

export default function FormCard(props: Props) {

    const {t, i18n} = useTranslation();


    return (
        <Card sx={{ mb: 1.5, boxShadow: (theme) => theme.shadows[2] }}>
            <CardContent className={styles.FormCardComponent}>
                <Typography variant="subtitle1" color="text.secondary" sx={{ wordWrap: 'break-word' }}>
                    {props.form}
                </Typography>
                <Button
                    size="small"
                    onClick={props.onDownloadClick}>
                    {t("Shkarko")}
                </Button>
            </CardContent>
        </Card>
    )
}
