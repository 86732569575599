import React, {useEffect, useState} from 'react';
import {Box, Chip, Container, Typography, Grid, Card, CardContent } from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootState";
import VerdictCard from "../../../components/VerdictCard";
import DocumentsSkeleton from "../../../components/DocumentsSkeleton";
import {
    getVerdicts,
    getVerdictsLastUpdated,
    getVerdictsWithFilters,
    setVerdictsLoading
} from "../../../redux/actions/verdicts";
import NoResults from "../../../components/NoResults";
import Pagination from "../../../components/Pagination";
import {paginationPageSize, SearchTypes} from "../../../utils/constants";
import {setVerdictsSearchFilters} from "../../../redux/actions/search";
import {setVerdictsFilter} from "../../../redux/actions/verdictsFilters";
import { getDocuments } from '../../../redux/actions/documents';
import moment from "moment";

interface Props {
    changeTab: (tab: number) => void;
}

function VerdictsTab(props: Props) {

    const dispatch: any = useDispatch();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(40);

    const searchText = useSelector((state: RootState) => state.search.searchText);
    const searchType = useSelector((state: RootState) => state.search.searchType);
    const verdictFilters = useSelector((state: RootState) => state.verdictsFilters.appliedVerdictsFilters);
    const verdicts = useSelector((state: RootState) => state.verdicts.verdicts);
    const totalVerdicts = useSelector((state: RootState) => state.verdicts.totalVerdicts);
    const verdictsLoading = useSelector((state: RootState) => state.verdicts.verdictsLoading);
    const hasSubscriptions = useSelector((state: RootState) => state.auth.hasSubscriptions);
    const favList = useSelector((state: RootState) => state.favorites.favoriteDocuments);
    const verdictsLastUpdated = useSelector((state: RootState) => state.verdicts.verdictsLastUpdated);
    const [foundDocuments, setFoundDocuments] = useState(false);

    const pagesCount = Math.ceil(verdicts.length / 10);

    const filtersApplied = Object.keys(verdictFilters).some(filter => verdictFilters[filter]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        if(value > pagesCount) {
            setPageSize(pageSize + 20);
        } else {
            setPage(value);
        }
    };

    const fetchVerdicts = () => {
        const payload: any = {
            maxResults: pageSize,
            phrase: searchText
        }

        if(searchText) {
            dispatch(setVerdictsLoading(true));
            setFoundDocuments(false);

            if(searchType === SearchTypes.Name) {
                dispatch(getVerdictsWithFilters("", { documentTitle: searchText, ...verdictFilters }, pageSize));
            } else if(filtersApplied && searchType === SearchTypes.Phrase) {
                dispatch(getVerdictsWithFilters(searchText, verdictFilters, pageSize));
            } else {
                dispatch(getVerdicts(payload)).then((res: any) => {
                    if(res.data?.data?.length === 0) {
                        dispatch(getDocuments(payload)).then((res: any) => {
                            if(res.data?.data?.length >= 1){
                                setFoundDocuments(true);
                            }
                        });
                    }
                });
                
            }
        }
    }

    useEffect(() => {
        setPage(1);
        setPageSize(40);
    }, [searchText]);

    useEffect(() => {
        fetchVerdicts()
    }, [searchText, verdictFilters, pageSize]);

    useEffect(() => {
    }, []);

    const isFavorited = (verdict: any) => {
        const itemExists = favList.data.filter((item: any) => item.documentId.toString() === verdict.id.toString());
        if (itemExists.length > 0) {
            return itemExists[0].id;
        }else{
            return false;
        }
    }

    return (
        <Box sx={{mt: 1.5}}>

            {/* {filtersApplied && <Box sx={{ mb: 2, display: "flex", alignItems: "center"  }}>
                <Typography sx={{ mr: 1 }}>
                    Filtrat e aplikuar:
                </Typography>
                {Object.keys(verdictFilters).map((key, index) => {
                    return verdictFilters[key] && <Chip key={index} sx={{ mr: 1 }} label={verdictFilters[key]} onDelete={() => dispatch(setVerdictsFilter({ name: key, value: null }))}/>
                })}
            </Box>} */}

            {verdictsLoading && <DocumentsSkeleton/>}

            {!verdictsLoading && totalVerdicts > 0 && (
                <p style={{marginBottom: '20px', color:'#808080'}}>Janë gjetur <b>{totalVerdicts?.toLocaleString()}</b> rezultate</p>
            )}

            {!verdictsLoading && favList && (
                verdicts.length
                    ? verdicts.map((verdict: any, index) => {
                        const previousPages = (page-1)*paginationPageSize;
                        const nextPages = page*paginationPageSize;
                        const isVisible = ((index + 1) > previousPages && (index) < nextPages);
                        return isVisible && <VerdictCard key={`verdict-card-${index}`} verdict={verdict} isFavorited={isFavorited(verdict)} />
                    }) : <NoResults found={foundDocuments} current="verdicts" changeTab={(tab: number) => props.changeTab(tab)} />
            )}

            {!!(searchText && !verdictsLoading && verdicts.length) &&
            <Pagination
                page={page}
                count={pagesCount}
                more={pagesCount < 10 && !(verdicts.length < pagesCount * 10)}
                onChange={handlePageChange} />}

        </Box>
    );
}

export default VerdictsTab;
