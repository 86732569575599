import React, {useState} from 'react';
import {Avatar, Box, Grid, IconButton, Link, TextField, Typography} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useDispatch} from "react-redux";
import {authenticate, getIpAddress, getUserDetails} from "../../redux/actions/auth";
import {useHistory} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {useFormik} from "formik";
import * as yup from "yup";
import {VERSION} from "../../utils/constants";
import LoginWithGoogle from "../../components/LoginWithGoogle";

interface Props {

}

const validationSchema = yup.object({
    email: yup.string().email('Enter a valid email').required("Email is required"),
    password: yup.string().required("Password is required"),
});

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            Të gjitha të drejtat e rezervuara!
        </Typography>
    );
}


function Version(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            v{VERSION}
        </Typography>
    );
}

function Login(props: Props) {

    const dispatch: any = useDispatch();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: async(values) => {
            setLoading(true);
            dispatch(getIpAddress())
                .then((res: any) => {
                    const ipAddress = res.data;
                    const data = `grant_type=password&username=${encodeURIComponent(values.email?.trim())}&password=${encodeURIComponent(values.password?.trim())}&using_ip=${encodeURIComponent(ipAddress)}`;
                    dispatch(authenticate(data))
                        .then((res: any) => {
                            history.push("/search");
                            dispatch(getUserDetails());
                            // if(res.data.using_ip === false) {
                            //     dispatch(sendVerificationEmail())
                            //         .then((res: any) => history.push("/search"))
                            //         .catch((err: any) => setLoading(false));
                            // } else {
                            //     history.push("/search");
                            //     // toast.success("Logged in!")
                            // }
                        }).catch((err: any) => {
                            setLoading(false)
                            if(err.response.data.error === 'invalid_grant' || err.response.data.error === 'unauthorized'){
                                setErrMsg("E-mail adresa ose fjalëkalimi është gabim!");
                            }
                        });
                }).catch((err: any) => {
                    console.log("Err", err);
                    setLoading(false)
                });
        },
    });

    return (
        <React.Fragment>
            <Box
                sx={{
                    marginTop: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Kyçu
                </Typography>
                <Box
                    noValidate
                    component="form"
                    onSubmit={formik.handleSubmit}
                    sx={{
                        mt: 3, maxWidth: "415px",
                        ['@media (max-width:756px)']: {
                            maxWidth: "80vw",
                        }
                    }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email adresa"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email} />

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Fjalëkalimi"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password} />

                    {/*<FormControlLabel*/}
                    {/*    control={<Checkbox value="remember" color="primary" />}*/}
                    {/*    label="Remember me"*/}
                    {/*/>*/}
                    <Typography variant="body2" color="text.secondary" sx={{color: 'red'}}>
                        {errMsg}
                    </Typography>
                    <LoadingButton
                        loading={loading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2, borderRadius: '0.8rem'}}>
                        Kyçu
                    </LoadingButton>

                    <Grid container>
                        <Grid item xs>
                            <Link href="/register" variant="body2">
                                Regjistrohu
                            </Link>
                        </Grid>
                        <Grid item xs sx={{ textAlign: "right" }}>
                            <Link href="/password/forgot" variant="body2">
                                Keni harruar fjalëkalimin?
                            </Link>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item sx={{ mx: "auto", mt: 2 }} xs={12}>
                            <LoginWithGoogle text={'Kyçu me Google'} />
                        </Grid>
                        <Grid item sx={{ mx: "auto" }}>
                            <IconButton aria-label="delete" size="large"
                                        onClick={() => window.open("https://www.facebook.com/lexdoks.org", "_blank")}>
                                <FacebookIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton aria-label="delete" size="large"
                                        onClick={() => window.open("https://www.linkedin.com/company/75422945", "_blank")}>
                                <LinkedInIcon fontSize="inherit" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{mt: 1, mb: 1}}/>
            <Version sx={{mt: 1, mb: 4}}/>
        </React.Fragment>
    );
}

export default Login;
