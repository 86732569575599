import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import sq from "./assets/locales/sq.json";
import sr from "./assets/locales/sr.json";
import en from "./assets/locales/en.json";

// i18n.changeLanguage('en-US');

const resources: any = {
    sq: {
        translation: sq
    },
    sr: {
        translation: sr
    },
    en: {
        translation: en
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "sq",
        fallbackLng: 'sq',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: false
        }

    });

export default i18n;
