import {Action} from "../actions/app";
import * as constants from "../constants";

export interface UsersState {
    users: Array<any>
    usersLoading: boolean;
}

const getInitialState = () => {
    return {
        users: [],
        usersLoading: true,
    }
}

const users = (state: UsersState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_USERS: {
            return {...state, users: action.payload, usersLoading: false};
        }

        case constants.SET_USERS_LOADING: {
            return {...state, usersLoading: action.payload};
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        default: {
            return {...state};
        }

    }
}

export default users;
