import React, {useEffect} from 'react';
import {
    Button,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/reducers/rootState";
import {clearDocumentsSearchFilters, setDocumentsSearchFilters} from "../../redux/actions/search";
import CloseIcon from '@mui/icons-material/Close';
import {setVerdictsFilter, setVerdictsFilters} from "../../redux/actions/verdictsFilters";
import {clearDocumentsFilters, setDocumentsFilter, setDocumentsFilters} from "../../redux/actions/documentsFilters";
import styles from "./DocumentFilters.module.scss";

interface Props {

}

// const filters = [
//     {
//         labelAl: "Statusi",
//         name: "status",
//         values: [
//             {label: "Të gjitha", value: ""},
//             {label: "Në fuqi", value: "IN FORCE"},
//             {label: "I ndryshuar", value: "AMENDED"},
//             {label: "I shfuqizuar", value: "ABOLISHED"}
//         ]
//     }
// ]

const filterNameToLabelMapping: any = {
    "documentStatus": "Statusi",
    "institution": "Institucioni",
    "documentType": "Lloji i aktit",
};

const DocumentFilters = (props: Props) => {

    const dispatch = useDispatch();

    const allFilters = useSelector((state: RootState) => state.documentsFilters.allDocumentsFilters);
    const documentsFilters = useSelector((state: RootState) => state.documentsFilters.documentsFilters);
    const appliedFilters = useSelector((state: RootState) => state.documentsFilters.appliedDocumentsFilters);

    const filtersApplied = Object.keys(appliedFilters).some((filter) => appliedFilters[filter]);

    const filters: any = Object.keys(appliedFilters).map((filterKey) => {
        const filterExists = documentsFilters.find((filter: any) => filter.name === filterKey);
        if (filterExists) {
            return filterExists;
        } else {
            const randomFilter = allFilters.find((filter) => filter.name === filterKey);
            if (randomFilter) {
                randomFilter.values = [];
            }
            return randomFilter;
        }
    });

    const handleFilterChanged = (event: SelectChangeEvent) => {
        // const filters = allFilters.find((filter) => filter.value === event.target.value)?.filters || [];
        dispatch(setDocumentsFilter({name: event.target.name, value: event.target.value}));
        // dispatch(setVerdictsFilters(filters));

    }

    const handleFilterDelete = (filter: any) => {
        dispatch(setDocumentsFilter({name: filter, value: ""}));
    }

    useEffect(() => {

        const finalFilters: any = {...appliedFilters};
        Object.keys(appliedFilters).forEach((filterKey) => { //loop through the currently applied filters obj
            const filterValue = appliedFilters[filterKey]; //get the value of the applied filter
            if (filterValue) {
                const newFilters = allFilters.find((filter) => filter.value === filterValue); //find the filter by value on allFilters
                if (newFilters) {
                    const filters = {...newFilters.filters}; //get the associated filters of the found filter
                    Object.keys(filters).forEach((newFilterKey) => { //loop through the associated filters
                        if (newFilterKey !== filterKey) { //we need to apply new filters (options) only to the filters that are not applied (filterKey belongs to applied)
                            if (finalFilters[newFilterKey]) { //we need to intersect only when we have already added filters to that key
                                if (!appliedFilters[newFilterKey]) { //we nned to intersect only when that filter is applied
                                    const newFilters = [...filters[newFilterKey]];
                                    finalFilters[newFilterKey] = finalFilters[newFilterKey].filter((filter: any) => newFilters.includes(filter)) //we only add filters that were already there included
                                }
                            } else {
                                finalFilters[newFilterKey] = [...filters[newFilterKey]] //otherwise we just insert the new filters entirely
                            }
                        } else {
                            finalFilters[filterKey] = [filterValue] //if the filter is applied we just set one value inside it, which is the applied filter (we do not touch/change it)
                        }
                    });
                }
            }
        });

        if (Object.values(finalFilters).every(value => value)) {
            let newFilterOptions: any = [];
            Object.keys(finalFilters).forEach((filterKey) => { //loop through the new filter object that was calculated
                //form an array with the original filter objects that were received from the backend
                const filterObjects = finalFilters[filterKey].map((filterValue: any) => {
                    return allFilters.find((filter) => filter.value == filterValue && filter.name == filterKey);
                })
                newFilterOptions = [...newFilterOptions, ...filterObjects];
            });

            dispatch(setDocumentsFilters(newFilterOptions));
        } else {
            dispatch(setDocumentsFilters(allFilters));
        }


    }, [appliedFilters]);

    // useEffect(() => {
    //     documentsFilters.forEach((filter) => {
    //        if(!appliedFilters[filter.name] && filter.values.length === 1){
    //            const filterToApply = filter.values[0];
    //            dispatch(setDocumentsFilter({ name: filter.name, value: filterToApply.value }));
    //        }
    //     });
    // }, [documentsFilters])

    const clearFilters = () => {
        dispatch(clearDocumentsFilters());
    }

    return (
        <Box>

            <Grid container spacing={2} sx={{mt: .5}}>
                {filters.map((filter: any, index: number) => (
                    filter && <Grid item xs={12} md={3} key={index}>
                        <FormControl fullWidth size="small" disabled={!filter.values.length}>
                            <InputLabel
                                id="demo-simple-select-label">{filter.labelAl || filterNameToLabelMapping[filter.name]}</InputLabel>
                            <Select
                                className={appliedFilters[filter.name] && styles.hideIcon}
                                sx={{backgroundColor: 'white', borderRadius: '.7rem'}}
                                fullWidth
                                label={filter.labelAl || filterNameToLabelMapping[filter.name]}
                                name={filter.name}
                                value={appliedFilters[filter.name] || ""}
                                MenuProps={{
                                    style: {
                                        maxHeight: 440,
                                    },
                                }}
                                onChange={handleFilterChanged}>
                                {filter.values.sort((a: any, b: any) => a.value.localeCompare(b.value))
                                    .map((value: any, index: number) =>
                                        <MenuItem
                                            key={`document-filter-dropdown-item-${index}`}
                                            value={value.value}>{value.label}</MenuItem>
                                    )}
                            </Select>
                            {appliedFilters[filter.name] && (
                                <IconButton sx={{position: 'absolute', right: '1px', top: '0px'}}
                                            onClick={() => handleFilterDelete(filter.name)}>
                                    <CloseIcon/>
                                </IconButton>
                            )}
                        </FormControl>
                    </Grid>
                ))}
            </Grid>

            {filtersApplied && (
                <Box sx={{display: 'flex', justifyContent: 'end'}}>
                    <Button onClick={clearFilters}>
                        Fshij filtrat
                    </Button>
                </Box>
            )}

        </Box>
    );
}

export default DocumentFilters;
