import {Action} from "../actions/app";
import * as constants from "../constants";

export interface FavoritesState {
    favoriteDocuments: any;
}

const getInitialState = () => {
    return {
        favoriteDocuments: []
    }
}

const favorites = (state: FavoritesState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        case constants.SET_MY_FAVORITE_DOCUMENTS: {
            return {
                ...state,
                favoriteDocuments: action.payload
            }
        }

        default: {
            return {...state};
        }

    }
}

export default favorites;
