export const SET_LOADING = "SET_LOADING";
export const SET_DRAWER = "SET_DRAWER";
export const SET_RIGHT_DRAWER = "SET_RIGHT_DRAWER";

export const FETCHED_KEYWORDS = "FETCHED_KEYWORDS";
export const FETCHED_LAW_TERMS = "FETCHED_LAW_TERMS";
export const FETCHED_FORMS = "FETCHED_FORMS";
export const FETCHED_JOURNALS = "FETCHED_JOURNALS";

export const FETCH_STATUS_LAWS = "FETCH_STATUS_LAWS";
export const FETCH_STATUS_VERDICTS = "FETCH_STATUS_VERDICTS";

export const FETCHED_DOCUMENTS = "FETCHED_DOCUMENTS";
export const FETCHED_DOCUMENT = "FETCHED_DOCUMENT";
export const FETCHED_DOCUMENTS_LAST_UPDATED = "FETCHED_DOCUMENTS_LAST_UPDATED";
export const SET_DOCUMENTS_LOADING = "SET_DOCUMENTS_LOADING";
export const SET_DOCUMENT_LOADING = "SET_DOCUMENT_LOADING";
export const SET_DOCUMENTS_PAGE_SIZE = "SET_DOCUMENTS_PAGE_SIZE";

export const FETCHED_VERDICTS = "FETCHED_VERDICTS";
export const FETCHED_VERDICT = "FETCHED_VERDICT";
export const FETCHED_VERDICTS_LAST_UPDATED = "FETCHED_VERDICTS_LAST_UPDATED";
export const SET_VERDICTS_LOADING = "SET_VERDICTS_LOADING";
export const SET_VERDICTS_PAGE_SIZE = "SET_VERDICTS_PAGE_SIZE";

export const FETCHED_VERDICTS_FILTERS = "FETCHED_VERDICTS_FILTERS";
export const SET_VERDICTS_FILTERS = "SET_VERDICTS_FILTERS";
export const CLEAR_VERDICTS_FILTERS = "CLEAR_VERDICTS_FILTERS";
export const SET_VERDICTS_FILTER = "SET_VERDICTS_FILTER";
export const SET_VERDICTS_FILTERS_LOADING = "SET_VERDICTS_FILTERS_LOADING";
export const SET_VERDICTS_FILTERS_FROM_LATEST_SEARCH = "SET_VERDICTS_FILTERS_FROM_LATEST_SEARCH";

export const FETCHED_VERDICTS_NAMES = "FETCHED_VERDICTS_NAMES";
export const SET_VERDICTS_NAMES_LOADING = "SET_VERDICTS_NAMES_LOADING";

export const FETCHED_DOCUMENTS_FILTERS = "FETCHED_DOCUMENTS_FILTERS";
export const SET_DOCUMENTS_FILTERS = "SET_DOCUMENTS_FILTERS";
export const CLEAR_DOCUMENTS_FILTERS = "CLEAR_DOCUMENTS_FILTERS";
export const SET_DOCUMENTS_FILTER = "SET_DOCUMENTS_FILTER";
export const SET_DOCUMENTS_FILTERS_LOADING = "SET_DOCUMENTS_FILTERS_LOADING";
export const SET_DOCUMENTS_FILTERS_FROM_LATEST_SEARCH = "SET_DOCUMENTS_FILTERS_FROM_LATEST_SEARCH";

export const FETCHED_DOCUMENTS_NAMES = "FETCHED_DOCUMENTS_NAMES";
export const SET_DOCUMENTS_NAMES_LOADING = "SET_DOCUMENTS_NAMES_LOADING";

export const FETCHED_USERS = "FETCHED_USERS";
export const SET_USERS_LOADING = "SET_USERS_LOADING";

export const FETCHED_SUBSCRIPTIONS = "FETCHED_SUBSCRIPTIONS";
export const SET_SUBSCRIPTIONS_LOADING = "SET_SUBSCRIPTIONS_LOADING";

export const FETCHED_PLANS = "FETCHED_PLANS";
export const SET_PLANS_LOADING = "SET_PLANS_LOADING";

export const AUTHENTICATED = "AUTHENTICATED";
export const EMAIL_VERIFIED = "EMAIL_VERIFIED";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const LOGGED_OUT = "LOGGED_OUT";
export const HAS_SUBSCRIPTIONS = "HAS_SUBSCRIPTIONS";
export const USER_DETAILS = "USER_DETAILS";


export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_SEARCH_TYPE = "SET_SEARCH_TYPE";
export const SET_DOCUMENTS_SEARCH_FILTERS = "SET_DOCUMENTS_SEARCH_FILTERS";
export const CLEAR_DOCUMENTS_SEARCH_FILTERS = "CLEAR_DOCUMENTS_SEARCH_FILTERS";
export const SET_VERDICTS_SEARCH_FILTERS = "SET_VERDICTS_SEARCH_FILTERS";
export const CLEAR_VERDICTS_SEARCH_FILTERS = "CLEAR_VERDICTS_SEARCH_FILTERS";
export const SET_LATEST_SEARCHES = "SET_LATEST_SEARCHES";
export const DELETE_SAVED_SEARCH = "DELETE_SAVED_SEARCH";

export const SET_MY_FAVORITE_DOCUMENTS = "SET_MY_FAVORITE_DOCUMENTS";
