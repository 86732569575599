import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import ProgressSpinner from "../../components/ProgressSpinner";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/reducers/rootState";
import {toast} from "react-hot-toast";
import {createSubscription, getSubscriptions} from "../../redux/actions/subscriptions";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import {useFormik} from "formik";
import {changePassword} from "../../redux/actions/password";
import * as yup from "yup";
import {getPlans} from "../../redux/actions/plans";
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {pdf} from '@react-pdf/renderer';
import InvoicePdf from "../../components/InvoicePdf";
import {saveAs} from 'file-saver';
import {plansMap} from "../../utils/constants";

interface Props {
}

type ModalContent = 'plans' | 'loading' | 'success';


const planTypes: any = {
    FREE_TRIAL: '1 muaj',
    INDIVIDUAL_SEMIANNUAL: '6 muaj',
    INDIVIDUAL_YEARLY: '12 muaj',
}

const validationSchema = yup.object({
    oldPassword: yup.string().required("Current password is required"),
    newPassword: yup.string().required("New password is required"),
    confirmNewPassword: yup.string().required("Password confirmation is required").oneOf([yup.ref('newPassword'), null], 'Passwords must match')
});

const SubscriptionsTableHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
}));

function Subscriptions(props: Props) {

    const dispatch: any = useDispatch();
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);
    const subscriptionsLoading = useSelector((state: RootState) => state.subscriptions.subscriptionsLoading);
    const user = useSelector((state: RootState) => `${state.auth.firstName} ${state.auth.lastName}`);

    const plans = useSelector((state: RootState) => state.plans.plans);

    const [searchText, setSearchText] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState<ModalContent>("plans");
    const [selectedSubscription, setSelectedSubscription] = useState<any>(null);

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: ""
        },
        validationSchema: validationSchema,
        onSubmit: async(values: any) => {
            setLoading(true);
            await dispatch(changePassword(values))
                .then((res: any) => {
                    toast.success("Fjalëkalimi juaj u ndërrua!")
                    setLoading(false);
                })
                .catch((err: any) => setLoading(false));
        },
    });

    const filteredSubscriptions = subscriptions.filter(subscription => subscription.plan.type.toLowerCase().includes(searchText.toLowerCase()));

    useEffect(() => {
        dispatch(getPlans());
        dispatch(getSubscriptions());
    }, [dispatch]);

    const handleModalClose = () => {
        setModalContent('plans');
        setIsModalOpen(false);
    }

    const handleChoosePlan = (plan: any) => {
        setModalContent('loading');
        dispatch(createSubscription(plan.id))
            .then((res: any) => {
                setSelectedSubscription(res.data)
                dispatch(getSubscriptions());
                setModalContent('success');
            });
    }

    const generateInvoice = async (subscription: any) => {
        if(subscription?.plan) {
            const blob = await pdf((
                <InvoicePdf
                    name={user}
                    plan={subscription.plan.type}
                    invoiceNr={subscription.invoiceNr}
                    price={subscription.plan.price}
                />
            )).toBlob();
            saveAs(blob, `lexdoks-invoice-${subscription.invoiceNr}.pdf`);
        }
    }

    return (
        <Container>
            <Card style={{ padding: '5px' }}>
                <h4 style={{marginLeft: '10px'}}>Ndrysho fjalëkalimin</h4>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="normal"
                                id="oldPassword"
                                label="Fjalëkalimi i vjetër"
                                name="oldPassword"
                                type="password"
                                value={formik.values.oldPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                                helperText={formik.touched.oldPassword && formik.errors.oldPassword}/>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField
                                fullWidth
                                margin="normal"
                                id="newPassword"
                                label="Fjalëkalimi i ri"
                                name="newPassword"
                                type="password"
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                helperText={formik.touched.newPassword && formik.errors.newPassword}/>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField
                                fullWidth
                                margin="normal"
                                id="confirmNewPassword"
                                label="Konfirmo fjalëkalimin e ri"
                                name="confirmNewPassword"
                                type="password"
                                value={formik.values.confirmNewPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                                helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}/>
                        </Grid>
                    </Grid>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <LoadingButton
                            loading={loading}
                            type="submit"
                            variant="contained"
                            sx={{mt: 2, mb: 2}}>
                            Ndërro fjalëkalimin
                        </LoadingButton>
                    </div>
                </form>
            </Card>
        </Container>
    );
}

export default Subscriptions;
