import API from "../../utils/api";
import {
    FETCHED_DOCUMENTS,
    FETCHED_VERDICTS,
    FETCHED_VERDICT,
    SET_DOCUMENTS_LOADING,
    SET_VERDICTS_LOADING, FETCHED_VERDICTS_LAST_UPDATED
} from "../constants";
import {Action} from "./app";
import {convertObjectToFormData} from "../../utils";
import {localStorageAccessTokenKey} from "../../utils/constants";
import {getVerdictsStatus} from "./status";

export const setVerdictsLoading = (value: boolean): Action => ({
    type: SET_VERDICTS_LOADING,
    payload: value
})

export const getVerdicts = (payload: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("verdicts", {
            params: payload,
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCHED_VERDICTS, payload: response.data });
        dispatch(getVerdictsStatus());
        return response;
    } catch (err) {
        return err;
    }
};

export const getVerdictsWithFilters = (phrase: string, payload: any, maxResults: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    const formData = convertObjectToFormData(payload);
    try {
        const response = await API.post(`verdicts?phrase=${phrase}&maxResults=${maxResults}`, formData,{
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCHED_VERDICTS, payload: response.data });
        dispatch(getVerdictsStatus());
        return response;
    } catch (err) {
        return err;
    }
};

export const getVerdictWithFilters = (phrase: string, payload: any, maxResults: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    const formData = convertObjectToFormData(payload);
    try {
        const response = await API.post(`verdicts?phrase=${phrase}&maxResults=${maxResults}`, formData,{
            headers: {"Authorization": "Bearer " + token}
        });
        if(response.data.data?.[0]) {
            dispatch({ type: FETCHED_VERDICT, payload: response.data.data[0] });
        }else{
            dispatch(getVerdictById(payload, 1));
        }
        dispatch(getVerdictsStatus());
        return response;
    } catch (err) {
        return err;
    }
};

export const getVerdictById = (payload: any, maxResults: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    const formData = convertObjectToFormData(payload);
    try {
        const response = await API.post(`verdicts?phrase=&maxResults=${maxResults}`, formData,{
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCHED_VERDICT, payload: response.data.data[0] });
        dispatch(getVerdictsStatus());
        return response;
    } catch (err) {
        return err;
    }
};

export const getVerdictsLastUpdated = () => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get(`/verdicts/last-updated`,{
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCHED_VERDICTS_LAST_UPDATED, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};
