import React, {useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useDispatch} from "react-redux";
import {authenticateClient, register} from "../../redux/actions/auth";
import {useHistory} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {toast} from "react-hot-toast";
import {useFormik} from "formik";
import * as yup from "yup";
import {professionOptions} from "../../utils/constants";
import LoginWithGoogle from "../../components/LoginWithGoogle";

interface Props {

}

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            Të gjitha të drejtat e rezervuara!
        </Typography>
    );
}

const validationSchema = yup.object({
    firstName: yup.string().required("Ju lutemi plotësoni emrin"),
    lastName: yup.string().required("Ju lutemi plotësoni mbiemrin"),
    profession: yup.string().required("Ju lutemi plotësoni profesionin"),
    email: yup.string().email('Email adresa nuk është valide').required("Ju lutemi plotësoni email adresën"),
    remember: yup.bool().oneOf([true], 'Në pajtim me legjislacionin në fuqi, regjistrimi nuk është i mundur nëse nuk pranoni që të dhënat tuaja të procesohen')
});

function Register(props: Props) {

    const dispatch: any = useDispatch();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            profession: "",
            remember: false
        },
        validationSchema: validationSchema,
        onSubmit: async(values) => {
            setLoading(true);
            const newValues = {
                ...values,
                email: values.email?.trim()
            }
            dispatch(authenticateClient())
                .then((res: any) => {
                    const token = res.data.access_token;
                    dispatch(register(newValues, token))
                        .then((res: any) => {
                            history.push("/login");
                            toast.success("Regjistrimi u krye me sukses, të dhënat për kyçje mund ti gjeni ne email! (Shiko në: Inbox ose Promotions)" )
                        }).catch((err: any) => {
                            setLoading(false)
                            console.log("err", err);
                            if(err.response.data.message === 'Kjo email addresë ekziston!'){
                                setErrMsg("Kjo email adresë ekziston! Ju lutem provoni të kyçeni!");
                            }
                        });
                }).catch((err: any) => setLoading(false));
        },
    });

    return (
        <React.Fragment>
            <Box
                sx={{
                    marginTop: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Regjistrohu
                </Typography>
                <Box component="form" onSubmit={formik.handleSubmit} noValidate
                     sx={{
                         mt: 3,
                         maxWidth: "415px",
                         ['@media (max-width:756px)']: {
                             maxWidth: "80vw",
                         }
                     }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="firstName"
                        label="Emri"
                        name="firstName"
                        autoFocus
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.email && formik.errors.firstName} />

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Mbiemri"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName} />

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email adresa"
                        name="email"
                        autoComplete="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email} />
                    {/*<FormControlLabel*/}
                    {/*    control={<Checkbox value="remember" color="primary" />}*/}
                    {/*    label="Remember me"*/}
                    {/*/>*/}

                    <FormControl
                        fullWidth
                        margin="normal"
                        error={formik.touched.profession && Boolean(formik.errors.profession)}>
                        <InputLabel id="profession-input-label">Profesioni</InputLabel>
                        <Select
                            labelId="profession-input-label"
                            id="plan-input"
                            label="Profesioni"
                            name="profession"
                            value={formik.values.profession}
                            onChange={formik.handleChange}>
                            {professionOptions.map((profession: any) =>
                                <MenuItem value={profession.value}>{profession.label}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText>{formik.touched.profession && formik.errors.profession}</FormHelperText>
                    </FormControl>


                    <FormControl
                        fullWidth
                        margin="normal"
                        error={formik.touched.remember && Boolean(formik.errors.remember)}>
                        <FormControlLabel
                            sx={{ mt: 2 }}
                            name="remember"
                            value={formik.values.remember}
                            onChange={formik.handleChange}
                            control={<Checkbox value="agree" color="primary" />}
                            label={<>Jap pëlqimin për procesimin e të dhënave personale të ofruara në pajtim me <b>LIGJIN NR.06/L-082</b></>}
                        />
                        <FormHelperText>{formik.touched.remember && formik.errors.remember}</FormHelperText>
                    </FormControl>

                    <Typography variant="body2" color="text.secondary" sx={{color: 'red'}}>
                        {errMsg}
                    </Typography>

                    <LoadingButton
                        loading={loading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2, borderRadius: '0.8rem'}}
                    >
                        Regjistrohu
                    </LoadingButton>
                    <Grid container>
                        <Grid item xs sx={{ textAlign: "center" }}>
                            <Button href="/login" variant="text">
                                Kyçu
                            </Button>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <Link href="#" variant="body2">*/}
                        {/*        {"Don't have an account? Sign Up"}*/}
                        {/*    </Link>*/}
                        {/*</Grid>*/}
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <LoginWithGoogle text="Regjistrohu me Google" />
                        </Grid>
                        <Grid item sx={{ mx: "auto" }}>
                            <IconButton aria-label="delete" size="large"
                                        onClick={() => window.open("https://www.facebook.com/lexdoks.org", "_blank")}>
                                <FacebookIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton aria-label="delete" size="large"
                                        onClick={() => window.open("https://www.linkedin.com/company/75422945", "_blank")}>
                                <LinkedInIcon fontSize="inherit" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{mt: 1, mb: 4}}/>
        </React.Fragment>
    );
}

export default Register;
