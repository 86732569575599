import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box, Button,
    Collapse,
    Container, Dialog, DialogContent,
    FormControlLabel, IconButton,
    RadioGroup,
    styled,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {differenceInDays, getQueryParameterByName, objectKeysToArray, suggestionsToArray} from "../../utils";
import DocumentsTab from "./DocumentsTab";
import {RootState} from "../../redux/reducers/rootState";
import {setSearchText, setSearchType} from "../../redux/actions/search";
import VerdictsTab from "./VerdictsTab";
import SearchInput from "../../components/SearchInput";
import {useTranslation} from "react-i18next";
import ResultsPlaceholder from "../../components/ResultsPlaceholder";
import Radio from '@mui/material/Radio';
import {SearchTypes} from "../../utils/constants";
import DocumentFilters from '../../components/DocumentFilters';
import VerdictFilters from '../../components/VerdictFilters';
import FormsTab from './FormsTab';
import styles from './Search.module.scss';
import {closest, distance, suggestion} from "../../utils/levenshtein";
import {getVerdictsLastUpdated} from "../../redux/actions/verdicts";
import {getDocumentsLastUpdated} from "../../redux/actions/documents";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface Props {

}

const SearchPageHeader = styled(Box)(({theme}) => ({
    backgroundColor: "white",
    marginTop: `-${theme.spacing(3)}`,
    borderBottom: 1,
    borderColor: theme.palette.divider,
    '@media (max-width:756px)': {
        padding: theme.spacing(2, 0, 0, 0)
    }
}));

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Search(props: Props) {

    const dispatch: any = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();

    const [currentTab, setCurrentTab] = useState(0);
    const [subscriptionMessage, setSubscriptionMessage] = useState(true);
    const [warningMessage, setWarningMessage] = useState(false);
    const [filteredLatestSearches, setFilteredLatestSearches] = useState([]);

    const latestSearches = useSelector((state: RootState) => state.search.latestSearches);
    const searchText = useSelector((state: RootState) => state.search.searchText);
    const searchType = useSelector((state: RootState) => state.search.searchType);
    const documentFilters = useSelector((state: RootState) => state.documentsFilters.appliedDocumentsFilters);
    const verdictFilters = useSelector((state: RootState) => state.verdictsFilters.appliedVerdictsFilters);
    const lawsStatus = useSelector((state: RootState) => state.status.lawsStatus);
    const verdictsStatus = useSelector((state: RootState) => state.status.verdictStatus);
    const endsAt = useSelector((state: RootState) => state.subscriptions.endsAt);
    const hasActiveSubscription = useSelector((state: RootState) => state.auth.hasSubscriptions);

    const keywords = useSelector((state: RootState) => state.keywords.keywords);
    const documentsNames = useSelector((state: RootState) => state.documentsNames.documentsNames);
    const verdictsNames = useSelector((state: RootState) => state.verdictsNames.verdictsNames);

    let suggestions: any[] = [];
    if(searchType === SearchTypes.Name) {
        if(currentTab === 0) {
            suggestions = documentsNames.map((keyword) => keyword.documentTitle);
        } else if(currentTab === 1) {
            suggestions = verdictsNames.map((keyword) => keyword.documentTitle);
        }
    } else {
        suggestions = keywords.map((keyword) => keyword.text);
    }

    const [closestKeyword, setClosestKeyword] = useState("");


    const [currentSearchText, setCurrentSearchText] = useState(searchText);

    useEffect(() => {
        dispatch(getVerdictsLastUpdated());
        dispatch(getDocumentsLastUpdated());
    }, []);

    useEffect(() => {
    }, []);

    const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
        if (!currentSearchText) {
            dispatch(setSearchText(""));
            history.push({search: ""});
        } else {
            history.push({search: "?q=" + searchText});
        }
    };

    const changeTab = (tab: number) => {
        setCurrentTab(tab);
    }

    useEffect(() => {
        if (currentTab === 0) {
            setFilteredLatestSearches(latestSearches.filter((search: any) => search.type === 'LAW'));
        } else{
            setFilteredLatestSearches(latestSearches.filter((search: any) => search.type === 'VERDICT'));
        }
    }, [currentTab, latestSearches]);


    const handleInputChange = (value: string) => {
        setCurrentSearchText(value);
    };

    const handleSearch = (value?: any) => {
        if(!hasActiveSubscription) {
            return;
        }
        const phrase = value || currentSearchText;
        const court = verdictFilters.court || getQueryParameterByName("court");
        const status = documentFilters.status || getQueryParameterByName("status");
        const params = new URLSearchParams();

        if (phrase) {
            params.append("q", phrase);
        }
        if (currentTab === 0 && status) {
            params.append("status", status);
        }
        if (currentTab === 1 && court) {
            params.append("court", court);
        }

        dispatch(setSearchText(phrase));
        history.push({search: "?" + params.toString()});
    }

    const handleEnterPress = (value: string) => {
        setCurrentSearchText(value);
        console.log(value);
        handleSearch(value);
    }


    useEffect(() => {
        const phrase = getQueryParameterByName("q");
        const params = new URLSearchParams();

        if (phrase) {
            params.append("q", phrase);
        }

        history.push({search: "?" + params.toString()});
    }, [documentFilters, verdictFilters])


    useEffect(() => {
        const phrase = getQueryParameterByName("q");
        if (phrase) {
            dispatch(setSearchText(phrase));
            setCurrentSearchText(phrase);

            const params = new URLSearchParams();
            params.append("q", phrase);
            history.push({search: "?" + params.toString()});
        }
    }, []);

    const handleSearchTypeChange = (e: any) => {
        dispatch(setSearchType(e.target.value));
        dispatch(setSearchText(""));
        setCurrentSearchText("");
        history.push({ search: "" });
    }


    useEffect(() => {
        const documentFiltersApplied = currentTab === 0 && !Object.values(documentFilters).some((value) => value);
        const verdictsFiltersApplied = currentTab === 1 && !Object.values(verdictFilters).some((value) => value);
        if(documentFiltersApplied || verdictsFiltersApplied) {
            const didYouMean = suggestion(searchText, suggestions);
            setClosestKeyword(didYouMean);
        } else {
            setClosestKeyword("");
        }
    }, [currentTab, documentFilters, searchText, suggestions, verdictFilters]);

    const onClickLatestSearch = (latestSearch: any) => {
        dispatch(setSearchType(SearchTypes.Phrase));
        if (latestSearch.type === "LAW"){
            setCurrentTab(0);
        } else {
            setCurrentTab(1);
        }
    }

    const searchInputTitle = () => {
        if(searchType === 'phrase') {
            return "Kërko me fjalë kyçe";
        } else {
            if(currentTab === 1) {
                return 'Kërko me numër të rastit';
            } else {
                return 'Kërko me titull';
            }
        }

    }

    const showExpirationMessage = () => {
        if(endsAt && hasActiveSubscription) {
            if(differenceInDays(new Date(), endsAt) <= 2) {
                return moment(endsAt).format('DD MM YYYY')
            }
        }
        return null;
    }

    useEffect(() => {
        const finalDate = new Date("2024-03-01").getTime();
        const currentDate = new Date().getTime();

        if(currentDate < finalDate) {
            setWarningMessage(true);
        }
    }, []);

    return (
        <React.Fragment>
            <SearchPageHeader>
                <Box
                    sx={{
                        mt: 1,
                        display: 'flex', alignItems: 'center', height: 50,
                        '@media (max-width:756px)': {
                            flexDirection: "column",
                            height: "auto",
                            mt: -0.5
                        }
                    }}>

                    <SearchInput
                        label={searchInputTitle()}
                        documentsNames={searchType === SearchTypes.Name && currentTab === 0}
                        verdictsNames={searchType === SearchTypes.Name && currentTab === 1}
                        currentTab={currentTab}
                        // showTerminology={currentTab === 0}
                        value={currentSearchText}
                        onChange={handleInputChange}
                        onEnterPress={handleEnterPress}
                        latestSearches={filteredLatestSearches}
                        onClickLatestSearch={(latestSearch: any) => onClickLatestSearch(latestSearch)}
                    />
                </Box>

                <Box sx={{mt: 1, display: "flex"}}>
                    <RadioGroup
                        row
                        defaultValue={SearchTypes.Phrase}
                        name="radio-buttons-group"
                        value={searchType}
                        onChange={handleSearchTypeChange}>
                        <FormControlLabel value={SearchTypes.Phrase} control={<Radio/>} label="Fjalë kyçe"/>
                        <FormControlLabel value={SearchTypes.Name} control={<Radio/>} label={currentTab === 0 ? 'Titull' : 'Numër të rastit'} />
                    </RadioGroup>
                </Box>

                <Box sx={{borderBottom: 1, borderColor: 'divider', marginTop: '10px'}}>
                    <Tabs className={styles.Tabs} value={currentTab} onChange={handleTabsChange}
                          aria-label="basic tabs example">
                        <Tab className={styles.TabButton} sx={currentTab == 0 ? {
                            backgroundColor: '#eff3f6',
                            borderTopRightRadius: '0.8rem',
                            borderTopLeftRadius: '0.8rem'
                        } : {color: '#808080'}} label={t("Legjislacion")} {...a11yProps(0)} />
                        <Tab className={styles.TabButton} sx={currentTab == 1 ? {
                            backgroundColor: '#eff3f6',
                            borderTopRightRadius: '0.8rem',
                            borderTopLeftRadius: '0.8rem'
                        } : {color: '#808080'}} label={t("Praktikë gjyqësore")} {...a11yProps(1)} />
                        <Tab className={styles.TabButton} sx={currentTab == 2 ? {
                            backgroundColor: '#eff3f6',
                            borderTopRightRadius: '0.8rem',
                            borderTopLeftRadius: '0.8rem'
                        } : {color: '#808080'}} label={t("Burime shtesë")} {...a11yProps(2)} />
                    </Tabs>
                </Box>
            </SearchPageHeader>


            {showExpirationMessage() && (
                <Collapse in={subscriptionMessage}>
                    <Alert
                        sx={{ mb: 2, alignItems: 'center'}}
                        severity="warning"
                        action={
                            <IconButton color="inherit" size="small" onClick={() => setSubscriptionMessage(false)}>
                                 <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }>
                        <Typography variant="body2">
                            Pakoja e juaj skadon më {showExpirationMessage()}. Ju lutem zgjedhni dhe paguani pakon përkatëse për vazhdimin e qasjes në platformë.
                            <Button size="small" variant="text" onClick={() => history.push('/subscriptions')}>Abonimet</Button>
                        </Typography>
                    </Alert>
                </Collapse>
            )}

            {hasActiveSubscription === false && (
                <Collapse in={subscriptionMessage}>
                    <Alert
                        sx={{ mb: 2, alignItems: 'center'}}
                        severity="error"
                        action={
                            <IconButton color="inherit" size="small" onClick={() => setSubscriptionMessage(false)}>
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }>
                        <Typography variant="body2">
                            Ju nuk mund të bëni kërkime sepse abonimi i juaj ka skaduar.
                            Ju lutem zgjedhni dhe paguani pakon përkatëse për vazhdimin e qasjes në platformë.
                            <Button size="small" variant="text" onClick={() => history.push('/subscriptions')}>Abonimet</Button>
                        </Typography>
                    </Alert>
                </Collapse>
            )}


            {lawsStatus === "Indexing" && currentTab === 0 && (
                <Alert severity="warning">Për momentin të dhënat janë duke u përditësuar. Rezultatet e kërkimit tuaj mund të mos shfaqen të gjitha.</Alert>
            )}

            {verdictsStatus === "Indexing" && currentTab === 1 && (
                <Alert severity="warning">Për momentin të dhënat janë duke u përditësuar. Rezultatet e kërkimit tuaj mund të mos shfaqen të gjitha.</Alert>
            )}


            {!!closestKeyword &&
            <Typography sx={{ fontSize: "1.15rem", mt: 2 }}>
                Në vend të kësaj kërkoni: <b style={{ cursor: "pointer", color: "#1976d2", textDecoration: "underline" }} onClick={() => handleEnterPress(closestKeyword)}>{closestKeyword}</b>?
            </Typography>}

            {currentTab === 0 && (
                <DocumentFilters/>
            )}

            {currentTab === 1 && (
                <VerdictFilters/>
            )}

            {currentTab === 0 && searchText && <DocumentsTab changeTab={(tab: number) => changeTab(tab)} />}
            {currentTab === 1 && searchText && <VerdictsTab changeTab={(tab: number) => changeTab(tab)} />}
            {currentTab === 2 && <FormsTab/>}

            {(!searchText && currentTab !== 2 && currentTab !== 3) && <Container maxWidth="md">
                <ResultsPlaceholder currentTab={currentTab}/>
            </Container>}

            <Dialog open={warningMessage} onClose={() => setWarningMessage(false)} maxWidth='md'>
                <IconButton
                    aria-label="close"
                    onClick={() => setWarningMessage(false)}
                    sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                    <CloseIcon/>
                </IconButton>
                <DialogContent>
                    <Box sx={{textAlign: 'center'}}>
                        <Typography variant="h5" sx={{mb: 2}}>
                            <ErrorOutlineIcon sx={{width: '80px', height: '80px'}} color='warning'/> <br/>
                            Lexdoks është bërë me pagesë!
                        </Typography>
                        <Typography sx={{mb: 1}}>
                            Përdorimi i llogarisë tuaj pa pagesë skadon më <b>1 Mars 2024</b>. <br/>
                        </Typography>
                            Pas kësaj kohe llogaria juaj do të aktivizohet varësisht nga plani që zgjedhni të paguani. <br/>
                        <Typography sx={{mb: 1}}>
                            Përdoruesit e Gjyqësorit, Ministrisë së Drejtësisë, Agjencisë për Ndihmë Juridike Falas, apo ndonjë pale me të cilën Lexdoks ka marrëveshje, nuk do të ndikohen nga ky ndryshim.
                        </Typography>
                        <Typography sx={{mb: 1}}>
                            Ju faleminderit!
                        </Typography>
                        <Button sx={{ mt: 2 }} variant="contained" onClick={() => setWarningMessage(false)}>
                            Në rregull
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

        </React.Fragment>
    );
}

export default Search;
