import React from 'react';
import * as yup from 'yup';
import {useFormik} from "formik";
import {TextField} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootState";

interface Props {
    onSubmit: any;
}

const validationSchema = yup.object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email('Enter a valid email').required("Email is required"),
});

const CreateUserForm = (props: Props) => {

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            props.onSubmit(values);
        },
    });

    const usersLoading = useSelector((state: RootState) => state.users.usersLoading);

    return (
        <form onSubmit={formik.handleSubmit}>
            <TextField
                fullWidth
                label="First name"
                name="firstName"
                margin="normal"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName} />

            <TextField
                fullWidth
                label="Last name"
                name="lastName"
                margin="normal"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName} />

            <TextField
                fullWidth
                label="Email"
                name="email"
                margin="normal"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email} />


            <LoadingButton
                fullWidth
                sx={{ mt: 2 }}
                type="submit"
                variant="contained"
                loading={usersLoading}>
                Create
            </LoadingButton>
        </form>
    );
}

export default CreateUserForm;
