import React, { useState } from 'react';
import {Box} from "@mui/material";
import Header from "./Header";
import Content from "./Content";
import {Link, Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Container} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Link as LinkRouter } from 'react-router-dom';


interface Props {
    children: any;
}

const Layout = (props: Props) => {
    const [copyrightModal, setCopyrightModal] = useState(false);
    const [contactModal, setContactModal] = useState(false);

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column', justifyContent: 'flex-start', backgroundColor: "#fff" }}>
            <Header/>
            {/*<Drawer />*/}
            <Content>
                {props.children}
            </Content>
            <Box sx={{paddingTop: '10px', paddingBottom: '10px'}}>
                <Container maxWidth="md">
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Box>
                            <Link sx={{cursor: 'pointer', textDecoration: 'none'}} onClick={() => setCopyrightModal(true)}>
                                © Lexdoks {new Date().getFullYear()} - E drejta e autorit & Heqja e përgjegjësisë
                            </Link>
                        </Box>
                        <Box>
                            {/*<LinkRouter to="/links" style={{textDecoration: 'none'}}>*/}
                            {/*    Legjislacioni ndërkombëtar*/}
                            {/*</LinkRouter>*/}
                            {/*<span style={{paddingRight: '5px', paddingLeft: '5px', color: '#1976d2'}}> | </span>*/}
                            <Link sx={{cursor: 'pointer', textDecoration: 'none'}} onClick={() => setContactModal(true)}>
                                Kontakti
                            </Link>
                        </Box>
                    </Box>
                    {/* Copyright Modal */}
                    <Dialog
                        open={copyrightModal}
                        onClose={() => setCopyrightModal(false)}
                        scroll={"paper"}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogTitle id="scroll-dialog-title" sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography>
                                © Lexdoks {new Date().getFullYear()} - E drejta e autorit & Heqja e përgjegjësisë
                            </Typography>
                            <CloseIcon sx={{cursor: 'pointer'}} onClick={() => setCopyrightModal(false)} />
                        </DialogTitle>
                        <DialogContent dividers={true}>
                            <DialogContentText
                                id="scroll-dialog-description"
                            >
                                <Typography variant='body1' gutterBottom>
                                    <b>E drejta e autorit:</b>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Materialet e disponueshme në këtë faqe interneti mbrohen nga legjislacioni në fuqi për të drejtën e autorit dhe janë prone e Legal Data Solutions L.L.C. dhe/ose partnerët e saj të ndërlidhura (të referuara së bashku si "Lexdoks").
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Lexdoks përmban dy grupe të dhënash. Të dhëna që gjenden brenda faqeve të internetit të autorizuara të Lexdoks dhe të dhëna që janë të aksesueshme përmes lidhjeve të jashtme me dokumentet ligjore të disponueshme publikisht.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Lidhur me të dhënat që përpunohen nga dhe që gjenden brenda faqeve të internetit të autorizuara të Lexdoks:
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Lexdoks përpunon informacionin nga legjislacioni parësor dhe dytësor, praktika  gjyqësore dhe dokumente të tjera ligjore relevante dhe u ofron përdoruesve aftësi kërkimi për të mundësuar një navigim efikas brenda fushës së gjerë të legjislacionit dhe jurisprudencave gjyqësore. Procesi i kërkimit dhe rezultatet rrjedhin nga përpunimi kompleks i të dhënave bazuar në inteligjencën artificiale. Rezultatet u paraqiten përdoruesve në formularët e të dhënave të krijuara në mënyrë origjinale (skedarë, formularë, skeda, tabela, etj.).
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Në lidhje me rezultatet dhe të dhënat e përpunuara në lidhje me legjislacionin (parësor, dytësor dhe/ose lloj tjetër) çështjet gjyqësore, dhe të gjitha materialet e tjera të disponueshme në faqet e internetit të autorizuara të Lexdoks (Materialet e Përpunuara Lexdoks), individi mund të përdorë dhe të marrë nëpërmjet komandave të shkarkimit dhe të ruajë në Forma e lexueshme nga makineritë, kryesisht për përdorim ekskluziv të një personi, një kopje e vetme e pjesëve jo thelbësore të Materialeve të Përpunuara Lexdoks të përfshira në çdo skedar individual në masën që ruajtja e tillë nuk kufizohet ose ndalohet më tej nga politikat e tjera të Lexdoks.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Materialet e shkarkuara mund të printohen, kopjohen dhe shpërndahen në masën e lejuar nga ligji në fuqi për të drejtën e autorit dhe duke mos paragjykuar ndonjë ndalesë në politikat e Lexdoks, por nuk mund të riprodhohen ose shpërndahen ndryshe. Materialet e shkarkuara dhe të ruajtura në formë të lexueshme nga makina nuk mund të shfaqen në mënyrë elektronike për më shumë se një përdorues në të njëjtën kohë.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Në lidhje me të dhënat që janë të aksesueshme përmes lidhjeve të jashtme:
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Në disa raste, faqet e autorizuara të Lexdoks do t'i ridrejtojnë përdoruesit te dokumentet ligjore zyrtare, origjinale dhe të disponueshme për publikun. Në raste të tilla, legjislacioni në fuqi do të zbatohet në lidhje me aksesin, përdorimin dhe/ose shpërndarjen e tyre.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Disa materiale të disponueshme në faqet e internetit të autorizuara të Lexdoks i nënshtrohen kufizimeve shtesë për përdorimin, kopjimin, shkarkimin ose shpërndarjen, si dhe terma dhe kushte të tjera.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Lexdoks ruan të drejtën e ndryshimit dhe përditësimit të njëanshëm të politikave të saj për procesimin dhe çasjen në të dhënat e poseduara në platofrmat që menaxhohen nga Lexdoks.
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    <b>Heqja e përgjegjësisë:</b>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Kjo faqe ofrohet nga Legal Data Solutions L.L.C. si krijuese dhe mirëmbajtëse e platformës  (këtu e tutje “Lexdoks”) sipas parimit “ashtu si është” dhe “kurdo që është e çasshme”.  Lexdoks shpalosë faktin, dhe përdoruesit e pranojnë që burimet origjinale të informacionit për legjislacionin dhe praktikën gjyqësore janë vetëm burimet zyrtare të përcaktuara me legjislacionin në fuqi. Duke qenë vetëm përpunues i informatave që përmbahen në këtë faqe, Lexdoks nuk jep kurfar garancioni, të shprehur apo nënkuptuar, mbi funksionimin, informacionet, përmbajtjen apo materialet në këtë faqe. Ju pajtoheni në mënyrë shprehimore që përdorimi i kësaj faqe është në rrezikun diskrecional tuajin.
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setCopyrightModal(false)}>Mbyll</Button>
                        </DialogActions>
                    </Dialog>
                    {/* Contact modal */}
                    <Dialog
                        open={contactModal}
                        onClose={() => setContactModal(false)}
                        scroll={"paper"}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogTitle id="scroll-dialog-title" sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography>
                                Kontakti
                            </Typography>
                            <CloseIcon sx={{cursor: 'pointer'}} onClick={() => setContactModal(false)} />
                        </DialogTitle>
                        <DialogContent dividers={true}>
                            <DialogContentText
                                id="scroll-dialog-description"
                            >
                                <Typography variant="body2" gutterBottom>
                                    Web: www.lexdoks.com
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    E-mail: info@lexdoks.com
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Support: support@lexdoks.com
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Tel: +383 44 462 970
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setContactModal(false)}>Mbyll</Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            </Box>
        </Box>
    );
}

export default Layout;
