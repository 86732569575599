import React, {useState} from 'react';
import {Avatar, Box, Container, Grid, Link, TextField, Typography} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {resetPassword} from "../../redux/actions/password";
import {getQueryParameterByName} from "../../utils";
import {useFormik} from "formik";
import * as yup from "yup";
import {toast} from "react-hot-toast";

interface Props {

}

const validationSchema = yup.object({
    password: yup.string().required("Password is required"),
    confirmPassword: yup.string().required("Password confirmation is required").oneOf([yup.ref('password'), null], 'Passwords must match')
});

function ResetPassword(props: Props) {

    const dispatch: any = useDispatch();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const code = getQueryParameterByName("code");


    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: ""
        },
        validationSchema: validationSchema,
        onSubmit: async(values) => {
            if(!code) {
                return;
            }

            setLoading(true);
            const payload = {
                password: values.password,
                code: code
            };
            await dispatch(resetPassword(payload));
            history.push("/login");
        },
    });

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Vendosni fjalëkalimin e ri
                </Typography>
                <Box sx={{
                    mt: 3,
                    ['@media (max-width:756px)']: {
                        maxWidth: "80vw",
                    }
                }}>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            autoFocus
                            fullWidth
                            margin="normal"
                            id="password"
                            label="Fjalëkalimi i ri"
                            name="password"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password} />

                        <TextField
                            fullWidth
                            margin="normal"
                            id="confirmPassword"
                            label="Konfirmo fjalëkalimin e ri"
                            name="confirmPassword"
                            type="password"
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword} />

                        <LoadingButton
                            loading={loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}>
                            Ndërro fjalëkalimin
                        </LoadingButton>
                    </form>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/login" variant="body2">
                                Kyçu
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>

    );
}

export default ResetPassword;
