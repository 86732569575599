import API from "../../utils/api";
import {FETCHED_USERS, SET_USERS_LOADING, SET_VERDICTS_LOADING} from "../constants";
import {Action} from "./app";
import {localStorageAccessTokenKey} from "../../utils/constants";

export const setUsersLoading = (value: boolean): Action => ({
    type: SET_USERS_LOADING,
    payload: value
})

export const getUsers = () => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("businesses/users", {
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCHED_USERS, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};

export const createUser = (payload: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.post("businesses/users", payload, {
            headers: {"Authorization": "Bearer " + token}
        });
        return response;
    } catch (err) {
        throw err;
    }
};

export const updateUser = (payload: any, id: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.put(`businesses/users/${id}`, payload, {
            headers: {"Authorization": "Bearer " + token}
        });
        return response;
    } catch (err) {
        throw err;
    }
};
