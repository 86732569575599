import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Menu, Button, TextField } from '@mui/material';
import { saveFavDocument, getMyFavDocuments } from '../../redux/actions/favorites';
import { RootState } from '../../redux/reducers/rootState';

interface FavoriteProps {
    document: any;
    show: any;
    handleClose: () => void;
    typeOfDocument: 'LAW' | 'VERDICT';
    page?: string;
};

export default function FavoriteModal({ document, show, handleClose, typeOfDocument, page }: FavoriteProps) {
    const dispatch: any = useDispatch();
    const favList = useSelector((state: RootState) => state.favorites.favoriteDocuments);
    const searchText = useSelector((state: RootState) => state.search.searchText);

    const [note, setNote] = useState(searchText ? `${searchText} - ` : "");

    const onSaveFavoriteDocument = (() => {
        const data = {
            "note": note,
            "type": typeOfDocument,
            "documentId": encodeURIComponent(document.id),
            "page": page,
            "content": {
                "page": page,
                "documentTitle": document.documentTitle,
                "documentName": document.documentName,
                "actNumber": document.actNumber,
                "documentType": document.documentType,
                "institution": document.institution,
                "adoptionDate": document.adoptionDate,
                "publicationDate": document.publicationDate,
                "enforcementDate": document.enforcementDate,
                "enforcementDateNote": document.enforcementDateNote,
                "documentStatus": document.documentStatus,
                "officialLink": document.officialLink,
                "abolishmentDate": document.abolishmentDate,
                "gazetteNumber": document.gazetteNumber,
                "numberInList": document.numberInList,
                // "documentContent": document.highlightedTexts
            }
        }
        if(page && typeOfDocument === "LAW" && page !== '0'){
            const itemExists = favList.data.filter((item: any) => item.documentId === document.id && item.content.page === "0");
            if (itemExists.length === 0) {
                const dataIfNeded = {
                    "note": note,
                    "type": typeOfDocument,
                    "documentId": encodeURIComponent(document.id),
                    "page": "0",
                    "content": {
                        "page": "0",
                        "documentTitle": document.documentTitle,
                        "documentName": document.documentName,
                        "actNumber": document.actNumber,
                        "documentType": document.documentType,
                        "institution": document.institution,
                        "adoptionDate": document.adoptionDate,
                        "publicationDate": document.publicationDate,
                        "enforcementDate": document.enforcementDate,
                        "enforcementDateNote": document.enforcementDateNote,
                        "documentStatus": document.documentStatus,
                        "officialLink": document.officialLink,
                        "abolishmentDate": document.abolishmentDate,
                        "gazetteNumber": document.gazetteNumber,
                        "numberInList": document.numberInList,
                        // "documentContent": document.highlightedTexts
                    }
                }
                dispatch(saveFavDocument(dataIfNeded));
            }
        }
        dispatch(saveFavDocument(data)).then((res: any) => {
            dispatch(getMyFavDocuments());
            handleClose();
        });
    });

    return (
        <div>
            <Menu
                anchorEl={show}
                keepMounted
                open={!!show}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                sx={{borderRadius: '0.8rem'}}
            >
                <div style={{display: 'flex', alignItems: 'center', padding: '2px 10px'}}>
                    <TextField
                        sx={{ width: "20rem" }}
                        size='small' 
                        label="Shënim për dokumentin" 
                        value={note} 
                        onChange={(e) => setNote(e.target.value)}
                    />
                    <Button onClick={onSaveFavoriteDocument} style={{ height: '37px', borderTopRightRadius: '0.8rem', borderBottomRightRadius: '0.8rem', marginLeft: '-12px' }} variant='contained'>Ruaj</Button>
                </div>
            </Menu>
        </div>
    );
};
