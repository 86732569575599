import { Button, Card, CardActions, IconButton, Typography, Box, styled, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { RootState } from "../../redux/reducers/rootState";
import { getQueryParameterByName } from "../../utils";
import LabeledValue from "../shared-components/LabeledValue";
import PageWithDescription from "../shared-components/PageWithDescription";
import styles from './DocumentCard.module.scss';
import { saveSearches, getSavedSearches } from '../../redux/actions/search';
import { deleteFavDocument } from '../../redux/actions/favorites';
import FavoriteModal from '../FavoriteModal';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { confirm } from "../ConfirmDialog/confirm";

interface Props {
    document: any;
    isFavorited: any;
}

export const StatusTranslations: any = {
    "NË FUQI": "Në fuqi",
    "NDRYSHUAR": "I ndryshuar",
    "SHFUQIZUAR": "I shfuqizuar"
}

const MainCardContent = styled(Box)(({theme}) => ({
    backgroundColor: "rgba(223,231,238,0.5)",
    padding: theme.spacing(2, 2, 2, 2)
}));

export default function DocumentCard(props: Props) {
    const dispatch: any = useDispatch();

    const totalDocuments = useSelector((state: RootState) => state.documents.totalDocuments);
    const favList = useSelector((state: RootState) => state.favorites.favoriteDocuments);
    const expandItems = 2;
    const [visibleItems, setVisibleItems] = useState(1);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleShowMoreButtonClick = () => {
        const remainingItems = props.document.highlightedTexts.length - visibleItems;
        const itemsToExpand =  remainingItems < expandItems ? remainingItems : expandItems;
        setVisibleItems(visibleItems + itemsToExpand);
    }

    const handleCollapseButtonClick = () => {
        setVisibleItems(1);
    }

    const phrase = getQueryParameterByName("q");

    const saveSearch = () => {
        const dataSearch = {
            "phrase": phrase,
            "type": "LAW",
            "totalHits": totalDocuments,
            "maxResults": 40,
            "slop": 10,
        }
        dispatch(saveSearches(dataSearch)).then((res: any) => {
            dispatch(getSavedSearches());
        });
    }

    // const onDeleteFavDocument = async(id: any) => {
    //     if(!await confirm(null, {title: "Dëshironi ta fshini dokumentin e ruajtur?", cancelButton: "Jo"})) {
    //         return;
    //     }
    //     const lawsFavListWithPage = favList.data.filter((item: any) => item.documentId === props.document.id && item.page !== '0' && item.type === "LAW");
    //     if(lawsFavListWithPage.length > 0){
    //         lawsFavListWithPage.forEach((element: any) => {
    //             dispatch(deleteFavDocument(element.id));
    //         });
    //     }
    //     dispatch(deleteFavDocument(id));
    // }

    const handleClickFavorite = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFavorite = () => {
        setAnchorEl(null);
    };

    return (
        <Card sx={{ mb: 2.2, boxShadow: (theme) => theme.shadows[2] }}>
            <Box>
                <MainCardContent>
                    <div className={styles.DocumentCardTitleWrapper}>
                        <Typography variant="h6" color="text.secondary" onClick={saveSearch}>
                            <RouterLink style={{textDecoration: 'none'}} to={`/documents/${encodeURIComponent(props.document.id)}/${props.document.highlightedTexts?.[0]?.page || 0}/q=${phrase}`}>
                                {props.document.documentName}
                            </RouterLink>
                        </Typography>
                        {/* {props.isFavorited ? (
                            <Tooltip title="Fshije dokumentin" placement="right" arrow>
                                <div>
                                    <IconButton color="primary" onClick={() => onDeleteFavDocument(props.isFavorited)}>
                                        <BookmarkIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        ) : ( */}
                        <Tooltip title="Ruaje dokumentin" placement="right" arrow>
                            <div>
                                <IconButton
                                    onClick={handleClickFavorite}
                                >
                                    <BookmarkBorderIcon />
                                </IconButton>
                                <FavoriteModal 
                                    show={anchorEl} 
                                    handleClose={handleCloseFavorite} 
                                    typeOfDocument="LAW"
                                    page="0"
                                    document={props.document}
                                />
                            </div>
                        </Tooltip>
                    </div>
                    <div className={styles.DocumentCardDescriptionWrapper}>
                        <LabeledValue type="normal" label="Lloji i dokumentit" value={props.document.documentType} />
                        <LabeledValue type="normal" label="Institucioni" value={props.document.institution} />
                        <LabeledValue type="date" label="Data e hyrjes në fuqi" value={props.document.enforcementDate} />
                        <LabeledValue type="normal" label="Numri i aktit" value={props.document.actNumber} />
                        <LabeledValue type="status" label="Statusi" value={props.document.documentStatus} />
                        <LabeledValue type="link" onSaveSearch={saveSearch} label="Linku zyrtar" value={props.document.officialLink} />
                    </div>
                </MainCardContent>

                {props.document.highlightedTexts.map((text: any, index: number) => {
                    const isRowVisible = ((index + 1) <= visibleItems);
                    return (
                        isRowVisible && <PageWithDescription onSaveSearch={saveSearch} document={props.document} page={text.page} text={text.highLightedText} />
                    )
                })}
            </Box>
            <CardActions sx={{display: 'flex', justifyContent: 'end'}}>
                {!!(props.document.highlightedTexts.length - visibleItems) && <Button
                    size="small"
                    disabled={visibleItems >= props.document.highlightedTexts.length}
                    onClick={handleShowMoreButtonClick}>
                    Shfaq më shumë ({props.document.highlightedTexts.length - visibleItems})
                </Button>}
                {visibleItems > 1 && <Button
                    size="small"
                    onClick={handleCollapseButtonClick}>
                    Mbyll të gjitha
                </Button>}
            </CardActions>
            
        </Card>
    )
}
