import {Action} from "../actions/app";
import * as constants from "../constants";

export interface AppState {
    loading: boolean;
    isDrawerOpen: boolean;
    isRightDrawerOpen: boolean;
}

const getInitialState = () => {
    return {
        loading: false,
        isDrawerOpen: true,
        isRightDrawerOpen: false
    }
}

const app = (state: AppState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.SET_LOADING: {
            return {...state, loading: action.payload};
        }

        case constants.SET_DRAWER: {
            return {...state, isDrawerOpen: action.payload};
        }

        case constants.SET_RIGHT_DRAWER: {
            return {...state, isRightDrawerOpen: action.payload};
        }

        default: {
            return {...state};
        }

    }
}

export default app;
