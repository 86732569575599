import {Action} from "../actions/app";
import * as constants from "../constants";
import {SearchTypes} from "../../utils/constants";

export interface SearchState {
    searchText: string;
    searchType: string;
    documentFilters: any;
    verdictFilters: any;
    latestSearches: any;
}

const getInitialState = () => {
    return {
        searchText: "",
        searchType: SearchTypes.Phrase,
        documentFilters: {
            status: "",
            // adoptionDate: "",
            // abolishmentDate: "",
            // enforcementDate: ""
        },
        verdictFilters: {
        },
        latestSearches: []
    }
}

const search = (state: SearchState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.SET_SEARCH_TEXT: {
            return {
                ...state,
                searchText: action.payload,
            }
        }

        case constants.SET_SEARCH_TYPE: {
            return {
                ...state,
                searchType: action.payload,
            }
        }

        case constants.SET_DOCUMENTS_SEARCH_FILTERS: {
            console.log(action.payload);
            return {
                ...state,
                documentFilters: {
                    ...state.documentFilters,
                    [action.payload.name]: action.payload.value
                }
            }
        }

        case constants.SET_VERDICTS_SEARCH_FILTERS: {
            console.log(action.payload);
            return {
                ...state,
                verdictFilters: {
                    ...state.verdictFilters,
                    [action.payload.name]: action.payload.value
                }
            }
        }

        case constants.CLEAR_DOCUMENTS_SEARCH_FILTERS: {
            return {
                ...state,
                documentFilters: {
                    status: "",
                    // adoptionDate: null,
                    // abolishmentDate: null,
                    // enforcementDate: null
                }
            }
        }

        case constants.CLEAR_VERDICTS_SEARCH_FILTERS: {
            return {
                ...state,
                verdictFilters: {
                    court: "",
                    branch: "",
                    announcementDate: null
                }
            }
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        case constants.SET_LATEST_SEARCHES: {
            return {
                ...state,
                latestSearches: action.payload
            }
        }

        case constants.DELETE_SAVED_SEARCH: {
            return {
                ...state,
                latestSearches: state.latestSearches.filter((search: any) => search.id != action.payload)
            }
        }

        default: {
            return {...state};
        }

    }
}

export default search;
