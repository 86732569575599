import {Action} from "../actions/app";
import * as constants from "../constants";

export interface SubscriptionsState {
    subscriptions: Array<any>
    subscriptionsLoading: boolean;
    endsAt: any;
}

const getInitialState = () => {
    return {
        subscriptions: [],
        subscriptionsLoading: false,
        endsAt: null
    }
}

const subscriptions = (state: SubscriptionsState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_SUBSCRIPTIONS: {
            let endsAt = null;
            const sorted = action.payload?.filter((sub: any) => sub.status === "paid")
                .sort((a: any, b: any) => new Date(b.endsAt).getTime() - new Date(a.endsAt).getTime());
            if(sorted?.[0]?.endsAt) {
               endsAt = sorted[0].endsAt;
            }
            return {...state, subscriptions: action.payload, subscriptionsLoading: false, endsAt: endsAt};
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        default: {
            return {...state};
        }

    }
}

export default subscriptions;
