import React, {useEffect, useState} from 'react';
import {
    Button,
    Container,
    IconButton, Paper, styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import CreateUserForm from "../../components/Forms/CreateUserForm";
import RightDrawer from "../../components/RIghtDrawer";
import ProgressSpinner from "../../components/ProgressSpinner";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/reducers/rootState";
import {setRightDrawer} from "../../redux/actions/app";
import {createUser, getUsers, setUsersLoading, updateUser} from "../../redux/actions/users";
import UpdateUserForm from "../../components/Forms/UpdateUserForm";
import {toast} from "react-hot-toast";
import {confirm} from "../../components/ConfirmDialog/confirm";
import {useHistory} from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

interface Props {

}

const UsersTableHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
}));

function Users(props: Props) {

    const dispatch: any = useDispatch();
    const history = useHistory();
    const users = useSelector((state: RootState) => state.users.users);
    const usersLoading = useSelector((state: RootState) => state.users.usersLoading);

    const isBusinessUser = useSelector((state: RootState) => state.auth.isBusinessUser);

    const [searchText, setSearchText] = useState<string>("");
    const [editingUser, setEditingUser] = useState<any>(null);

    const filteredUsers = users.filter(user => {
        return user.firstName.toLowerCase().includes(searchText.toLowerCase()) || user.lastName.toLowerCase().includes(searchText.toLowerCase()) || user.email.toLowerCase().includes(searchText.toLowerCase())
    });

    useEffect(() => {
        if(!isBusinessUser) {
            history.push("/");
            toast.error("Access denied!");
            return;
        }

        dispatch(getUsers());
    }, [dispatch]);

    const handleCreateButtonClick = () => {
        dispatch(setRightDrawer(true));
    }

    const handleCreateUserFormSubmit = async(values: any) => {
        if(!await confirm()) {
            return;
        }

        setEditingUser(null);
        dispatch(setUsersLoading(true))
        dispatch(createUser(values))
            .then((res: any) => {
                dispatch(getUsers());
                toast.success("User created!")
                closeDrawer();
            }).catch((err: any) => closeDrawer())
    }

    const handleUpdateUserFormSubmit = async(values: any) => {
        if(!await confirm()) {
            return;
        }

        dispatch(setUsersLoading(true));
        dispatch(updateUser(values, editingUser.id))
            .then((res: any) => {
                dispatch(getUsers());
                toast.success("User updated!")
                closeDrawer();
            }).catch((err: any) => closeDrawer())
    }

    const handleEditUserButtonClick = (user: any) => {
        setEditingUser(user);
        dispatch(setRightDrawer(true));
    }

    const closeDrawer = () => {
        dispatch(setUsersLoading(false));
        dispatch(setRightDrawer(false));
    }

    return (
        <Container maxWidth="md">
            <TableContainer sx={{maxWidth: 800, margin: "auto"}} component={Paper}>

                <UsersTableHeader>

                    <TextField
                        label="Search"
                        name="search"
                        size="small"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)} />
                    <input role="presentation" style={{ width: 0, height: 0, border: 0, padding: 0}} autoComplete="off"/>

                    <Button
                        sx={{ml: "auto"}}
                        variant="contained"
                        onClick={handleCreateButtonClick}
                        startIcon={<AddCircleIcon/>}>
                        Shto
                    </Button>
                </UsersTableHeader>


                {!usersLoading ? <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell>Firstname</TableCell>
                            <TableCell>Lastname</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Active</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.map((user) => (
                            <TableRow
                                key={user.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell>{user.username.slice(0, 8)}</TableCell>
                                <TableCell>{user.firstName}</TableCell>
                                <TableCell align="left">{user.lastName}</TableCell>
                                <TableCell align="left">{user.email}</TableCell>
                                <TableCell align="left">{user.active ? <CheckCircleIcon color="success"/> : <CancelIcon color="error"/>}</TableCell>
                                <TableCell align="left">
                                    <IconButton
                                        aria-label="delete"
                                        size="small"
                                        onClick={() => handleEditUserButtonClick(user)}>
                                        <EditIcon fontSize="inherit" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table> : <ProgressSpinner />}
            </TableContainer>

            <RightDrawer
                title={editingUser ? "Update user" : "Create user"}
                onClose={() => setEditingUser(null)}>

                {editingUser
                    ? <UpdateUserForm
                        user={editingUser}
                        onSubmit={handleUpdateUserFormSubmit}/>
                     : <CreateUserForm
                        onSubmit={handleCreateUserFormSubmit}/>}

            </RightDrawer>
        </Container>
    );
}

export default Users;
