import { Box, InputAdornment, styled, TextField, IconButton, Divider, Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from '@mui/icons-material/History';
import ClearIcon from '@mui/icons-material/Clear';
import styles from "./SearchInput.module.scss";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../../redux/reducers/rootState";
import { useTranslation } from "react-i18next";
import {
    arrayToSuggestions,
    filterDocumentNames, filterSuggestionsByWords,
    filterSuggestionsStartsWith,
    objectValuesToArray
} from "../../utils";
import CloseIcon from '@mui/icons-material/Close';
import {deleteSavedSearch} from "../../redux/actions/search";

interface Props {
    value: any;
    onChange: any;
    onEnterPress: any;
    showTerminology?: boolean;
    hideSuggestions?: boolean;
    label?: any;
    documentsNames?: boolean;
    verdictsNames?: boolean;
    currentTab?: number;
    latestSearches?: any;
    onClickLatestSearch?: any;
}

const SuggestionsWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'open' })<{ open?: boolean; }>
(({ theme, open }) => ({
    position: "absolute",
    top: "55px",
    backgroundColor: "white",
    width: "100%",
    boxShadow: theme.shadows[2],
    padding: theme.spacing(1, 0),
    zIndex: 9,
    borderRadius: 3
}));

export default function SearchInput(props: Props) {
    const {t} = useTranslation();
    const inputRef: any = useRef<any>(null);
    const dispatch: any = useDispatch();

    const [clickedOnDelete, setClickedOnDelete] = useState(false);

    const keywords = useSelector((state: RootState) => props.hideSuggestions ? [] : state.keywords.keywords);
    const documentsNames = useSelector((state: RootState) => props.hideSuggestions ? [] : state.documentsNames.documentsNames);
    const verdictsNames = useSelector((state: RootState) => props.hideSuggestions ? [] : state.verdictsNames.verdictsNames);

    const documentsFilters = useSelector((state: RootState) => state.documentsFilters.appliedDocumentsFilters);
    const verdictsFilters = useSelector((state: RootState) => state.verdictsFilters.appliedVerdictsFilters);

    const documentsFiltersArray = objectValuesToArray(documentsFilters);
    const verdictsFiltersArray = objectValuesToArray(verdictsFilters);

    let suggestions: any[] = [];
    if(props.documentsNames) {
        const filteredDocumentsNames = filterDocumentNames(documentsNames, documentsFiltersArray);
        suggestions = arrayToSuggestions(filteredDocumentsNames, "documentTitle");
    } else if (props.verdictsNames) {
        const filteredVerdictsNames = filterDocumentNames(verdictsNames, verdictsFiltersArray);
        suggestions = arrayToSuggestions(filteredVerdictsNames, "documentTitle");
    } else {
        suggestions = arrayToSuggestions(keywords, "text");
    }

    let filteredSuggestions = filterSuggestionsStartsWith(suggestions, props.value);

    if(!filteredSuggestions.length) {
        if(props.documentsNames || props.verdictsNames) {
            filteredSuggestions = filterSuggestionsByWords(suggestions, props.value);
        }
    }

    const sortedSuggestions = filteredSuggestions.sort((a: any, b: any) => b.possibility - a.possibility);
    const latestSearches = props.latestSearches ?? [];
    latestSearches.forEach((element: any) => {
        return [
            element.text = element.phrase,
        ];
    });
    const filteredSearches = filterSuggestionsStartsWith(latestSearches, props.value).splice(0,5);
    const finalSuggestions = filteredSearches.concat(props.value !== '' ? sortedSuggestions.splice(0, 6) : []);

    const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);
    const [selectedKeywordIndex, setSelectedKeywordIndex] = useState<any>(null);

    const handleKeyDown = (e: any) => {
        if(props.hideSuggestions) {
            return;
        }
        setIsSuggestionsVisible(true);
        if (e.key === "ArrowDown") {
            e.preventDefault();
            const selected = (selectedKeywordIndex === null || (selectedKeywordIndex === (finalSuggestions.length - 1))) ? 0 : selectedKeywordIndex + 1;
            setSelectedKeywordIndex(selected);
        }
        if (e.key === "ArrowUp") {
            e.preventDefault();
            const selected = (selectedKeywordIndex === 0) ? null : (selectedKeywordIndex === null) ? (finalSuggestions.length - 1) : selectedKeywordIndex - 1;
            setSelectedKeywordIndex(selected);
        }
    }

    const handleKeyPress = (e: any, isSubmit?: boolean) => {
        if (e?.key === "Enter" || isSubmit) {
            if(finalSuggestions[selectedKeywordIndex]) {
                props.onEnterPress(finalSuggestions[selectedKeywordIndex].text);
                if (finalSuggestions[selectedKeywordIndex].filters) {
                    props.onClickLatestSearch(props.latestSearches[selectedKeywordIndex]);
                }
                handleInputBlur()
            } else {
                if(props.value) {
                    props.onEnterPress(props.value);
                    handleInputBlur()
                }
            }
        }
    }

    const handleInputFocus = () => {
        setIsSuggestionsVisible(true);
    }

    const handleInputBlur = () => {
        if(!clickedOnDelete) {
            setIsSuggestionsVisible(false);
            setSelectedKeywordIndex(null);
            inputRef?.current?.blur();
        } else {
            inputRef?.current?.focus();
            setClickedOnDelete(false);
        }
    }

    const handleChange = (e: any) => {
        props.onChange(e.target.value);
    }

    const clearSearchInput = () => {
        props.onChange('');
    }

    const handleSuggestionClick = (keyword: any) => {
        props.onEnterPress(keyword.text);
        if (keyword.filters) {
            props.onClickLatestSearch(keyword);
        }
        handleInputBlur();
    }

    const renderKeyword = (keyword: any) => {
        if(keyword.startsWith(props.value) && !props.documentsNames && !props.verdictsNames) {
            const remainingPart = keyword.slice(props.value.length, keyword.length);
            return <>{props.value}<b>{remainingPart}</b></>
        }
        return keyword;
    }

    const ClearInputEndAdornment  = (
        <InputAdornment position="start">
            <ClearIcon sx={{ cursor: 'pointer' }} onClick={clearSearchInput} />
        </InputAdornment>
    );

    const onClickDeleteKeyword = (keyword: any) => {
        dispatch(deleteSavedSearch(keyword.id));
    }

    const SearchInputEndAdornment = (
        <>
            {(props.value !== '') && ClearInputEndAdornment}
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <Tooltip title="Kërko">
                <IconButton onClick={() => handleKeyPress(null, true) } color="primary" sx={{ p: '10px' }} aria-label="directions">
                    <SearchIcon />
                </IconButton>
            </Tooltip>
        </>
    )

    return (
        <Box component="div"
             sx={{
                position: "relative",
                '@media (max-width:756px)': {
                    width: "100%",
                }
            }}>
            <form
                action="#"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleKeyPress(null, true);
                }}>
                <TextField
                    inputRef={inputRef}
                    name="search"
                    type="search"
                    id="outlined-basic"
                    label={props.label || t("Kërko")}
                    variant="outlined"
                    autoComplete='off'
                    // disabled={(props.currentTab === 1 && !hasSubscriptions) || props.currentTab === 2}
                    disabled={props.currentTab === 2}
                    className={styles.input}
                    value={props.value}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDown}
                    onKeyPress={(e) => handleKeyPress(e)}
                    onChange={handleChange}
                    sx={{
                        width: "520px",
                        height: "100%" ,
                        '@media (max-width:756px)': {
                            width: "100%",
                        },
                    }}
                    InputProps={{
                        style: {
                            borderRadius: ".8rem",
                        },
                        endAdornment: SearchInputEndAdornment
                    }} />
            </form>

            {!props.hideSuggestions && (
                (isSuggestionsVisible && !!keywords.length) && finalSuggestions.length > 0 &&
                <SuggestionsWrapper>
                    {isSuggestionsVisible && finalSuggestions.map((keyword: any, index: number) => {
                        return (
                            <div
                                key={index}
                                onMouseDown={() => handleSuggestionClick(keyword)}
                                className={styles.SuggestionItem}
                                style={selectedKeywordIndex === index ? { backgroundColor: '#ECECEC' } : {}}
                            >
                                {keyword.type ? (
                                    <HistoryIcon style={{marginRight: '10px'}} />
                                ) : (
                                    <SearchIcon style={{marginRight: '10px'}} />
                                )}
                                {renderKeyword(keyword.text)}
                                {!!keyword.id &&
                                <IconButton
                                    className={styles.deleteKeywordButton}
                                    size="small"
                                    aria-label="delete"
                                    onMouseDown={(e) => {
                                        e.stopPropagation();
                                        setClickedOnDelete(true);
                                        onClickDeleteKeyword(keyword)
                                    }}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>}
                            </div>
                        )
                    })}
                </SuggestionsWrapper>
            )}
        </Box>
    );
}
