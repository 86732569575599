import {
    CLEAR_DOCUMENTS_SEARCH_FILTERS,
    CLEAR_VERDICTS_SEARCH_FILTERS,
    SET_DOCUMENTS_SEARCH_FILTERS,
    SET_SEARCH_TEXT, SET_SEARCH_TYPE,
    SET_VERDICTS_SEARCH_FILTERS,
    SET_LATEST_SEARCHES, DELETE_SAVED_SEARCH
} from "../constants";
import { Action } from "./app";
import API from "../../utils/api";
import { localStorageAccessTokenKey } from "../../utils/constants";

export const setSearchText = (value: string): Action => ({
    type: SET_SEARCH_TEXT,
    payload: value
});

export const setSearchType = (value: string): Action => ({
    type: SET_SEARCH_TYPE,
    payload: value
});

export const setDocumentsSearchFilters = (value: any): Action => ({
    type: SET_DOCUMENTS_SEARCH_FILTERS,
    payload: value
});

export const setVerdictsSearchFilters = (value: any): Action => ({
    type: SET_VERDICTS_SEARCH_FILTERS,
    payload: value
});

export const clearDocumentsSearchFilters = (): Action => ({
    type: CLEAR_DOCUMENTS_SEARCH_FILTERS,
    payload: null
});


export const clearVerdictsSearchFilters = (): Action => ({
    type: CLEAR_VERDICTS_SEARCH_FILTERS,
    payload: null
});


export const saveSearches = (payload: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.post("searches", payload, {
            headers: {"Authorization": "Bearer " + token}
        });
        return response;
    } catch (err) {
        throw err;
    }
};

export const getSavedSearches = () => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("searches", {
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: SET_LATEST_SEARCHES, payload: response.data.data})
        return response;
    } catch (err) {
        throw err;
    }
};

export const deleteSavedSearch = (id: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.delete("searches/" + id, {
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: DELETE_SAVED_SEARCH, payload: id})
        return response;
    } catch (err) {
        throw err;
    }
};
