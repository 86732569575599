import API from "../../utils/api";
import {
    FETCHED_VERDICTS_FILTERS,
    FETCHED_VERDICTS_NAMES,
    SET_VERDICTS_FILTERS_LOADING,
    SET_VERDICTS_NAMES_LOADING
} from "../constants";
import {Action} from "./app";
import {localStorageAccessTokenKey} from "../../utils/constants";

export const setVerdictsNamesLoading = (value: boolean): Action => ({
    type: SET_VERDICTS_NAMES_LOADING,
    payload: value
});

export const getVerdictsNames = (payload: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("titles?type=verdicts", {
            params: payload,
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCHED_VERDICTS_NAMES, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};
