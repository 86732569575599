import {Action} from "../actions/app";
import * as constants from "../constants";

export interface DocumentsState {
    document: any;
    documents: Array<any>;
    totalDocuments: number;
    documentsLoading: boolean;
    documentLoading: boolean;
    documentsPageSize: number;
    documentsLastUpdated: string;
}

const getInitialState = () => {
    return {
        document: null,
        documents: [],
        totalDocuments: 0,
        documentsLoading: false,
        documentLoading: true,
        documentsPageSize: 40,
        documentsLastUpdated: '',
    }
}

const documents = (state: DocumentsState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_DOCUMENTS: {
            return {...state, documents: action.payload.data, totalDocuments:action.payload.total , documentsLoading: false};
        }

        case constants.FETCHED_DOCUMENT: {
            return {...state, document: action.payload, documentLoading: false};
        }

        case constants.SET_DOCUMENTS_LOADING: {
            return {...state, documentsLoading: action.payload};
        }

        case constants.SET_DOCUMENT_LOADING: {
            return {...state, documentLoading: action.payload};
        }

        case constants.SET_DOCUMENTS_PAGE_SIZE: {
            return {...state, documentsPageSize: action.payload};
        }

        case constants.FETCHED_DOCUMENTS_LAST_UPDATED: {
            return {...state, documentsLastUpdated: action.payload};
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        default: {
            return {...state};
        }

    }
}

export default documents;
