import API from "../../utils/api";
import {
    CLEAR_DOCUMENTS_FILTERS,
    FETCHED_DOCUMENTS_FILTERS,
    SET_DOCUMENTS_FILTER,
    SET_DOCUMENTS_FILTERS,
    SET_DOCUMENTS_FILTERS_LOADING,
    SET_DOCUMENTS_FILTERS_FROM_LATEST_SEARCH
} from "../constants";
import {Action} from "./app";
import {localStorageAccessTokenKey} from "../../utils/constants";

export const setDocumentsFilters = (value: any): Action => ({
    type: SET_DOCUMENTS_FILTERS,
    payload: value
});

export const setDocumentsFiltersFromLatestSearch = (value: any): Action => ({
    type: SET_DOCUMENTS_FILTERS_FROM_LATEST_SEARCH,
    payload: value
});

export const setDocumentsFilter = (value: any): Action => ({
    type: SET_DOCUMENTS_FILTER,
    payload: value
});

export const clearDocumentsFilters = (): Action => ({
    type: CLEAR_DOCUMENTS_FILTERS,
    payload: null
});

export const setDocumentsFiltersLoading = (value: boolean): Action => ({
    type: SET_DOCUMENTS_FILTERS_LOADING,
    payload: value
});

export const getDocumentsFilters = (payload: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("filters?type=laws", {
            params: payload,
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCHED_DOCUMENTS_FILTERS, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};
