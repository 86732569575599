import {Action} from "../actions/app";
import * as constants from "../constants";
import {parseFiltersFromBackend} from "../../utils";

export interface VerdictsFiltersState {
    allVerdictsFilters: Array<any>
    verdictsFilters: Array<any>
    verdictsFiltersLoading: boolean;
    appliedVerdictsFilters: any;
}

const getInitialState = () => {
    return {
        allVerdictsFilters: [],
        verdictsFilters: [],
        verdictsFiltersLoading: true,
        appliedVerdictsFilters: {}
    }
}

const verdictsFilters = (state: VerdictsFiltersState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_VERDICTS_FILTERS: {
            console.log(action.payload.filter((f: any) => f.name === 'branch' && f.value === 'Gjykata Supreme'));
            const filters = parseFiltersFromBackend(action.payload);
            console.log(filters);
            return {
                ...state,
                allVerdictsFilters: action.payload,
                verdictsFilters: filters,
                verdictsFiltersLoading: false,
                appliedVerdictsFilters: filters.reduce((a: any, v: any) => ({ ...a, [v.name]: "" }), {})
            };
        }

        case constants.SET_VERDICTS_FILTER: {
            return {
                ...state,
                appliedVerdictsFilters: {
                    ...state.appliedVerdictsFilters,
                    [action.payload.name]: action.payload.value
                }
            }
        }

        case constants.SET_VERDICTS_FILTERS_FROM_LATEST_SEARCH: {
            return {
                ...state,
                appliedVerdictsFilters: action.payload
            }
        }

        case constants.SET_VERDICTS_FILTERS: {
            const filters = parseFiltersFromBackend(action.payload);
            return { ...state, verdictsFilters: filters };
        }

        case constants.SET_VERDICTS_FILTERS_LOADING: {
            return {...state, verdictsFiltersLoading: action.payload};
        }

        case constants.CLEAR_VERDICTS_FILTERS: {
            console.log(state.verdictsFilters.reduce((a: any, v: any) => ({ ...a, [v.name]: null }), {}));
            return {
                ...state,
                appliedVerdictsFilters: state.verdictsFilters.reduce((a: any, v: any) => ({ ...a, [v.name]: null }), {})
            }
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        default: {
            return {...state};
        }

    }
}

export default verdictsFilters;
