import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import ProgressSpinner from "../../components/ProgressSpinner";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/reducers/rootState";
import {createSubscription, getSubscriptions} from "../../redux/actions/subscriptions";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import {plansMap} from "../../utils/constants";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DownloadIcon from "@mui/icons-material/Download";
import {pdf} from "@react-pdf/renderer";
import InvoicePdf from "../../components/InvoicePdf";
import {saveAs} from "file-saver";
import {getPlans} from "../../redux/actions/plans";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {type} from "os";

interface Props {

}

type ModalContent = 'plans' | 'loading' | 'success' | 'warning';

function Subscriptions(props: Props) {

    const dispatch: any = useDispatch();
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);
    const subscriptionsLoading = useSelector((state: RootState) => state.subscriptions.subscriptionsLoading);
    const user = useSelector((state: RootState) => `${state.auth.firstName} ${state.auth.lastName}`);
    const hasSubscription = useSelector((state: RootState) => state.auth.hasSubscriptions);

    const plans = useSelector((state: RootState) => state.plans.plans);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState<ModalContent>("plans");
    const [selectedSubscription, setSelectedSubscription] = useState<any>(null);

    useEffect(() => {
        dispatch(getPlans());
        dispatch(getSubscriptions());
    }, [dispatch]);

    const handleModalClose = () => {
        setIsModalOpen(false);
    }

    const handleChoosePlan = (plan: any) => {
        setModalContent('loading');
        dispatch(createSubscription(plan.id))
            .then((res: any) => {
                setSelectedSubscription(res.data)
                dispatch(getSubscriptions());
                setModalContent('success');
            });
    }

    const onCreateSubscription = () => {
        const hasUnpaidSubscription = subscriptions.find((sub) => sub.status === 'unpaid');
        if (!hasUnpaidSubscription) {
            setModalContent('plans')
        } else {
            setModalContent('warning')
        }
        setIsModalOpen(true);
    }

    const generateInvoice = async (subscription: any) => {
        if (subscription?.plan) {
            const blob = await pdf((
                <InvoicePdf
                    name={user}
                    plan={getPlan(subscription.plan).name}
                    invoiceNr={subscription.invoiceNr}
                    price={subscription.plan.price}
                />
            )).toBlob();
            saveAs(blob, `lexdoks-invoice-${subscription.invoiceNr}.pdf`);
        }
    }

    const hasActiveSubscription = hasSubscription && subscriptions.find((sub) => sub.status === 'paid' && new Date(sub.endsAt).getTime() > new Date().getTime());

    const getPlan = (plan: any) => {
        if(plan?.type) {
            return plansMap[plan.type];
        }
        return {};
    }

    const getExpiryDate = () => {
        if(!hasActiveSubscription) {
            return null;
        }
        const sorted = subscriptions.filter((sub: any) => sub.status === "paid")
            .sort((a, b) => new Date(b.endsAt).getTime() - new Date(a.endsAt).getTime());
        if(sorted?.[0]?.status === 'paid') {
            return moment(sorted[0].endsAt).format('DD.MM.YYYY');
        }
        return null;
    }

    return (
        <Container maxWidth="md">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5" sx={{mb: 2}}>
                    Abonimi juaj
                </Typography>
                <Button variant="outlined" startIcon={<AddCircleIcon />} onClick={onCreateSubscription}>
                    Zgjat abonimin
                </Button>
            </Box>

            <Paper sx={{mb: 4, p: 2}}>
                {(!subscriptionsLoading && typeof hasSubscription === 'boolean') &&
                <Box>
                    {hasActiveSubscription ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant='h6' color='green'>Aktiv</Typography>
                            <CheckCircleIcon color='success' sx={{ ml: 1 }} />
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant='h6' color='error'>Jo aktiv</Typography>
                            <ErrorIcon color='error' sx={{ ml: 1 }} />
                        </Box>
                    )}
                    {getExpiryDate() && (
                        <Typography sx={{ mt: 1 }}>
                            Data e skadimit: <b>{getExpiryDate()}</b>
                        </Typography>
                    )}
                </Box>}
            </Paper>
            <Typography variant="h5" sx={{mb: 2}}>
                Pagesat
            </Typography>
            <TableContainer component={Paper}>
                {!subscriptionsLoading ?
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Numri</TableCell>
                                <TableCell>Emri shërbimit</TableCell>
                                <TableCell>Vlera</TableCell>
                                <TableCell>Paguar</TableCell>
                                <TableCell>Gjendja</TableCell>
                                <TableCell>Shkarko</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {subscriptions.length ? subscriptions.map((subscription, index) => (
                                <TableRow key={subscription.id}>
                                    <TableCell>{subscription.invoiceNr}</TableCell>
                                    <TableCell>{plansMap[subscription.plan.type]?.name || subscription.plan.type}</TableCell>
                                    <TableCell>{subscription.plan.price}€</TableCell>
                                    <TableCell align="left">
                                        {((subscription.status === 'paid' && subscription.paidAt)) ? moment(subscription.paidAt).format("DD.MM.YYYY") : '-'}

                                    </TableCell>
                                    <TableCell><Chip label={subscription.status === 'paid' ? 'Paguar' : 'Pa paguar'}
                                                     color={subscription.status === 'paid' ? 'success' : 'error'}/></TableCell>
                                    <TableCell>
                                        {subscription.status !== 'paid' &&
                                        <IconButton onClick={() => generateInvoice(subscription)}>
                                            <DownloadIcon/>
                                        </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            )) : <TableRow sx={{border: 0}}>
                                <TableCell>Nuk ka abonime</TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    </Table> : <ProgressSpinner/>}
            </TableContainer>


            <Dialog open={isModalOpen} onClose={handleModalClose}>
                <IconButton
                    aria-label="close"
                    onClick={handleModalClose}
                    sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                    <CloseIcon/>
                </IconButton>
                <DialogContent>
                    {modalContent === 'plans' && <Typography variant="h5" sx={{mb: 2}}>Zgjedh planin:</Typography>}
                    {modalContent === 'plans' && (
                        <Grid container spacing={2}>
                            {plans.filter((plan) => plansMap.hasOwnProperty(plan.type))
                                .sort((a, b) => a.price - b.price)
                                .map((plan: any) => (
                                <Grid item xs={4}>
                                    <Card sx={{ maxWidth: 140 }} elevation={2}>
                                        <CardContent sx={{ height: 140 }}>
                                            <Typography variant="h5" component="div">
                                               {plan.price}€
                                            </Typography>
                                            <Typography sx={{ mt: 0.5, mb: 2 }} color="text.secondary">
                                                {getPlan(plan).name}
                                            </Typography>
                                            <Typography variant="body2">
                                                {getPlan(plan).description}
                                            </Typography>
                                        </CardContent>
                                        <CardActions sx={{display: 'flex', justifyContent: 'center'}}>
                                            <Button fullWidth variant='contained' onClick={() => handleChoosePlan(plan)}>Zgjedh</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {modalContent === 'loading' && (
                        <Box sx={{
                            display: 'flex',
                            width: '550px',
                            height: '230px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress/>
                        </Box>
                    )}
                    {modalContent === 'success' && (
                        <Box sx={{textAlign: 'center'}}>
                            <Typography variant="h5" sx={{mb: 2}}>
                                <CheckCircleOutlineIcon sx={{width: '80px', height: '80px'}} color='success'/> <br/>
                                Fatura juaj është krijuar!
                            </Typography>
                            <Typography variant="body1" sx={{mb: 3}}>
                                Per të finalizuar abonimin tuaj, ju duhet të paguani faturën e krijuar për ju të cilën
                                mund ta shkarkoni duke klikuar butonin më poshtë!
                            </Typography>
                            <Button variant="contained" endIcon={<DownloadIcon/>}
                                    onClick={() => generateInvoice(selectedSubscription)}>
                                Shkarko faturën
                            </Button>
                        </Box>
                    )}
                    {modalContent === 'warning' && (
                        <Box sx={{textAlign: 'center'}}>
                            <Typography variant="body1" sx={{my: 4}}>
                                Nuk mund të zgjatni abonimin pasi që keni pagesë të pa paguar!
                            </Typography>
                            <Button variant="contained" onClick={() => handleModalClose()}>
                                Mbyll
                            </Button>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </Container>
    );
}

export default Subscriptions;
