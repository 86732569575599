import React from 'react';
import {Box, Card, CardContent, Container, Link, Typography} from "@mui/material";
import {comparativeLinks} from "../../../utils/constants";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface Props {
}

function CountriesTab(props: Props) {
    return (
        <Box maxWidth="md" sx={{ paddingLeft: 0, paddingRight: 0 }}>

            {/*<Link underline="none" href={"/search"}*/}
            {/*    sx={{ display: "flex", alignItems: "center", mt: -1, mb: 3 }}>*/}
            {/*    <ArrowBackIcon sx={{ mr: 2 }} />*/}
            {/*    <Typography variant="subtitle1" color="text.link" sx={{ fontSize: "1.2rem" }}>*/}
            {/*        Kthehu*/}
            {/*    </Typography>*/}
            {/*</Link>*/}

            <Typography variant="h6" color="text.secondary" gutterBottom sx={{ mb: 3 }}>
                Lidhje të jashtme <br/> (Legjislacioni dhe praktika gjyqësore në
                Bashkimin Evropian dhe shtetet Evropiane).
            </Typography>

            <Typography variant="body1" color="text.secondary" gutterBottom sx={{ mb: 3 }}>
                Më poshtë përdoruesit mund të gjejnë linqe për burime ligjore të nevojshme të sistemeve ligjore të vendeve të Evropiane.
            </Typography>

            {comparativeLinks.map((country) => {
                return (
                    <Card sx={{ mb: 1.5, boxShadow: (theme) => theme.shadows[2] }}>
                        <CardContent sx={{ pb: "16px !important" }}>
                            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                {country.name}
                            </Typography>
                            {country.primary.map((link) => (
                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                    <Link href={link.url} target="_blank" underline="none">
                                        {link.url}
                                    </Link>
                                </Typography>
                            ))}
                            <Box sx={{ mt: 1 }}>
                                {country.secondary.map((link) => (
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        <b>{link.name} - </b>
                                        <Link href={link.url} target="_blank" underline="none" sx={{}}>
                                            {link.url}
                                        </Link>
                                    </Typography>
                                ))}
                            </Box>
                        </CardContent>
                    </Card>
                )
            })}

        </Box>
    );
}

export default CountriesTab;
