import {Action} from "../actions/app";
import * as constants from "../constants";

export interface VerdictsState {
    verdict: any;
    verdicts: Array<any>;
    totalVerdicts: number;
    verdictsLoading: boolean;
    verdictsPageSize: number;
    verdictsLastUpdated: string;
}

const getInitialState = () => {
    return {
        verdict: null,
        verdicts: [],
        totalVerdicts: 0,
        verdictsLoading: true,
        verdictsPageSize: 40,
        verdictsLastUpdated: '',
    }
}

const verdicts = (state: VerdictsState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_VERDICTS: {
            return {...state, verdicts: action.payload.data, totalVerdicts:action.payload.total , verdictsLoading: false};
        }

        case constants.FETCHED_VERDICT: {
            return {...state, verdict: action.payload, verdictsLoading: false};
        }

        case constants.SET_VERDICTS_LOADING: {
            return {...state, verdictsLoading: action.payload};
        }

        case constants.SET_VERDICTS_PAGE_SIZE: {
            return {...state, verdictsPageSize: action.payload};
        }

        case constants.FETCHED_VERDICTS_LAST_UPDATED: {
            return {...state, verdictsLastUpdated: action.payload};
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        default: {
            return {...state};
        }

    }
}

export default verdicts;
