import React, { useState, useEffect } from 'react';
import { styled, Box, Typography, IconButton } from '@mui/material';
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootState';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from "react-router-dom";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { deleteFavDocument, getMyFavDocuments } from '../../redux/actions/favorites';
import { confirm } from '../ConfirmDialog/confirm';
import styles from './FavoriteCard.module.scss';

interface Props {
    document: any;
}

const MainCardContent = styled(Box)(({theme}) => ({
    backgroundColor: "rgba(223,231,238,0.5)",
    padding: theme.spacing(2, 2, 2, 2),
    borderRadius: '4px'
}));

const MainCardContentPage = styled(Box)(({theme}) => ({
    marginTop: '5px',
    backgroundColor: "rgba(223,231,238,0.5)",
    padding: theme.spacing(0.6, 0.6, 0.6, 0.6),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '2rem',
    borderRadius: '4px'
}));

const PageButton = styled(Typography)(({theme}) => ({
    backgroundColor: "#f5f5f5",
    color: "#1976d2",
    fontSize: '14px',
    borderLeft: '2px solid #1976d2',
    width: 'fit-content',
    cursor: document ? 'pointer' : '',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    paddingLeft: '10px',
    marginLeft: '4px'
}));

function LawFavoriteCard({ document }: Props) {
    const history = useHistory();
    const dispatch: any = useDispatch();
    const favList = useSelector((state: RootState) => state.favorites.favoriteDocuments);

    const [lawsListWithPage, setLawsListWithPage] = useState([]);

    useEffect(() => {
        if(favList && favList.data && favList.data.length > 0){
            const favoriteList = favList.data.filter((doc: any) => doc.type === "LAW" && doc.page !== "0" && doc.documentId === document.documentId);
            const sorted = favoriteList.sort((a: any, b: any) => (a.page > b.page ? 1 : a.page < b.page ? -1 : 0));
            setLawsListWithPage(sorted);
        }
    }, [favList, document.documentId]);

    const onDeleteFavDocument = async(id: any) => {
        if(!await confirm(null, {title: "Dëshironi ta fshini dokumentin e ruajtur?", cancelButton: "Jo"})) {
            return;
        }

        if(lawsListWithPage.length > 0){
            lawsListWithPage.forEach((element: any) => {
                dispatch(deleteFavDocument(element.id));
            });
        }
        dispatch(deleteFavDocument(id)).then((res: any) => {
            dispatch(getMyFavDocuments());
        })
    }

    const onDeletePagFavDocument = async(id: any) => {
        if(!await confirm(null, {title: "Dëshironi ta fshini dokumentin e ruajtur?", cancelButton: "Jo"})) {
            return;
        }
        dispatch(deleteFavDocument(id)).then((res: any) => {
            dispatch(getMyFavDocuments());
        })
    }

    const goToPageOfDocument = (id: any, page: any) => {
        history.push(`/documents/${id}/${page}`);
    }

    return (
        <Box sx={{ mb: 1.5 }}>
            {lawsListWithPage && (
                <Box>
                    <MainCardContent>
                        <div className={styles.FavoriteCardTitleWrapper}>
                            <Typography variant="h6" color="text.secondary">
                                <RouterLink style={{textDecoration: 'none'}} to={document.type === "LAW" ? `/documents/${encodeURIComponent(document.documentId)}/${document.content.page}` : `/verdicts/${encodeURIComponent(document.documentId)}`}>
                                    {document.content.documentName}
                                </RouterLink>
                            </Typography>
                            <IconButton color="primary" onClick={() => onDeleteFavDocument(document.id)}>
                                <BookmarkIcon />
                            </IconButton>
                        </div>
                        <div className={styles.FavoritedCardDescriptionsWrapper}>
                            <p>
                                <span>Legjislacion</span>
                            </p>
                            <p>
                                Lloji i dokumentit: <span>{document.content.documentType}</span>
                            </p>
                            <p>
                                Shënim: <span>{document.note}</span>
                            </p>
                        </div>
                    </MainCardContent>
                    {lawsListWithPage.length > 0 && lawsListWithPage.map((document: any) => (
                        <MainCardContentPage>
                            <Box>
                                <PageButton onClick={() => goToPageOfDocument(document.documentId, document.page)}>
                                    Faqe: {document.page}
                                </PageButton>
                                <div style={{ marginLeft: '17px' }} className={styles.FavoritedCardDescriptionsWrapper}>
                                    <p>
                                        Shënim: <span>{document.note}</span>
                                    </p>
                                </div>
                            </Box>
                            <IconButton color='primary' size='small' className={styles.FavoriteIcon} onClick={() => onDeletePagFavDocument(document.id)}>
                                <BookmarkIcon fontSize='small' />
                            </IconButton>
                        </MainCardContentPage>
                    ))}
                </Box>
            )}
        </Box>
    );
}

export default LawFavoriteCard;
