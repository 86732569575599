import React, {useEffect, useState} from 'react';
import {Box, Chip, Container, Typography, Card, CardContent, Grid} from "@mui/material";
import DocumentCard, {StatusTranslations} from "../../../components/DocumentCard";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootState";
import DocumentsSkeleton from "../../../components/DocumentsSkeleton";
import {
    getDocuments,
    getDocumentsLastUpdated,
    getDocumentsWithFilters,
    setDocumentsLoading
} from "../../../redux/actions/documents";
import NoResults from "../../../components/NoResults";
import {paginationPageSize, SearchTypes} from "../../../utils/constants";
import Pagination from "../../../components/Pagination";
import {setDocumentsSearchFilters} from "../../../redux/actions/search";
import {setDocumentsFilter} from "../../../redux/actions/documentsFilters";
import { getVerdicts } from '../../../redux/actions/verdicts';
import moment from "moment";

interface Props {
    changeTab: (tab: number) => void;
}

function DocumentsTab(props: Props) {

    const dispatch: any = useDispatch();


    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(40);

    const searchText = useSelector((state: RootState) => state.search.searchText);
    const searchType = useSelector((state: RootState) => state.search.searchType);
    const documentFilters = useSelector((state: RootState) => state.documentsFilters.appliedDocumentsFilters);
    const documents = useSelector((state: RootState) => state.documents.documents);
    const totalDocuments = useSelector((state: RootState) => state.documents.totalDocuments);
    const documentsLoading = useSelector((state: RootState) => state.documents.documentsLoading);
    const favList = useSelector((state: RootState) => state.favorites.favoriteDocuments);
    const documentsLastUpdated = useSelector((state: RootState) => state.documents.documentsLastUpdated);
    const [foundVerdicts, setFoundVerdicts] = useState(false);

    const pagesCount = Math.ceil(documents.length / 10);

    const filtersApplied = Object.keys(documentFilters).some((filter) => documentFilters[filter]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        if(value > pagesCount) {
            setPageSize(pageSize + 20);
        } else {
            setPage(value);
        }
    };

    const fetchDocuments = () => {
        const payload: any = {
            maxResults: pageSize,
            phrase: searchText
        }


        if(searchText) {
            dispatch(setDocumentsLoading(true));
            setFoundVerdicts(false);

            if(searchType === SearchTypes.Name) {
                dispatch(getDocumentsWithFilters("", {documentTitle: searchText, ...documentFilters}, pageSize));
            } else if(filtersApplied && searchType === SearchTypes.Phrase) {
                dispatch(getDocumentsWithFilters(searchText, documentFilters, pageSize));
            } else {
                console.log("here");
                dispatch(getDocuments(payload)).then((res: any) => {
                    console.log(res);
                    if(res.data?.data?.length === 0) {
                        // will be handled if user has subscribsions
                        dispatch(getVerdicts(payload)).then((res: any) => {
                            if(res.data?.data?.length >= 1){
                                setFoundVerdicts(true);
                            }
                        });
                    }
                });                
            }
        }
    }

    useEffect(() => {
        setPage(1);
        setPageSize(40);
    }, [searchText]);

    useEffect(() => {
        fetchDocuments();
    }, [searchText, searchType, documentFilters, pageSize]);

    useEffect(() => {
        dispatch(getDocumentsLastUpdated());
    }, []);

    const isFavorited = (document: any) => {
        const itemExists = favList.data.filter((item: any) => item.documentId.toString() === document.id.toString() && item.page === "0");
        if (itemExists.length > 0) {
            return itemExists[0].id;
        }else{
            return false;
        }
    }

    return (
        <Box sx={{mt: 1.5}}>

            {/* {filtersApplied && <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                <Typography sx={{ mr: 1 }}>
                    Filtrat e aplikuar:
                </Typography>
                {Object.keys(documentFilters).map((key) => {
                    return documentFilters[key] && <Chip label={StatusTranslations[documentFilters[key]]} onDelete={() => dispatch(setDocumentsFilter({ name: key, value: null }))}/>
                })}
            </Box>} */}

            {documentsLoading && <DocumentsSkeleton />}

            {!documentsLoading && (
                <p style={{marginBottom: '20px', color:'#808080'}}>Janë gjetur <b>{totalDocuments?.toLocaleString()}</b> rezultate</p>
            )}

            {!documentsLoading && favList && (
                documents.length
                    ? documents.map((document: any, index) => {
                        const previousPages = (page-1)*paginationPageSize;
                        const nextPages = page*paginationPageSize;
                        const isVisible = ((index + 1) > previousPages && (index) < nextPages);
                        return isVisible && <DocumentCard key={`document-card-${index}`} document={document} isFavorited={isFavorited(document)} />
                    }) : <NoResults found={foundVerdicts} current="documents" changeTab={(tab: number) => props.changeTab(tab)} />
            )}

            {!!(searchText && !documentsLoading && documents.length) &&
            <Pagination
                page={page}
                count={Math.ceil(documents.length / 10)}
                more={pagesCount < 10 && !(documents.length < pagesCount * 10)}
                onChange={handlePageChange} />}

        </Box>
    );
}

export default DocumentsTab;
