import API from "../../utils/api";
import {FETCHED_SUBSCRIPTIONS, SET_SUBSCRIPTIONS_LOADING} from "../constants";
import {Action} from "./app";
import {localStorageAccessTokenKey} from "../../utils/constants";

export const setSubscriptionsLoading = (value: boolean): Action => ({
    type: SET_SUBSCRIPTIONS_LOADING,
    payload: value
})

export const getSubscriptions = () => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("subscriptions", {
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCHED_SUBSCRIPTIONS, payload: response.data.data });
        return response;
    } catch (err) {
        return err;
    }
};

export const createSubscription = (planId: string) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.post("subscriptions", { planId },{
            headers: {"Authorization": "Bearer " + token}
        });
        return response;
    } catch (err) {
        return err;
    }
};
