import {Action} from "../actions/app";
import * as constants from "../constants";

export interface LawTermsState {
    lawTerms: Array<any>
}

const getInitialState = () => {
    return {
        lawTerms: [],
    }
}

const lawTerms = (state: LawTermsState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_LAW_TERMS: {
            return {...state, lawTerms: action.payload};
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        default: {
            return {...state};
        }

    }
}

export default lawTerms;
