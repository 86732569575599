import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootState";
import {Autocomplete, Link, TextField, Typography} from '@mui/material';
import VerdictFavoriteCard from '../../components/FavoriteCard/VerdictFavoriteCard';
import LawFavoriteCard from '../../components/FavoriteCard/LawFavoriteCard';
import styles from './FavoriteDocuments.module.scss';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useHistory} from "react-router-dom";


function FavoriteDocuments() {
    const history = useHistory();
    const favList = useSelector((state: RootState) => state.favorites.favoriteDocuments);

    const [items, setItems] = useState([]);
    const [notes, setNotes] = useState([]);
    const [search, setSearch] = useState();

    useEffect(() => {
        if(favList && favList.data && favList.data.length > 0){
            const searchNotes = favList.data.map((item: any) => item.note);
            let uniqueSearchNotes: any = [];
            searchNotes.forEach((element: any) => {
                if(!uniqueSearchNotes.includes(element)){
                    uniqueSearchNotes.push(element);
                }
            });
            setNotes(uniqueSearchNotes);
            if(search === "" || search === null || search === undefined){
                setItems(favList.data.filter((doc: any) => doc.page === "0" || doc.page === null));
            }else{
                const getDocs = favList.data.filter((doc: any) => (doc.page === "0" || doc.page === null) && doc.note === search);
                const getPages = favList.data.filter((doc: any) => doc.page !== "0" && doc.note === search);
                getPages.forEach((element: any) => {
                    if(!getDocs.find((item: any) => item.documentId === element.documentId)){
                        const found = favList.data.filter((el: any) => el.documentId === element.documentId);
                        if (found){
                            const findElement = found.find((item: any) => (item.page === "0" || item.page === null));
                            getDocs.push(findElement);
                        }
                    }
                });
                setItems(getDocs);
            }
        }
    }, [search, favList]);

    return (
        <div>
            <Typography variant='h5' sx={{ mb: 2 }}>
                Dokumentet e ruajtura
            </Typography>
            <div className={styles.SearchWrapper}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size='small'
                    options={notes}
                    value={search}
                    noOptionsText="Nuk ka shënime"
                    onChange={(e, newValue: any) => setSearch(newValue)}
                    renderInput={(params) => <TextField {...params} label="Kërko sipas shënimit" />}
                />
            </div>
            {favList && favList.data && favList.data.length > 0 && items.map((document: any, index: any) => (
                document.type === "LAW" ? (
                    <LawFavoriteCard key={`document-card-${index}`} document={document} />
                ) : (
                    <VerdictFavoriteCard key={`document-card-${index}`} document={document} />
                )
            ))}
        </div>
    );
}

export default FavoriteDocuments;
