import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2'
        },
        secondary: {
            main: '#2f8be2',
        },
        success: {
            main: '#449e48',
        },
        error: {
            main: '#dc3c31',
        },
        info: {
            main: '#fff'
        }
    },
});

export default theme;
