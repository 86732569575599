import React from 'react';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import {Box, styled, Typography} from "@mui/material";
import {getQueryParameterByName} from "../../utils";
import {useTranslation} from "react-i18next";

import styles from './NoResults.module.scss';

interface Props {
    current?: string;
    found?: boolean;
    changeTab?: (tab: number) => void;
}

const NoResultsContainer = styled(Box)(({theme}) => ({
    display: "flex",
    marginTop: "2rem"
}));


const NoResults = ({ found, current, changeTab }: Props) => {
    const {t} = useTranslation();
    const phrase = getQueryParameterByName("q");

    return (
        <NoResultsContainer>
            <SearchOffIcon color="disabled" sx={{ fontSize: "4rem" }} />
            {current === 'documents' && (
                <div>
                    <Typography variant="h6" color="gray" sx={{ ml: 1 }}>
                        {t("Kërkimi juaj")} - <b>{phrase}</b> - {t("nuk u gjet në asnjë dokument në ")} <span className={styles.Upercase}>{t("Legjislacion.")}</span> {t("Ju lutem provoni me fjalë të tjera!")}
                    </Typography>
                    {found && (
                        <Typography variant="h6" color="gray" sx={{ ml: 1 }}>
                            {t("Janë gjetur rezultate në ")} <span className={styles.ColorBlue} onClick={() => changeTab && changeTab(1)}>{t('Praktikë Gjyqësore.')}</span>
                        </Typography>
                    )}
                </div>
            )}
            {current === 'verdicts' && (
                <div>
                    <Typography variant="h6" color="gray" sx={{ ml: 1 }}>
                        {t("Kërkimi juaj")} - <b>{phrase}</b> - {t("nuk u gjet në asnjë dokument në ")} <span className={styles.Upercase}>{t("Praktikë Gjyqësore")}</span> {t(". Ju lutem provoni me fjalë të tjera!")}
                    </Typography>
                    {found && (
                        <Typography variant="h6" color="gray" sx={{ ml: 1 }}>
                            {t("Janë gjetur rezultate në ")} <span className={styles.ColorBlue} onClick={() => changeTab && changeTab(0)}>{t("Legjislacion.")}</span>
                        </Typography>
                    )}
                </div>
            )}
        </NoResultsContainer>
    );
}

export default NoResults;
