import React, {useEffect} from 'react';
import {
    Button,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Alert
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/reducers/rootState";
import {clearVerdictsSearchFilters, setVerdictsSearchFilters} from "../../redux/actions/search";
import CloseIcon from "@mui/icons-material/Close";
import {clearVerdictsFilters, setVerdictsFilter, setVerdictsFilters} from "../../redux/actions/verdictsFilters";
import {parseFiltersFromBackend} from "../../utils";
import styles from "./VerdictFilters.module.scss";
import {setDocumentsFilter} from "../../redux/actions/documentsFilters";

interface Props {
    
}

const courts = [
    {label: "Të gjitha", value: ""},
    {label: "Gjykata Kushtetuese", value: "Constitutional Court"},
    {label: "Gjykata Supreme", value: "Gjykata Supreme"},
    {label: "Gjykata e Apelit", value: "Gjykata e Apelit"},
    {label: "Gjykata Themelore e Prishtinës", value: "Gjykata Themelore e Prishtinës"},
    {label: "Gjykata Themelore e Gjilanit", value: "Gjykata Themelore e Gjilanit"},
    {label: "Gjykata Themelore e Prizrenit", value: "Gjykata Themelore e Prizrenit"},
    {label: "Gjykata Themelore e Mitrovicës", value: "Gjykata Themelore e Mitrovicës"},
    {label: "Gjykata Themelore e Gjakovës", value: "Gjykata Themelore e Gjakovës"},
    {label: "Gjykata Themelore e Pejës", value: "Gjykata Themelore e Pejës"},
    {label: "Gjykata Themelore e Ferizajit", value: "Gjykata Themelore e Ferizajit"},
]

const filterNameToLabelMapping: any = {
    "court": "Gjykata",
    "documentType": "Lloji i aktit",
    "procedure": "Procedura",
    "branch": "Dega",
};

const VerdictFilters = (props: Props) => {

    const dispatch = useDispatch();

    const allFilters = useSelector((state: RootState) => state.verdictsFilters.allVerdictsFilters);
    const verdictsFilters = useSelector((state: RootState) => state.verdictsFilters.verdictsFilters);
    const appliedFilters = useSelector((state: RootState) => state.verdictsFilters.appliedVerdictsFilters);

    const filtersApplied = Object.keys(appliedFilters).some(filter => appliedFilters[filter]);

    const filters: any = Object.keys(appliedFilters).map((filterKey) => {
        const filterExists = verdictsFilters.find((filter: any) => filter.name === filterKey);
        if(filterExists) {
            return filterExists;
        } else {
            const randomFilter = allFilters.find((filter) => filter.name === filterKey);
            if(randomFilter) {
                randomFilter.values = [];
            }
            return randomFilter;
        }
    });

    const handleFilterChanged = (event: SelectChangeEvent) => {
        dispatch(setVerdictsFilter({ name: event.target.name, value: event.target.value }));
    }

    const handleFilterDelete = (filter: any) => {
        dispatch(setVerdictsFilter({ name: filter, value: "" }));
    }

    useEffect(() => {

        const finalFilters: any = { ...appliedFilters };
        Object.keys(appliedFilters).forEach((filterKey) => { //loop through the currently applied filters obj
            const filterValue = appliedFilters[filterKey]; //get the value of the applied filter
            if(filterValue) {
                const newFilters = allFilters.find((filter) => filter.value === filterValue); //find the filter by value on allFilters (should check here also by key not just value, that is why sometimes there are undefined values)
                if(newFilters) {
                    const filters = { ...newFilters.filters }; //get the associated filters of the found filter
                    Object.keys(filters).forEach((newFilterKey) => { //loop through the associated filters
                        if(newFilterKey !== filterKey) { //we need to apply new filters (options) only to the filters that are not applied (filterKey belongs to applied)
                            if(finalFilters[newFilterKey]) { //we need to intersect only when we have already added filters to that key
                                if(!appliedFilters[newFilterKey]) { //we nned to intersect only when that filter is applied
                                    const newFilters = [...filters[newFilterKey]];
                                    finalFilters[newFilterKey] = finalFilters[newFilterKey].filter((filter: any) => newFilters.includes(filter)) //we only add filters that were already there included
                                }
                            } else {
                                finalFilters[newFilterKey] = [ ...filters[newFilterKey] ] //otherwise we just insert the new filters entirely
                            }
                        } else {
                            finalFilters[filterKey] = [filterValue] //if the filter is applied we just set one value inside it, which is the applied filter (we do not touch/change it)
                        }
                    });
                }
            }
        });

        if(Object.values(finalFilters).every(value => value)) {
            let newFilterOptions: any = [];
            Object.keys(finalFilters).forEach((filterKey) => { //loop through the new filter object that was calculated
                //form an array with the original filter objects that were received from the backend
                const filterObjects = finalFilters[filterKey].map((filterValue: any) => {
                    return allFilters.find((filter) => filter.value == filterValue && filter.name == filterKey);
                })
                newFilterOptions = [ ...newFilterOptions, ...filterObjects ];
            });

            dispatch(setVerdictsFilters(newFilterOptions));
        } else {
            dispatch(setVerdictsFilters(allFilters));
        }



    }, [appliedFilters]);

    // useEffect(() => {
    //     verdictsFilters.forEach((filter) => {
    //         if(!appliedFilters[filter.name] && filter.values.length === 1){
    //             const filterToApply = filter.values[0];
    //             dispatch(setVerdictsFilter({ name: filter.name, value: filterToApply.value }));
    //         }
    //     });
    // }, [verdictsFilters])

    const clearFilters = () => {
        dispatch(clearVerdictsFilters());
    }

    // const selectedCourt = courtFilters.find((court) => filters.court === court.value);

    return (
        <Box>

            <Grid container spacing={2} sx={{ mt:.5 }}>
                {filters.map((filter: any, index: number) => (
                    filter && <Grid item xs={12} md={3} key={index}>
                        <FormControl fullWidth size="small" disabled={!filter.values.length}>
                            <InputLabel id="demo-simple-select-label">{filter.labelAl || filterNameToLabelMapping[filter.name]}</InputLabel>
                            <Select
                                className={appliedFilters[filter.name] && styles.hideIcon}
                                sx={{backgroundColor: 'white', borderRadius: '.7rem'}}
                                label={filter.labelAl || filterNameToLabelMapping[filter.name]}
                                name={filter.name}
                                value={appliedFilters[filter.name] || ""}
                                MenuProps={{
                                    style: {
                                       maxHeight: 440,
                                          },
                                    }}
                                onChange={handleFilterChanged}>
                                {filter.values.sort((a: any, b: any) => a.value.localeCompare(b.value))
                                    .map((value: any, index: number) =>
                                    <MenuItem
                                        key={`verdict-filter-dropdown-item-${index}`}
                                        value={value.value}>{value.label}</MenuItem>
                                )}
                            </Select>
                            {appliedFilters[filter.name]  && (
                                <IconButton sx={{position: 'absolute', right: '1px', top: '0px'}} onClick={() => handleFilterDelete(filter.name)}>
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </FormControl>
                    </Grid>
                ))}
            </Grid>

            {filtersApplied && (
                <Box sx={{display: 'flex', justifyContent: 'end'}}>
                    <Button onClick={clearFilters}>
                        Fshij filtrat
                    </Button>
                </Box>
            )}

            {filtersApplied && appliedFilters.documentType && appliedFilters.documentType === 'Aktgjykim' && (
                <Alert severity="info">
                    Tek lloji i aktit ju keni aplikuar filtrin “Aktgjykim”. Megjithatë në rezultatet e më poshtme mund të ketë edhe llojet tjera të akteve të vendosjes gjyqësore meritore.
                </Alert>
            )}
        </Box>
    );
}

export default VerdictFilters;
