import axios from 'axios';
import {toast} from "react-hot-toast";
import store from "../redux/store";
import {authenticate, getIpAddress, logout} from "../redux/actions/auth";
import {localStorageRefreshTokenKey} from "./constants";
// const baseURL = process.env.BASE_URL;

export const baseUrl = process.env.REACT_APP_API_URL || "invalid";

const instance = axios.create({
    baseURL: baseUrl
});

instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, async function (error) {
    const originalConfig = error.config;
console.log(originalConfig)

    if (originalConfig.url !== "register") {
        if (originalConfig.url !== "oauth/token" && error.response) {
            // Access Token was expired
            if (error.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const ipAddressResponse = await store.dispatch(getIpAddress());
                    const data = `grant_type=refresh_token&refresh_token=${window.localStorage.getItem(localStorageRefreshTokenKey)}&using_ip=${encodeURIComponent(ipAddressResponse.data)}`;
                    const res = await store.dispatch(authenticate(data));
                    originalConfig.headers.Authorization = 'Bearer ' + res.data.access_token;
                    return instance(originalConfig);
                } catch (error) {
                    store.dispatch(logout());
                    window.location.reload();
                    return Promise.reject(error);
                }
            }
        } else {
            if(error.request.status === 401) {
                store.dispatch(logout());
            }
        }
    }

    let errorMessage = "Ka ndodhur një problem!";
    if(error?.response?.data?.message) {
        errorMessage = error?.response?.data?.message
    }
    if(error?.response?.data?.error_description) {
        errorMessage = error?.response?.data?.error_description
    }
    if(errorMessage.startsWith("Invalid refresh token")) {
        errorMessage = "Sesioni juaj ka skaduar, ju lutemi kyçuni përsëri";
    }

    if(!errorMessage.startsWith("String index out of range")) {
        // toast.error(errorMessage);
    }


    return Promise.reject(error);
});

export default instance;
