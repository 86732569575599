import * as React from 'react';
import { useState } from 'react';
import {
    Link,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Button, Box, Toolbar, Typography, Container
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {useHistory} from "react-router-dom";
import styles from "./Landing.module.scss";
import {ReactComponent as WhatIsLexdoksImg} from "../../assets/images/landing/whatislexdoks.svg";
import {ReactComponent as WhyLexdoksImg} from "../../assets/images/landing/whylexdoks.svg";
import {ReactComponent as WithLexdoksImg} from "../../assets/images/landing/withlexdoks.svg";
import {ReactComponent as ServicesImg} from "../../assets/images/landing/services.svg";
import {ReactComponent as EmailIcon} from "../../assets/images/icons/email.svg";
import {ReactComponent as InfoIcon} from "../../assets/images/icons/info.svg";
import {ReactComponent as FbIcon} from "../../assets/images/icons/fb.svg";
import {ReactComponent as LinkedInIcon} from "../../assets/images/icons/linkedin.svg";
import {ReactComponent as ArrowRight} from "../../assets/images/icons/arrow-right.svg";
import {ReactComponent as ArrowLeft} from "../../assets/images/icons/arrow-left.svg";
import ArberJashariImg from '../../assets/images/landing/clients/Arber-Jashari.jpg';
import ArdianMehmetiImg from '../../assets/images/landing/clients/Ardian-Mehmeti.png';
import EdonaSinaImg from '../../assets/images/landing/clients/Edona-Sina.jpg';
import FlorijeManajZogajImg from '../../assets/images/landing/clients/Florije-Manaj-Zogaj.jpg';
import LeutrimSylaImg from '../../assets/images/landing/clients/Leutrim-Syla.jpg';
import LeutrimHimajImg from '../../assets/images/landing/clients/Leutrim-Himaj.jpg';
import Slider from "react-slick";
import cdfLogo from '../../assets/images/landing/partners/cdf.png';
import universitetiIsaBoletini from '../../assets/images/landing/partners/universiteti-isa-boletini.jpeg';
import justechLogo from '../../assets/images/landing/partners/justech.png';
import kusaLogo from '../../assets/images/landing/partners/kusa.png';
import usEmbassyLogo from '../../assets/images/landing/partners/usEmbassy.png';
import qsLogo from '../../assets/images/landing/partners/qsLogo.jpg';
import tlpalumniassociationLogo from '../../assets/images/landing/partners/tlp.jpg';
import undpLogo from '../../assets/images/landing/partners/undp.png';
import kgkLogo from '../../assets/images/landing/partners/kgk.jpeg';
import rzzLogo from '../../assets/images/landing/partners/rzz.jpg';
import ghgLogo from '../../assets/images/landing/partners/ghg.png';
import rphsLogo from '../../assets/images/landing/partners/rphs.jpeg';
import mdLogo from '../../assets/images/landing/partners/md.png';
import inlLogo from '../../assets/images/landing/partners/inl.jpeg';
import lexBusinessLogo from '../../assets/images/landing/partners/lex-business.png';
import Logo from "../../assets/images/logo-blue.png";
import { useTranslation } from 'react-i18next';
import EnglishLanguage from "../../assets/images/eng.svg";
import AlbanianLanguage from "../../assets/images/alb.svg";

export default function Landing() {

    const history = useHistory();
    const { t, i18n } = useTranslation();

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [copyrightModal, setCopyrightModal] = useState(false);
    const [clientIndex, setClientIndex] = useState(0);
    const [partnerIndex, setPartnerIndex] = useState(0);

    const changeLanguageHandler = (lang: "en" | "sq") =>
    {
       i18n.changeLanguage(lang)
    }

    const clients = [
        {
            img: LeutrimSylaImg,
            name: "Leutrim Syla",
            proffesion: t("attorney"),
            message: t('leutrim-syla-msg')
        },
        {
            img: LeutrimHimajImg,
            name: "Leutrim Himaj",
            proffesion: t("attorney"),
            message: t('leutrim-himaj-msg')
        },
        {
            img: EdonaSinaImg,
            name: "Edona Sina",
            proffesion: t("attorney"),
            message: t("edona-sina-msg")
        },
        {
            img: ArberJashariImg,
            name: "Arbër Jashari",
            proffesion: t("attorney"),
            message: t("arber-jashari-msg")
        },
        {
            img: FlorijeManajZogajImg,
            name: "Florije Manaj-Zogaj",
            proffesion: t("attorney"),
            message: t("florije-manaj-zogaj-msg")
        },
        {
            img: ArdianMehmetiImg,
            name: "Ardian Mehmeti",
            proffesion: t("lawyer"),
            message: t("ardian-mehmeti-msg")
        }
    ];

    const partners = [
        {
            img: usEmbassyLogo,
            link: 'https://xk.usembassy.gov/',
            text: t("us-embassy-partner"),
        },
        {
            img: inlLogo,
            link: 'https://xk.usembassy.gov/embassy/pristina/sections-offices/',
            text: t("inl-partner"),
        },
        {
            img: kusaLogo,
            link: 'https://kusalumni.org/',
            text: t("kusa-partner"),
        },
        {
            img: kgkLogo,
            link: 'https://www.gjyqesori-rks.org/',
            text: t("kgk-partner"),
        },
        {
            img: mdLogo,
            link: 'https://md.rks-gov.net/',
            text: t("md-partner"),
        },
        {
            isNotRounded: true,
            img: undpLogo,
            link: 'https://www.undp.org/kosovo',
            text: t("undp-partner"),
        },
        {
            isNotRounded: false,
            img: qsLogo,
            link: 'https://www.facebook.com/SejdiuQerkini/',
            text: <>{t("qs-1-partner")} <br/> {t("qs-2-partner")}</>
        },
        {
            img: justechLogo,
            link: 'https://www.usaid.gov/sh/kosovo/news-information/news/first-ever-hackathon-generates-innovative-ideas-improve-kosovos-justice',
            text: t("usaid-partner")
        },
        {
            img: tlpalumniassociationLogo,
            link: 'https://www.facebook.com/tlpalumni',
            text: t("tlp-partner")
        },
        {
            img: rzzLogo,
            link: 'https://rzzlaw.com/',
            text: t("rzz-partner")
        },
        {
            img: universitetiIsaBoletini,
            link: 'https://www.umib.net/',
            text: t("isa-boletini-partner")
        },
        {
            img: ghgLogo,
            link: 'https://www.ghglaw.net/',
            text: t("ghg-partner"),
        },
        {
            img: rphsLogo,
            link: 'https://rphs.law/',
            text: t("rphs-partner"),
        },
        {
            img: lexBusinessLogo,
            link: 'https://lex-business.net/',
            text: t("lex-business-partner"),
        },
        // {
        //     img: cdfLogo,
        //     link: 'https://kcdf.org/'
        // },       
    ];


    const isMobile = window.innerWidth < 756;

    const scrollIntoView = (elementId: string) => {
        setDrawerOpen(false);
        const element = document.getElementById(elementId);
        if (element) {
            setTimeout(() => {
                element.scrollIntoView({behavior: "smooth"});
            }, isMobile ? 500 : 0)
        }
    }

    const NextArrow = ({onClick}: any) => {
        return (
            <div className="arrow next" onClick={onClick}>
                <ArrowRight/>
            </div>
        )
    }

    const PrevArrow = ({onClick}: any) => {
        return (
            <div className="arrow prev" onClick={onClick}>
                <ArrowLeft/>
            </div>
        )
    }

    const settings = {
        focusOnSelect: true,
        infinite: true, //to allow the slides to show infinitely
        speed: 300, //this is the speed of slider in ms
        slidesToShow: isMobile ? 1 : 3, //number of slides to show up on screen
        centerMode: true,
        nextArrow: <NextArrow/>, //imported from 'react-icons'
        prevArrow: <PrevArrow/>, //imported from 'react-icons'
        beforeChange: (current: any, next: any) => setClientIndex(next),
    };

    const settingsPartners = {
        infinite: true, //to allow the slides to show infinitely
        speed: 300, //this is the speed of slider in ms
        slidesToShow: isMobile ? 2 : 3, //number of slides to show up on screen
        nextArrow: <NextArrow/>, //imported from 'react-icons'
        prevArrow: <PrevArrow/>, //imported from 'react-icons'
        beforeChange: (current: any, next: any) => setPartnerIndex(next),
    };

    // useEffect(() => {
    //     (window as any).fwSettings={
    //         'widget_id':73000002872
    //     };
    //     if("function"!=typeof (window as any).FreshworksWidget){
    //         var n: any = function(){
    //             n.q.push(arguments)
    //         };
    //     }
    //     n.q=[];
    //     (window as any).FreshworksWidget=n;
    //
    //     const script = document.createElement('script');
    //     script.src = "https://widget.freshworks.com/widgets/73000002872.js";
    //     script.defer = true;
    //     script.async = true;
    //
    //     document.body.appendChild(script);
    //
    //     return () => {
    //         (window as any).FreshworksWidget("destroy");
    //         (window as any).FreshworksWidget = undefined;
    //         document.body.removeChild(script);
    //     }
    // }, []);

    return (
        <Box className={styles.LandingWrapper}>

            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}>
                <List sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    width: "50vw",
                    height: "100%",
                    color: "white"
                }}>
                    <ListItem button key="Shërbimet" onClick={() => scrollIntoView("services")}>
                        <ListItemText primary={t("services-menu")}/>
                    </ListItem>
                    {/* <ListItem button key="Vlerat" onClick={() => scrollIntoView("cards-section")}>
                        <ListItemText primary="Vlerat"/>
                    </ListItem> */}
                    <ListItem button key="Për ne" onClick={() => scrollIntoView("why-lexdoks")}>
                        <ListItemText primary={t("about-us-menu")}/>
                    </ListItem>
                    <ListItem button key="Kontakti" onClick={() => scrollIntoView("footer")}>
                        <ListItemText primary={t("contact-menu")}/>
                    </ListItem>
                    {/*<ListItem button key="Si të regjistrohem?" onClick={() => setModalOpen(true)}>*/}
                    {/*    <ListItemText primary="Si të regjistrohem?" />*/}
                    {/*</ListItem>*/}
                </List>
            </Drawer>

            <Box sx={{backgroundColor: '#fff', color: '#1976D2'}} position="relative">
                <Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
                    {/*<CameraIcon sx={{ mr: 2 }} />*/}
                        <img src={Logo} alt="logo" style={{ height: "2.6rem", marginTop: ".2rem" }}/>

                    <div>
                        {!isMobile && (
                            <div style={{display: 'flex'}}>
                                <Typography sx={{mr: 10, cursor: "pointer", fontSize: '1rem'}} variant="button"
                                            color="inherit" noWrap
                                            onClick={() => scrollIntoView("services")}>
                                    {t('services-menu')}
                                </Typography>
                                {/* <Typography sx={{mr: 2, cursor: "pointer"}} variant="button" color="inherit" noWrap
                                            onClick={() => scrollIntoView("cards-section")}>
                                    Vlerat
                                </Typography> */}
                                <Typography sx={{mr: 10, cursor: "pointer", fontSize: '1rem'}} variant="button"
                                            color="inherit" noWrap
                                            onClick={() => scrollIntoView("why-lexdoks")}>
                                    {t('about-us-menu')}
                                </Typography>
                                <Typography sx={{cursor: "pointer", fontSize: '1rem'}} variant="button" color="inherit"
                                            noWrap
                                            onClick={() => scrollIntoView("footer")}>
                                    {t('contact-menu')}
                                </Typography>
                                {/*<Typography sx={{ cursor: "pointer"  }} variant="button" color="inherit" noWrap onClick={() => setModalOpen(true)}>*/}
                                {/*    Si të regjistrohem?*/}
                                {/*</Typography>*/}
                                <Box sx={{ml: 10}}>
                                    <div className={styles.LanguageDropdown}>
                                        {i18n.language === "sq" ? (
                                            <div style={{ display: 'flex', alignItems: 'center'}}><img src={AlbanianLanguage} width="20px" alt="albanian" /> <span style={{ marginLeft: '5px', fontWeight: 'bold'}}>SQ</span></div>
                                        ) : (
                                            <div style={{ display: 'flex', alignItems: 'center'}}><img src={EnglishLanguage} width="20px" alt="english" /> <span style={{ marginLeft: '5px', fontWeight: 'bold'}}>EN</span></div>
                                        )}
                                        <div className={styles.LanguageDropdownContent}>
                                            <div onClick={() => changeLanguageHandler("sq")} className={styles.LanguageDropdownContentItem}>
                                                <img src={AlbanianLanguage} width="20px" alt="Albanian" />
                                                <span style={{marginLeft: '10px'}}>Shqip</span>
                                            </div>
                                            <div onClick={() => changeLanguageHandler("en")} className={styles.LanguageDropdownContentItem}>
                                                <img src={EnglishLanguage} width="20px" alt="English" />
                                                <span style={{marginLeft: '10px'}}>English</span>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </div>)}

                        {isMobile && (<IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={() => setDrawerOpen(true)}>
                            <MenuIcon/>
                        </IconButton>)}
                    </div>
                </Toolbar>
            </Box>
            <main>
                {/* Hero unit */}
                <div id="what-is-lexdoks" className={styles.WhatIsLexdoksSection}>
                    <Container>
                        <Box className={styles.WhatIsLexdoksSectionItems}>
                            <Box className={styles.WhatIsLexdoksSectionItem}>
                                <p className={styles.WhatIsLexdoksTitle}>
                                    {t('what-is')} <b>{t('lexdoks?')}</b>
                                </p>

                                <p className={styles.WhatIsLexdoksDescription}>
                                    {t('what-is-lexdoks-description')}
                                </p>
                                <Box sx={{mt: 4}}>
                                    <Button variant="contained"
                                            sx={{width: "12rem", borderRadius: '100px', mr: 4, fontSize: '1rem'}}
                                            onClick={() => history.push("/login")}>{t('sign-in-button')}</Button>
                                    <Button variant="outlined"
                                            sx={{width: "12rem", borderRadius: '100px', fontSize: '1rem'}}
                                            className={styles.RegisterButton}
                                            onClick={() => history.push("/register")}>{t('sign-up-button')}</Button>
                                </Box>
                            </Box>
                            <Box sx={isMobile ? {margin: "-40px 0 -90px 0"} : undefined}
                                 className={styles.WhatIsLexdoksSectionItem}>
                                <WhatIsLexdoksImg width="100%"/>
                            </Box>
                        </Box>
                    </Container>
                </div>

                <Box id="why-lexdoks" className={styles.WhyLexdoksSection}>
                    <Container>
                        <Box className={styles.WhyLexdoksSectionItems}>
                            <Box sx={isMobile ? {margin: "-80px 0 -40px 0"} : undefined}
                                 className={styles.WhyLexdoksSectionItem}>
                                <WhyLexdoksImg width="100%"/>
                            </Box>
                            <Box className={styles.WhyLexdoksSectionItem}>
                                <p className={styles.WhyLexdoksTitle}>
                                    {t('why')} <b>{t('lexdoks?')}</b>
                                </p>
                                <p className={styles.WhyLexdoksDescription}>
                                    {t('why-lexdoks-description')}
                                </p>
                            </Box>
                        </Box>
                    </Container>
                </Box>

                <Box id="services" className={styles.ServicesSection}>
                    <Container>
                        <Box className={styles.ServicesSectionItems}>
                            <Box className={styles.ServicesSectionItem}>
                                <p className={styles.ServicesTitle}>{t('services')}</p>
                                <div className={styles.ServicesItem}>
                                    <div className={styles.ServiceItemTitleWrapper}>
                                        <div className={styles.Circle}></div>
                                        <p className={styles.ServicesItemTitle}>{t('searching')}</p>
                                    </div>
                                    <p className={styles.ServicesItemDescription}>
                                        {t('searching-description')}
                                    </p>
                                </div>
                                <div className={styles.ServicesItem}>
                                    <div className={styles.ServiceItemTitleWrapper}>
                                        <div className={styles.Circle}></div>
                                        <p className={styles.ServicesItemTitle}>{t('research')}</p>
                                    </div>
                                    <p className={styles.ServicesItemDescription}>
                                        {t('research-description')}
                                    </p>
                                </div>
                                <div className={styles.ServicesItem}>
                                    <div className={styles.ServiceItemTitleWrapper}>
                                        <div className={styles.Circle}></div>
                                        <p className={styles.ServicesItemTitle}>{t('drafting')}</p>
                                    </div>
                                    <p className={styles.ServicesItemDescription}>
                                        {t('drafting-description')}
                                    </p>
                                </div>
                            </Box>
                            <Box sx={isMobile ? {margin: "-130px 0 -150px 0"} : undefined}
                                 className={styles.ServicesSectionItem}>
                                <ServicesImg width="100%"/>
                            </Box>
                        </Box>
                    </Container>
                </Box>

                <Box className={styles.WithLexdoksSection}>
                    <Container>
                        <Box className={styles.WithLexdoksSectionItems}>
                            <Box sx={isMobile ? {margin: "-140px 0 -70px 0"} : undefined}
                                 className={styles.WithLexdoksSectionItem}>
                                <WithLexdoksImg width="100%"/>
                            </Box>
                            <Box className={styles.WithLexdoksSectionItem}>
                                <p className={styles.WithLexdoksDescription}>
                                    {t('with-lexdoks-description')}
                                </p>
                                <Button variant="contained" className={styles.RegisterButton}
                                        onClick={() => history.push("/register")}>{t('sign-up-button')}</Button>
                            </Box>
                        </Box>
                    </Container>
                </Box>

                <Box className={styles.ClientsSection}>
                    <Container>
                        <p className={styles.ClientsSectionTitle}>
                            <b>{t('customer-testimonials')}</b>
                        </p>
                        <Slider {...settings}>
                            {clients.map((client, idx) => (
                                <div className={idx === clientIndex ? "slide activeSlide" : "slide"}>
                                    <img src={client.img} alt="" />
                                    <p className={styles.ClientTitle}>
                                        {client.name}
                                    </p>
                                    <p className={styles.ClientProffesion}>
                                        {client.proffesion}
                                    </p>
                                    <p className={styles.ClientMessage}>
                                        {client.message}
                                    </p>
                                </div>
                            ))}
                        </Slider>
                    </Container>
                </Box>
                <Box className={styles.PartnersSection}>
                    <Container>
                        <div className={styles.PartnersSectionItems}>
                            <div>
                                <p className={styles.PartnersSectionTitle}>{t('partners')}</p>
                            </div>
                            <div>
                                {/* <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                                    {partners.map((partner, idx) => (
                                        <a href={partner.link} target="_blank" rel="noreferrer">
                                            <img className={styles.PartnerImg} src={partner.img} alt="" />
                                        </a>
                                    ))}
                                </div> */}
                                <Slider {...settingsPartners}>
                                    {partners.map((partner, idx) => (
                                        <>
                                        <div className={`slidePartners ${partner.isNotRounded && "notRounded"}`}>
                                            <a href={partner.link} target="_blank" rel="noreferrer">
                                                <img src={partner.img} alt="" />
                                            </a>
                                        </div>
                                            <p className="slidePartnersText">
                                            <a href={partner.link} target="_blank" rel="noreferrer">
                                                {partner.text}
                                            </a>
                                            </p>
                                        </>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </Container>
                </Box>
            </main>

            <Box id="footer" className={styles.FooterSection}>
                <Container>
                    <div className={styles.FooterItems}>
                        <div className={styles.FooterItem}>
                            <img src={Logo} alt="logo" style={{ height: "2.6rem", marginTop: ".2rem" }}/>
                            <div className={styles.FooterInfoIconWithText}>
                                <EmailIcon width="20px"/> <span>info@lexdoks.com</span>
                            </div>
                            <div className={styles.FooterInfoIconWithText}>
                                <InfoIcon width="20px"/> <span>support@lexdoks.com</span>
                            </div>
                        </div>
                        <div>
                            <p className={styles.FooterSubtitle}>{t('web-footer')}</p>
                            <p style={{cursor: 'pointer'}} onClick={() => scrollIntoView("what-is-lexdoks")}>{t('what-is-lexdoks-footer')}</p>
                            <p style={{cursor: 'pointer'}} onClick={() => scrollIntoView("services")}>{t('services-footer')}</p>
                            <p style={{cursor: 'pointer'}} onClick={() => scrollIntoView("why-lexdoks")}>{t('why-lexdoks-footer')}</p>
                            <p style={{cursor: 'pointer'}} onClick={() => scrollIntoView("footer")}>{t('contact-footer')}</p>
                            {/* <p style={{cursor: 'pointer'}}>Shpallje</p>
                            <p style={{cursor: 'pointer'}}>Pyetje të shpeshta</p> */}
                        </div>
                        <div>
                            <p className={styles.FooterSubtitle}>{t('social-media-footer')}</p>
                            <div onClick={() => window.open("https://www.facebook.com/lexdoks.org", "_blank")}
                                 className={styles.FooterInfoIconWithTextLink}>
                                <FbIcon width="20px"/> <span>Facebook</span>
                            </div>
                            <div onClick={() => window.open("https://www.linkedin.com/company/lexdoks/", "_blank")}
                                 className={styles.FooterInfoIconWithTextLink}>
                                <LinkedInIcon width="20px"
                                              onClick={() => window.open("https://www.linkedin.com/company/lexdoks/", "_blank")}/>
                                <span>Linkedin</span>
                            </div>
                        </div>
                    </div>
                    <Box sx={{textAlign: 'center', pt: 4}}>
                        <Link sx={{cursor: 'pointer'}} onClick={() => setCopyrightModal(true)}>
                            {t('copyright-footer')}
                        </Link>
                    </Box>
                </Container>
            </Box>


            {/*<Modal*/}
            {/*    open={modalOpen}*/}
            {/*    onClose={() => setModalOpen(false)}*/}
            {/*    aria-labelledby="modal-modal-title"*/}
            {/*    aria-describedby="modal-modal-description">*/}
            {/*    */}
            {/*    <Box sx={modalBoxStyle}>*/}
            {/*        <iframe width="650" height="370" src="https://www.youtube.com/embed/ssw40dbh9dw"*/}
            {/*                title="YouTube video player" frameBorder="0"*/}
            {/*                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
            {/*                allowFullScreen />*/}
            {/*    </Box>*/}
            {/*</Modal>*/}

            <Dialog
                open={copyrightModal}
                onClose={() => setCopyrightModal(false)}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography>
                        © Lexdoks {new Date().getFullYear()} - E drejta e autorit & Heqja e përgjegjësisë
                    </Typography>
                    <CloseIcon sx={{cursor: 'pointer'}} onClick={() => setCopyrightModal(false)}/>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                    >
                        <Typography variant='body1' gutterBottom>
                            <b>E drejta e autorit:</b>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Materialet e disponueshme në këtë faqe interneti mbrohen nga legjislacioni në fuqi për të drejtën e autorit dhe janë prone e Legal Data Solutions L.L.C. dhe/ose partnerët e saj të ndërlidhura (të referuara së bashku si "Lexdoks").
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Lexdoks përmban dy grupe të dhënash. Të dhëna që gjenden brenda faqeve të internetit të autorizuara të Lexdoks dhe të dhëna që janë të aksesueshme përmes lidhjeve të jashtme me dokumentet ligjore të disponueshme publikisht.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Lidhur me të dhënat që përpunohen nga dhe që gjenden brenda faqeve të internetit të autorizuara të Lexdoks:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Lexdoks përpunon informacionin nga legjislacioni parësor dhe dytësor, praktika  gjyqësore dhe dokumente të tjera ligjore relevante dhe u ofron përdoruesve aftësi kërkimi për të mundësuar një navigim efikas brenda fushës së gjerë të legjislacionit dhe jurisprudencave gjyqësore. Procesi i kërkimit dhe rezultatet rrjedhin nga përpunimi kompleks i të dhënave bazuar në inteligjencën artificiale. Rezultatet u paraqiten përdoruesve në formularët e të dhënave të krijuara në mënyrë origjinale (skedarë, formularë, skeda, tabela, etj.).
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Në lidhje me rezultatet dhe të dhënat e përpunuara në lidhje me legjislacionin (parësor, dytësor dhe/ose lloj tjetër) çështjet gjyqësore, dhe të gjitha materialet e tjera të disponueshme në faqet e internetit të autorizuara të Lexdoks (Materialet e Përpunuara Lexdoks), individi mund të përdorë dhe të marrë nëpërmjet komandave të shkarkimit dhe të ruajë në Forma e lexueshme nga makineritë, kryesisht për përdorim ekskluziv të një personi, një kopje e vetme e pjesëve jo thelbësore të Materialeve të Përpunuara Lexdoks të përfshira në çdo skedar individual në masën që ruajtja e tillë nuk kufizohet ose ndalohet më tej nga politikat e tjera të Lexdoks.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Materialet e shkarkuara mund të printohen, kopjohen dhe shpërndahen në masën e lejuar nga ligji në fuqi për të drejtën e autorit dhe duke mos paragjykuar ndonjë ndalesë në politikat e Lexdoks, por nuk mund të riprodhohen ose shpërndahen ndryshe. Materialet e shkarkuara dhe të ruajtura në formë të lexueshme nga makina nuk mund të shfaqen në mënyrë elektronike për më shumë se një përdorues në të njëjtën kohë.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Në lidhje me të dhënat që janë të aksesueshme përmes lidhjeve të jashtme:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Në disa raste, faqet e autorizuara të Lexdoks do t'i ridrejtojnë përdoruesit te dokumentet ligjore zyrtare, origjinale dhe të disponueshme për publikun. Në raste të tilla, legjislacioni në fuqi do të zbatohet në lidhje me aksesin, përdorimin dhe/ose shpërndarjen e tyre.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Disa materiale të disponueshme në faqet e internetit të autorizuara të Lexdoks i nënshtrohen kufizimeve shtesë për përdorimin, kopjimin, shkarkimin ose shpërndarjen, si dhe terma dhe kushte të tjera.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Lexdoks ruan të drejtën e ndryshimit dhe përditësimit të njëanshëm të politikave të saj për procesimin dhe çasjen në të dhënat e poseduara në platofrmat që menaxhohen nga Lexdoks.
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            <b>Heqja e përgjegjësisë:</b>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Kjo faqe ofrohet nga Legal Data Solutions L.L.C. si krijuese dhe mirëmbajtëse e platformës  (këtu e tutje “Lexdoks”) sipas parimit “ashtu si është” dhe “kurdo që është e çasshme”.  Lexdoks shpalosë faktin, dhe përdoruesit e pranojnë që burimet origjinale të informacionit për legjislacionin dhe praktikën gjyqësore janë vetëm burimet zyrtare të përcaktuara me legjislacionin në fuqi. Duke qenë vetëm përpunues i informatave që përmbahen në këtë faqe, Lexdoks nuk jep kurfar garancioni, të shprehur apo nënkuptuar, mbi funksionimin, informacionet, përmbajtjen apo materialet në këtë faqe. Ju pajtoheni në mënyrë shprehimore që përdorimi i kësaj faqe është në rrezikun diskrecional tuajin.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCopyrightModal(false)}>Mbyll</Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}
