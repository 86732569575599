import React, {useState} from 'react';
import {Box, Container, Tab, Tabs} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootState";
import FormCard from "../../../components/FormCard";
import {baseUrl} from "../../../utils/api";
import {useTranslation} from "react-i18next";
import styles from "../Search.module.scss";
import CountriesTab from "../CountriesTab";
import JournalsTab from "../JournalsTab";

interface Props {
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function FormsTab(props: Props) {

    const {t, i18n} = useTranslation();
    const dispatch: any = useDispatch();

    const [currentTab, setCurrentTab] = useState(0);

    const forms = useSelector((state: RootState) => state.forms.forms);
    const accessToken = useSelector((state: RootState) => state.auth.accessToken);

    const handleDownload = (form: string) => {
        window.location.assign(baseUrl + "forms?fileName=" + form + "&access_token=" + accessToken);
    }

    const formsTab = () => (
        forms.map(form => {
            const visible = (i18n.language == "sr" && form.includes("SRB")) || (i18n.language == "sq" && !form.includes("SRB"));
            return visible && <FormCard form={form} onDownloadClick={() => handleDownload(form)} />
        })
    )

    return (
        <Box maxWidth="md" sx={{mt: 1.5}}>
            <Box sx={{
                backgroundColor: "#eff3f6",
                borderColor: 'divider',
                marginTop: '20px',
                marginBottom: '20px',
                borderRadius: '.8rem',
                paddingLeft: '1rem'}}>
                <Tabs className={styles.Tabs} value={currentTab} onChange={(e, tab) => setCurrentTab(tab)}
                      aria-label="basic tabs example">
                    <Tab className={styles.TabButton} sx={currentTab === 0 ? {
                        backgroundColor: '#eff3f6',
                    } : {color: '#808080'}} label={t("Forma ligjore")} {...a11yProps(0)} />
                    <Tab className={styles.TabButton} sx={currentTab === 1 ? {
                        backgroundColor: '#eff3f6',
                    } : {color: '#808080'}} label={t("Legjislacioni ndërkombëtar")} {...a11yProps(1)} />
                    <Tab className={styles.TabButton} sx={currentTab === 2 ? {
                        backgroundColor: '#eff3f6',
                    } : {color: '#808080'}} label={t("Revista shkencore")} {...a11yProps(2)} />
                </Tabs>
            </Box>

            {currentTab === 0 && formsTab()}
            {currentTab === 1 && <CountriesTab />}
            {currentTab === 2 && <JournalsTab />}
        </Box>
    );
}

export default FormsTab;
