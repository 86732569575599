import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/reducers/rootState";
import {getDocumentById, searchWithinDocument, setDocumentLoading} from "../../redux/actions/documents";
import { deleteFavDocument } from '../../redux/actions/favorites';
import {useHistory, useParams} from "react-router-dom";
import {Button, Container, Link, Typography, Box, Card, styled, IconButton, Tooltip} from "@mui/material";
import {getQueryParameterByName, parsePageContent, removePages} from "../../utils";
import SearchInput from "../../components/SearchInput";
import {setSearchText, setSearchType} from "../../redux/actions/search";
import {useTranslation} from "react-i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LabeledValue from '../../components/shared-components/LabeledValue';
import PageWithDescription from '../../components/shared-components/PageWithDescription';
import { ReactComponent as ArrowDown } from "../../assets/images/icons/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/icons/arrow-up.svg";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import FavoriteModal from '../../components/FavoriteModal';
import styles from './Document.module.scss';
import { confirm } from '../../components/ConfirmDialog/confirm';

interface Props {

}

const StatusTranslations: any = {
    "NË FUQI": "Në fuqi",
    "NDRYSHUAR": "I ndryshuar",
    "SHFUQIZUAR": "I shfuqizuar"
}

const MainCardContent = styled(Box)(({theme}) => ({
    backgroundColor: "rgba(223,231,238,0.5)",
    padding: theme.spacing(2, 2, 2, 2),
}));

const BodyCardContent = styled(Box)(({theme}) => ({
    padding: theme.spacing(2, 2, 2, 2),
}));

function Document(props: Props) {

    const history = useHistory();
    const {t} = useTranslation();

    const dispatch = useDispatch();
    let { documentId, page }: any = useParams();

    const phrase = getQueryParameterByName("q");

    const document = useSelector((state: RootState) => state.documents.document);
    const documentLoading = useSelector((state: RootState) => state.documents.documentLoading);
    const favList = useSelector((state: RootState) => state.favorites.favoriteDocuments);
    const searchText = useSelector((state: RootState) => state.search.searchText);
    const searchType = useSelector((state: RootState) => state.search.searchType);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [showAmendments, setShowAmendments] = useState(false);
    const [showReferences, setShowReferences] = useState(false);

    const [currentSearchText, setCurrentSearchText] = useState(searchText);

    const handleInputChange = (value: string) => {
        setCurrentSearchText(value);
    };

    const handleEnterPress = (value: string) => {
        setCurrentSearchText(value);
        console.log(value);
        handleSearch(value);
    }

    const handleSearch = (value?: any) => {
        const phrase = value || currentSearchText;
        const params = new URLSearchParams();

        if(phrase){
            params.append("q", phrase);
        }

        dispatch(setSearchText(phrase));
        history.push({search: "?" + params.toString()});
    }

    useEffect(() => {
        const phrase = getQueryParameterByName("q");
        if(phrase) {
            dispatch(setSearchText(phrase));
            setCurrentSearchText(phrase);

            const params = new URLSearchParams();
            params.append("q", phrase);
            history.push({search: "?" + params.toString()});
        }
    }, []);

    useEffect(() => {
        const payload = {
            id: decodeURIComponent(documentId),
            page: page
        }
        dispatch(setDocumentLoading(true));
        if(phrase || page === "0") {
            dispatch(searchWithinDocument(searchText, payload));
        } else {
            dispatch(getDocumentById(payload));
        }
    }, [searchText, page]);

    // const isFavorited = () => {
    //     const itemExists = favList.data.filter((item: any) => item.documentId === document.id && item.page === "0");
    //     if (itemExists.length > 0) {
    //         return itemExists[0].id;
    //     }else{
    //         return false;
    //     }
    // }

    // const onDeleteFavDocument = async(id: any) => {
    //     if(!await confirm(null, {title: "Dëshironi ta fshini dokumentin e ruajtur?", cancelButton: "Jo"})) {
    //         return;
    //     }
    //     dispatch(deleteFavDocument(id));
    // }

    const handleClickFavorite = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFavorite = () => {
        setAnchorEl(null);
    };

    const parseReference = (amendment: string) => {
        if(amendment === 'Akt nënligjor') {
            return amendment + " i";
        }
        if(amendment === 'Bazë ligjore') {
            return amendment + " për";
        }
        return amendment;
    }

    return (
        <Box>

            <Link underline="none" onClick={() => history.goBack()}
                sx={{ display: "flex", alignItems: "center", mt: -1, mb: 3, cursor: 'pointer' }}>
                <ArrowBackIcon sx={{ mr: 2 }} />
                <Typography variant="subtitle1" color="text.link" sx={{ fontSize: "1.2rem" }}>
                    Kthehu
                </Typography>
            </Link>

            <Box
                sx={{
                    display: 'flex', alignItems: 'center', height: 50, mb: 3,
                    '@media (max-width:756px)': {
                        flexDirection: "column",
                        height: "auto"
                    }
                }}>

                <SearchInput
                    // showTerminology={currentTab === 0}
                    hideSuggestions
                    label={t("Kërko brenda dokumentit")}
                    value={currentSearchText}
                    onChange={handleInputChange}
                    onEnterPress={handleEnterPress}/>

                {/*<Button*/}
                {/*    size="large"*/}
                {/*    variant="contained"*/}
                {/*    sx={{*/}
                {/*        borderRadius: ".8rem",*/}
                {/*        ml: 2,*/}
                {/*        height: "100%",*/}
                {/*        width: "8rem",*/}
                {/*        '@media (max-width:756px)': {*/}
                {/*            width: "100%",*/}
                {/*            mt: 2,*/}
                {/*            ml: 0*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    onClick={() => handleSearch()}>*/}
                {/*    {t("Kërko")}*/}
                {/*</Button>*/}
            </Box>
            {(!documentLoading && document && favList) &&
                <Card sx={{borderTopRightRadius: '10px', borderTopLeftRadius: '10px', paddingBottom: '30px'}}>

                    <MainCardContent>
                        <div className={styles.DocumentTitleWrapper}>
                            <Typography variant="h5" color="text.secondary">
                                {document.documentName}
                            </Typography>
                            {favList && favList.data && (
                                // isFavorited() ? (
                                //     <Tooltip title="Fshije dokumentin" placement="right" arrow>
                                //         <div>
                                //             <IconButton color="primary" onClick={() => onDeleteFavDocument(isFavorited())}>
                                //                 <BookmarkIcon />
                                //             </IconButton>
                                //         </div>
                                //     </Tooltip>
                                // ) : (
                                <Tooltip title="Ruaje dokumentin" placement="right" arrow>
                                    <div>
                                        <IconButton
                                            onClick={handleClickFavorite}
                                        >
                                            <BookmarkBorderIcon />
                                        </IconButton>
                                        <FavoriteModal 
                                            show={anchorEl} 
                                            handleClose={handleCloseFavorite} 
                                            typeOfDocument="LAW"
                                            page="0"
                                            document={document}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                        <div className={styles.DocumentDescriptionWrapper}>
                            <LabeledValue type="normal" label="Lloji i dokumentit" value={document.documentType} />
                            <LabeledValue type="normal" label="Institucioni" value={document.institution} />
                            <LabeledValue type="date" label="Data e publikimit" value={document.publicationDate} />
                            <LabeledValue type="normal" label="Numri i aktit" value={document.actNumber} />
                            <LabeledValue type="status" label="Statusi" value={document.documentStatus} />
                            <LabeledValue type="link" label="Linku zyrtar" value={document.officialLink} />
                        </div>
                    </MainCardContent>

                    <BodyCardContent>
                        {document.documentAmendments && document.documentAmendments.length > 0 && (
                            <>
                                <div className={styles.AmendmentsReferencesWrapper} style={showAmendments ? { borderEndEndRadius: '0px', borderEndStartRadius: '0px' } : {}} onClick={() => setShowAmendments(!showAmendments)}>
                                    <Typography style={{ fontWeight: 'bold', fontSize: '0.9rem' }} variant="overline" color="text.secondary">
                                        Amandamentet ({document.documentAmendments.length})
                                    </Typography>
                                    {showAmendments ? <ArrowUp /> : <ArrowDown /> }
                                </div>
                                {showAmendments && (
                                    <div className={styles.AmendmentsReferencesBox}>
                                        {document.documentAmendments?.length ?
                                            document?.documentAmendments.map((amendment: any) => (
                                                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                                    {amendment.data.type}: &nbsp;&nbsp;
                                                    <Link href={`/documents/${amendment.data.id}/${1}`}>
                                                        {amendment.data.documentName}
                                                    </Link>
                                                </Typography>
                                            )) : <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                                Nuk ka amandamente
                                            </Typography>}
                                    </div>
                                )}
                            </>
                        )}

                        <br></br>

                        {document.references && document.references.length > 0 && (
                            <>
                                <div className={styles.AmendmentsReferencesWrapper} style={showReferences ? { borderEndEndRadius: '0px', borderEndStartRadius: '0px' } : {}} onClick={() => setShowReferences(!showReferences)}>
                                    <Typography style={{ fontWeight: 'bold', fontSize: '0.9rem' }} variant="overline" color="text.secondary">
                                        Referencat ({document.references.length})
                                    </Typography>
                                    {showReferences ? <ArrowUp /> : <ArrowDown /> }
                                </div>
                                {showReferences && (
                                    <div className={styles.AmendmentsReferencesBox}>
                                        {document.references?.length ?
                                            document?.references.map((reference: any) => (
                                                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                                    {parseReference(reference.data.type)}: &nbsp;&nbsp;
                                                    <Link href={`/documents/${reference.data.id}/${1}`}>
                                                        {reference.data.documentName}
                                                    </Link>
                                                </Typography>
                                            )) : <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                                Nuk ka referenca
                                            </Typography>}
                                    </div>
                                )}
                            </>
                        )}

                        {document.keywordNotFound && searchText &&
                            <Typography variant="h6" color="text.secondary" sx={{ mt: 5 }}>
                                Kërkimi juaj - <b>{searchText}</b> - nuk u gjet në këtë dokument.
                            </Typography>}

                            {!!document.pageContent && !document.keywordNotFound &&
                            <Typography variant="body1" color="text.secondary" gutterBottom sx={{ mt: 5 }}>
                                <span dangerouslySetInnerHTML={{__html: removePages(parsePageContent(document.pageContent, phrase))}}/>
                            </Typography>}
                    </BodyCardContent>

                    {!!document.highlightedTexts &&
                    document.highlightedTexts.map((text: any, index: number) => {
                        return <PageWithDescription document={document}  page={text.page} text={text.highLightedText} />
                    })}

                </Card>}

            {documentLoading && "Loading"}

        </Box>
    );
}

export default Document;
