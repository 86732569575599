import API from "../../utils/api";
import {localStorageAccessTokenKey} from "../../utils/constants";

export const requestPasswordResetLink = (payload: any) => async(dispatch: any) => {
    try {
        const response = await API.post("out/request-reset-password", payload);
        return response;
    } catch (err) {
        return err;
    }
};

export const resetPassword = (payload: any) => async(dispatch: any) => {
    try {
        const response = await API.post("out/reset-password", payload);
        return response;
    } catch (err) {
        return err;
    }
};

export const changePassword = (payload: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.post("reset-password", payload, {
            headers: {"Authorization": "Bearer " + token}
        });
        return response;
    } catch (err) {
        throw err;
    }
};
