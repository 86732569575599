import * as React from 'react';
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import {Button, Alert, IconButton, Link, Typography} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface Props {
    page: number;
    count: number;
    onChange: any;
    more: boolean;
    disablePreviousButton?: boolean;
    disableNextButton?: boolean;
}

const Nav = styled('nav')({
    display: 'flex',
    justifyContent: 'center',
    marginTop: "3rem"
});

const List = styled('ul')({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center'
});

export default function Pagination(props: Props) {
    const { items } = usePagination({
        page: props.page,
        count: props.count,
        onChange: props.onChange
    });

    const buttonCustomization = { minWidth: "32px", height: "32px", borderRadius: "16px", mx: "3px", fontSize: "1rem", padding: "0 6px", fontWeight: "700" };

    return (
        <>
            <Nav>
                <List>
                    {items.map(({ page, type, selected, ...item }, index) => {
                        let children = null;

                        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                            children = '…';
                        } else if (type === 'page') {
                            children = (
                                <Button
                                    {...item}
                                    variant={selected ? "contained" : "text"}
                                    color={selected ? "primary" : "secondary"}
                                    sx={buttonCustomization}>
                                    {page}
                                </Button>
                            );
                        } else {
                            const isPreviousButtonDisabled = (type === "previous") && (((props.page - 1) < 1) || props.disablePreviousButton);
                            const isNextButtonDisabled = (type === "next") && ((props.page + 1 > 10) || ((props.page == props.count) && !props.more) ||props.disableNextButton);
                            children = (
                                <React.Fragment>
                                    {(type === "next" && props.more) &&
                                    <Link href="#" underline="none" sx={{ pointerEvents: "none" }}>
                                        ...
                                    </Link>}
                                <Button
                                    {...item}
                                    size="small"
                                    disabled={isPreviousButtonDisabled || isNextButtonDisabled}
                                    sx={buttonCustomization}>
                                    {type === "previous" && <KeyboardArrowLeftIcon />}
                                    {type === "next" && <KeyboardArrowRightIcon />}
                                </Button>
                                </React.Fragment>
                            );
                        }

                        return <li key={index}>{children}</li>;
                    })}
                </List>
            </Nav>
            {props.page === 10 && (
                <Alert style={{ marginTop: '10px' }} severity="info">
                    Nga kërkimi juaj, kemi mundur të shfaqim rezultatet që shihni. Për rezultate të tjera kërkoni me terma të përafërt nga ato që kërkuat.
                </Alert>
            )}
        </>
    );
}
