import React from 'react';
import {CircularProgress, styled} from "@mui/material";

interface Props {
}

const ProgressContainer = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const ProgressSpinner = (props: Props) => {
    return (
        <ProgressContainer>
            <CircularProgress sx={{ mx: "auto", my: "2rem" }} />
        </ProgressContainer>
    );
}

export default ProgressSpinner;
