import moment from "moment";
import {suggestion} from "./levenshtein";

export const getQueryParameterByName = (name: string, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const convertObjectToFormData = (object: any) => {
    const formData = new FormData();
    for (let key in object) {
        if(object.hasOwnProperty(key) && object[key]) {
            formData.append(key, object[key]);
        }
    }
    return formData;
}

export const parsePageContent = (content: any, phrase: any) => {
    let parsedContent = content.replaceAll("\n", "<br/>").replaceAll("~Neni", "<br /> Neni");
    if(phrase) {
        let regex = new RegExp(phrase, "ig");
        parsedContent = parsedContent.replaceAll(regex, "<b>"+ phrase +"</b>");
    }
    return parsedContent;
}

export const removePages = (text: any) => {
    // return text;
    let regex = new RegExp(/\[page].*?\[\/page]/, "gm");
    return text.replaceAll(regex, "");
}

export const parseJwt = (token: any) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const parseFiltersFromBackend = (filters: any) => {
    if(!filters?.length) {
        return [];
    }

    return filters.reduce((acc: any, curr: any) => {
        const index = acc.findIndex((filter: any) => filter?.name === curr?.name);
        if(curr?.value) {
            if(index !== -1) {
                acc[index].values = [
                    ...acc[index].values,
                    { label: curr.value, value: curr.value }
                ];
            } else {
                acc.push({ ...curr,
                    values: [
                        // { label: "Të gjitha", value: "" },
                        { label: curr.value, value: curr.value },
                    ] });
            }
            delete acc.value;
        }
        return acc;
    }, []);
}

export function countInArray(array: any[], what: any) {
    return array.filter(item => item === what).length;
}

export const objectKeysToArray = (obj: any) => {
    return Object.keys(obj);
}

export const objectValuesToArray = (obj: any) => {
    return Object.values(obj).filter(value => value);
}

export const filterDocumentNames = (names: any, filters: any) => {
    return names.filter((name: any) => {
        return filters.every((filter: any) => name.filters.includes(filter) || !filters.length);
    });
}

export const arrayToSuggestions = (array: any, key?: any) => {
    return array.map((suggestion: any) => ({
        text: key ? suggestion[key] : suggestion,
        possibility: suggestion.possibility !== undefined ? suggestion.possibility : 0
    }));
}

export const filterSuggestionsStartsWith = (suggestions: any, value: any) => {
    return suggestions.filter((suggestion: any) => suggestion.text?.toLowerCase().startsWith(value));
}

export const filterSuggestionsByWords = (suggestions: any, value: any) => {
    return suggestions.filter((suggestion: any) => {
        return value.toLowerCase().split(" ").every((word: any) => suggestion.text?.toLowerCase().includes(word));
    });
}

export const suggestionsToArray = (suggestions: any) => {
    return suggestions.map((suggestion: any) => suggestion.text);
}

export const differenceInDays = (d1: any, d2: any) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(d1).getTime();
    const secondDate = new Date(d2).getTime();

    return Math.round(Math.abs((firstDate - secondDate) / oneDay));
}
