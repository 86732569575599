import React, { useState } from 'react';
import {Box, Typography, styled, IconButton, Tooltip} from "@mui/material";
import { useDispatch } from 'react-redux';
import {removePages} from "../../utils";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootState';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { deleteFavDocument } from '../../redux/actions/favorites';
import FavoriteModal from '../FavoriteModal';
import { confirm } from '../ConfirmDialog/confirm';

type PageWithDescriptionProps = {
    document?: any;
    page: number;
    text: string;
    onSaveSearch?: () => void;
};

const Text = styled(Typography)(({theme}) => ({
    padding: theme.spacing(0, 3, 0, 3)
}));

export default function PageWithDescription({ document, page, text, onSaveSearch }: PageWithDescriptionProps) {
    const history = useHistory();
    const dispatch: any = useDispatch();
    const favList = useSelector((state: RootState) => state.favorites.favoriteDocuments);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickFavorite = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFavorite = () => {
        setAnchorEl(null);
    };

    const goToPageOfDocument = () => {
        if(document.id) {
            history.push(`/documents/${document.id}/${page}`);
            if(onSaveSearch){
                onSaveSearch();
            }
        }
    }

    // const isFavorited = () => {
    //     const itemExists = favList.data.filter((item: any) => item.documentId === document.id && item.page === page);
    //     if (itemExists.length > 0) {
    //         return itemExists[0].id;
    //     }else{
    //         return false;
    //     }
    // }

    // const onDeleteFavDocument = async(id: any) => {
    //     if(!await confirm(null, {title: "Dëshironi ta fshini dokumentin e ruajtur?", cancelButton: "Jo"})) {
    //         return;
    //     }
    //     dispatch(deleteFavDocument(id));
    // }

    const PageButton = {
        backgroundColor: "#f5f5f5",
        color: "#1976d2",
        fontSize: '14px',
        borderLeft: '2px solid #1976d2',
        paddingLeft: '5px',
        paddingRight: '5px',
        width: 'fit-content',
        cursor: document ? 'pointer' : '',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        display: 'flex',
        alignItems: 'center',
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Box>
                <Box sx={PageButton}>
                    <div onClick={goToPageOfDocument}>Faqe {page}</div>
                    {document && favList && favList.data && (
                        // isFavorited() ? (
                        //     <Tooltip title="Fshije faqen" placement="right" arrow>
                        //         <div>
                        //             <IconButton size='small' sx={{ marginLeft: '5px' }} onClick={() => onDeleteFavDocument(isFavorited())}>
                        //                 <BookmarkIcon color="primary" />
                        //             </IconButton>
                        //         </div>
                        //     </Tooltip>
                        // ) : (
                        <Tooltip title="Ruaje faqen" placement="right" arrow>
                            <div>
                                <IconButton sx={{ marginLeft: '5px' }} size='small' onClick={handleClickFavorite}>
                                    <BookmarkBorderIcon />
                                </IconButton>
                                <FavoriteModal 
                                    show={anchorEl} 
                                    handleClose={handleCloseFavorite} 
                                    typeOfDocument="LAW"
                                    page={page.toString()}
                                    document={document}
                                />
                            </div>
                        </Tooltip>
                    )}
                </Box>
            </Box>
            <Text>
                <Typography variant="body2" sx={{ mt: 2, fontSize: '1rem' }} color="text.secondary">
                    <span dangerouslySetInnerHTML={{ __html: removePages(text) }}/>
                </Typography>
            </Text>
        </Box>
    );
};