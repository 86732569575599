import API from "../../utils/api";
import {localStorageAccessTokenKey} from "../../utils/constants";
import {AUTHENTICATED, EMAIL_VERIFIED} from "../constants";

export const sendVerificationEmail = () => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.post("verify/email/send", {},{
            headers: {"Authorization": "Bearer " + token}
        });
        return response;
    } catch (err) {
        throw err;
    }
};

export const verifyEmailVerificationCode = (payload: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.post("verify/email/check", payload,{
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: EMAIL_VERIFIED, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};
