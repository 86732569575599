import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {Box, styled, Typography} from "@mui/material";
import {getQueryParameterByName} from "../../utils";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reducers/rootState";
import moment from "moment";

interface Props {
    currentTab: number;
}

const NoResultsContainer = styled(Box)(({theme}) => ({
    display: "flex",
    marginTop: "2rem"
}));


const ResultsPlaceholder = (props: Props) => {
    const {t} = useTranslation();
    return (
        <NoResultsContainer>
            <SearchIcon color="disabled" sx={{ fontSize: "4rem" }} />
            <Typography variant="h6" color="gray" sx={{ ml: 1 }}>
                {t("Kërkoni ligjet dhe praktikën gjyqësore në sistemin ligjor")} <br/>
                {t("të Republikës së Kosovës.")} <br/>
            </Typography>

        </NoResultsContainer>
    );
}

export default ResultsPlaceholder;
