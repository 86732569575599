import React, {useState} from 'react';
import {Avatar, Box, Container, Grid, Link, TextField, Typography} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {requestPasswordResetLink} from "../../redux/actions/password";
import {useFormik} from "formik";
import * as yup from "yup";
import {toast} from "react-hot-toast";

interface Props {

}

const validationSchema = yup.object({
    email: yup.string().email('Enter a valid email').required("Email is required"),
});

function ForgotPassword(props: Props) {

    const dispatch: any = useDispatch();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: validationSchema,
        onSubmit: async(values) => {
            setLoading(true);
            await dispatch(requestPasswordResetLink(values));
            toast.success("Linku per ndërrimin e fjalëkalimit është dërguar, ju lutemi kontrolloni emailin tuaj! (Shiko në: Inbox ose Promotions)")
            history.push("/login");
        },
    });

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Ndërro Fjalëkalimin
                </Typography>
                <Box
                    sx={{
                        mt: 3,
                        ['@media (max-width:756px)']: {
                            maxWidth: "80vw",
                        }
                    }}>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            autoFocus
                            fullWidth
                            id="email"
                            name="email"
                            margin="normal"
                            label="Email adresa"
                            autoComplete="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email} />

                        <LoadingButton
                            loading={loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}>
                            Dërgo linkun në email
                        </LoadingButton>
                    </form>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/login" variant="body2">
                                Kyçu
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>

    );
}

export default ForgotPassword;
