import API from "../../utils/api";
import {FETCH_STATUS_LAWS, FETCH_STATUS_VERDICTS} from "../constants";
import {localStorageAccessTokenKey} from "../../utils/constants";

export const getLawsStatus = () => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("laws/status", {
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCH_STATUS_LAWS, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};

export const getVerdictsStatus = () => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("verdicts/status", {
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCH_STATUS_VERDICTS, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};
