import React, {useEffect} from 'react';
import './App.css';
import Layout from "./components/Layout";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import Login from "./containers/Login";
import Authenticated from "./components/Routes/Authenticated";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./redux/reducers/rootState";
import Search from "./containers/Search";
import Document from "./containers/Document";
import Verdict from "./containers/Verdict";
import {getKeywords} from "./redux/actions/keywords";
import Users from "./containers/Users";
import ForgotPassword from "./containers/ForgotPassword";
import ResetPassword from "./containers/ResetPassword";
import VerifyEmail from "./containers/VerifyEmail";
import Subscriptions from "./containers/Subscriptions";
import Profile from "./containers/Profile";
import Register from "./containers/Register";
import Landing from "./containers/Landing";
import FavoriteDocuments from './containers/FavoriteDocuments';
import {getForms} from "./redux/actions/forms";
import CountriesTab from './containers/Search/CountriesTab';
import {getVerdictsFilters} from "./redux/actions/verdictsFilters";
import {getDocumentsNames} from "./redux/actions/documentsNames";
import {getVerdictsNames} from './redux/actions/verdictsNames';
import {getDocumentsFilters} from "./redux/actions/documentsFilters";
import {getSavedSearches} from './redux/actions/search';
import {getMyFavDocuments} from './redux/actions/favorites';
import {authenticateViaSocial, checkSubscriptions, getUserDetails} from "./redux/actions/auth";
import {getJournals} from "./redux/actions/journals";
import {useGA4React} from "ga-4-react";
import {getQueryParameterByName} from "./utils";
import {getSubscriptions} from "./redux/actions/subscriptions";


function App() {

    const dispatch: any = useDispatch();
    const history = useHistory();
    const accessToken = useSelector((state: RootState) => state.auth.accessToken);
    const email = useSelector((state: RootState) => state.auth.email);
    const isVerified = useSelector((state: RootState) => state.auth.isVerified);
    const ga = useGA4React();

    useEffect(() => {
        const accessTokenQueryParam = getQueryParameterByName('access_token');
        if(accessTokenQueryParam) {
            dispatch(authenticateViaSocial(accessTokenQueryParam));
            history.push('/search');
        }
    }, []);

    useEffect(() => {
        if(accessToken) {
            dispatch(checkSubscriptions());
            dispatch(getSubscriptions());
            dispatch(getKeywords());
            dispatch(getDocumentsFilters({}));
            dispatch(getVerdictsFilters({}));
            dispatch(getDocumentsNames({}));
            dispatch(getVerdictsNames({}));
            // dispatch(getLawTerms());
            dispatch(getForms());
            dispatch(getJournals());
            dispatch(getUserDetails());
            dispatch(getSavedSearches());
            dispatch(getMyFavDocuments());
        }
    }, [accessToken]);

    useEffect(() => {
        if (ga && ga.pageview) {
            ga.pageview(history.location.pathname + history.location.search);
        }
    }, [ga, history.location.pathname, history.location.search]);

    useEffect(() => {
        if (ga && ga.pageview && email) {
            ga.event('page_view', 'Page: ' + history.location.pathname + history.location.search, 'User: ' + email);
        }
    }, [ga, history.location.pathname, history.location.search]);

    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/" component={Landing} />
                <Route path="/login" render={() => !!accessToken ? isVerified ? <Redirect to="/search"/> : <Redirect to="/email/verify"/> : <Login/>} />
                <Route path="/register" render={() => !!accessToken ? <Redirect to="/search"/> : <Register/>} />
                <Route path="/password/forgot" render={() => !!accessToken ? <Redirect to="/search"/> : <ForgotPassword/>} />
                <Route path="/password/reset" render={() => !!accessToken ? <Redirect to="/search"/> : <ResetPassword/>} />
                <Route path="/email/verify" render={() => <VerifyEmail/>} />
                <Route path="/qrcode" render={() => {
                    if (ga && ga.event) {
                        ga.event('visit', 'Visit via QR Code', '');
                    }
                        return <Redirect to="/"/>
                }} />

                <Layout>
                    <Authenticated authenticated={!!accessToken} verified={isVerified} path="/search" component={Search} />
                    <Authenticated authenticated={!!accessToken} verified={isVerified} path="/users" component={Users} />
                    <Authenticated authenticated={!!accessToken} verified={isVerified} path="/subscriptions" component={Subscriptions} />
                    <Authenticated authenticated={!!accessToken} verified={isVerified} path="/favorite-documents" component={FavoriteDocuments} />
                    <Authenticated authenticated={!!accessToken} verified={isVerified} path="/profile" component={Profile} />
                    <Authenticated authenticated={!!accessToken} verified={isVerified} path="/links" component={CountriesTab} />
                    <Authenticated authenticated={!!accessToken} verified={isVerified} path="/documents/:documentId/:page" component={Document} />
                    <Authenticated authenticated={!!accessToken} verified={isVerified} path="/verdicts/:verdictId" component={Verdict} />
                    <Authenticated authenticated={!!accessToken} verified={isVerified} path="/password/change" component={Document} />
                </Layout>
            </Switch>
            {/*<Route exact path="/" render={() => !!accessToken ? isVerified ? <Redirect to="/search" /> : <Redirect to="/email/verify" /> : <Redirect to="/login" /> }/>*/}
        </React.Fragment>
    );
}

export default App;
