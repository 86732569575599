import {Action} from "../actions/app";
import * as constants from "../constants";
import {parseFiltersFromBackend} from "../../utils";

export interface DocumentsFiltersState {
    appliedDocumentsFilters: any;
    allDocumentsFilters: Array<any>;
    documentsFilters: Array<any>;
    documentsFiltersLoading: boolean;
}

const getInitialState = () => {
    return {
        documentsFilters: [],
        documentsFiltersLoading: false,
        allDocumentsFilters: [],
        appliedDocumentsFilters: {}
    }
}

const documentsFilters = (state: DocumentsFiltersState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_DOCUMENTS_FILTERS: {
            const filters = parseFiltersFromBackend(action.payload);
            return {
                ...state,
                allDocumentsFilters: action.payload,
                documentsFilters: filters,
                documentsFiltersLoading: false,
                appliedDocumentsFilters: filters.reduce((a: any, v: any) => ({ ...a, [v.name]: "" }), {})
            };
        }

        case constants.SET_DOCUMENTS_FILTERS: {
            const filters = parseFiltersFromBackend(action.payload);
            return { ...state, documentsFilters: filters };
        }

        case constants.SET_DOCUMENTS_FILTERS_FROM_LATEST_SEARCH: {
            return {
                ...state,
                appliedDocumentsFilters: action.payload
            }
        }

        case constants.SET_DOCUMENTS_FILTER: {
            return {
                ...state,
                appliedDocumentsFilters: {
                    ...state.appliedDocumentsFilters,
                    [action.payload.name]: action.payload.value
                }
            };
        }

        case constants.SET_DOCUMENTS_FILTERS_LOADING: {
            return {...state, documentsFiltersLoading: action.payload};
        }

        case constants.CLEAR_DOCUMENTS_FILTERS: {
            return {
                ...state,
                appliedDocumentsFilters: state.documentsFilters.reduce((a: any, v: any) => ({ ...a, [v.name]: "" }), {})
            }
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        default: {
            return {...state};
        }

    }
}

export default documentsFilters;
