import React from 'react';
import {styled, Container, Box} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootState";

interface Props {
    children: any;
}

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const ContentPage = styled(Box)(({theme}) => ({
    paddingTop: theme.spacing(2),
    marginLeft: `-${theme.spacing(3)}`,
    marginRight: `-${theme.spacing(3)}`,
}));

const Content = (props: Props) => {
    const isDrawerOpen = useSelector((state: RootState) => state.app.isDrawerOpen);
    return (
        <Main open={isDrawerOpen}>
            <DrawerHeader />
            <Container maxWidth="md">
                <ContentPage>{props.children}</ContentPage>
            </Container>
        </Main>
    );
}

export default Content;
