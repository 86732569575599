import React from 'react';
import * as yup from 'yup';
import {useFormik} from "formik";
import {Switch, TextField} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootState";

interface Props {
    user: any;
    onSubmit: any;
}

const validationSchema = yup.object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email('Enter a valid email').required("Email is required"),
    active: yup.boolean()
});

const UpdateUserForm = (props: Props) => {

    const formik = useFormik({
        initialValues: {
            firstName: props.user.firstName,
            lastName: props.user.lastName,
            email: props.user.email,
            active: props.user.active
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            props.onSubmit(values);
        },
    });

    const usersLoading = useSelector((state: RootState) => state.users.usersLoading);

    return (
        <form onSubmit={formik.handleSubmit}>
            <TextField
                fullWidth
                label="First name"
                name="firstName"
                margin="normal"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName} />

            <TextField
                fullWidth
                label="Last name"
                name="lastName"
                margin="normal"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName} />

            <TextField
                disabled
                fullWidth
                label="Email"
                name="email"
                margin="normal"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email} />

            <Switch
                name="active"
                checked={!!formik.values.active}
                onChange={formik.handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />

            <LoadingButton
                fullWidth
                sx={{ mt: 2 }}
                type="submit"
                variant="contained"
                loading={usersLoading}>
                Update
            </LoadingButton>
        </form>
    );
}

export default UpdateUserForm;
