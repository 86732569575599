import app, {AppState} from "./app";
import {combineReducers} from "redux";
import auth, {AuthState} from "./auth";
import documents, {DocumentsState} from "./documents";
import verdicts, {VerdictsState} from "./verdicts";
import search, {SearchState} from "./search";
import keywords, {KeywordsState} from "./keywords";
import users, {UsersState} from "./users";
import subscriptions, {SubscriptionsState} from "./subscriptions";
import status, {StatusState} from "./status";
import lawTerms, {LawTermsState} from "./lawterms";
import forms, {FormsState} from "./forms";
import verdictsFilters, {VerdictsFiltersState} from "./verdictsFilters";
import documentsFilters, {DocumentsFiltersState} from "./documentsFilters";
import documentsNames, {DocumentsNamesState} from "./documentsNames";
import verdictsNames, {VerdictsNamesState} from "./verdictsNames";
import favorites, {FavoritesState} from "./favorites";
import journals, {JournalsState} from "./journals";
import plans, {PlansState} from "./plans";

export interface RootState {
    app: AppState;
    auth: AuthState;
    documents: DocumentsState;
    verdicts: VerdictsState;
    search: SearchState;
    keywords: KeywordsState;
    users: UsersState;
    subscriptions: SubscriptionsState;
    status: StatusState;
    lawTerms: LawTermsState;
    forms: FormsState;
    documentsFilters: DocumentsFiltersState;
    verdictsFilters: VerdictsFiltersState;
    documentsNames: DocumentsNamesState;
    verdictsNames: VerdictsNamesState;
    favorites: FavoritesState;
    journals: JournalsState;
    plans: PlansState;
}

export default combineReducers({
    app,
    auth,
    documents,
    verdicts,
    search,
    keywords,
    users,
    subscriptions,
    status,
    lawTerms,
    forms,
    verdictsFilters,
    documentsFilters,
    documentsNames,
    verdictsNames,
    favorites,
    journals,
    plans,
});
