import {SET_DRAWER, SET_LOADING, SET_RIGHT_DRAWER} from "../constants";

export interface Action {
    type: string;
    payload?: any;
}

export const setDrawer = (value: boolean): Action => ({
    type: SET_DRAWER,
    payload: value
})

export const setRightDrawer = (value: boolean): Action => ({
    type: SET_RIGHT_DRAWER,
    payload: value
})

export const setLoading = (value: any): Action => ({
    type: SET_LOADING,
    payload: value
});
