import React from 'react';
import { styled, Box, Typography, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from "react-router-dom";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { deleteFavDocument, getMyFavDocuments } from '../../redux/actions/favorites';
import styles from './FavoriteCard.module.scss';
import { confirm } from '../ConfirmDialog/confirm';

interface Props {
    document: any;
}

const MainCardContent = styled(Box)(({theme}) => ({
    backgroundColor: "rgba(223,231,238,0.5)",
    padding: theme.spacing(2, 2, 2, 2),
    borderRadius: '4px'
}));

function VerdictFavoriteCard({ document }: Props) {
    const dispatch: any = useDispatch();

    const onDeleteFavDocument = async() => {
        if(!await confirm(null, {title: "Dëshironi ta fshini dokumentin e ruajtur?", cancelButton: "Jo"})) {
            return;
        }

        dispatch(deleteFavDocument(document.id)).then((res: any) => {
            dispatch(getMyFavDocuments());
        })
    }

    return (
        <Box sx={{ mb: 1.5 }}>
            <Box>
                <MainCardContent>
                    <div className={styles.FavoriteCardTitleWrapper}>
                        <Typography variant="h6" color="text.secondary">
                            <RouterLink style={{textDecoration: 'none'}} to={document.type === "LAW" ? `/documents/${encodeURIComponent(document.documentId)}/${document.content.page}` : `/verdicts/${encodeURIComponent(document.documentId)}`}>
                                {document.content.documentName}
                            </RouterLink>
                        </Typography>
                        <IconButton color="primary" onClick={() => onDeleteFavDocument()}>
                            <BookmarkIcon />
                        </IconButton>
                    </div>
                    <div className={styles.FavoritedCardDescriptionsWrapper}>
                        <p>
                            <span>Praktikë Gjyqësore</span>
                        </p>
                        <p>
                            Lloji i dokumentit: <span>{document.content.documentType}</span>
                        </p>
                        <p>
                            Shënim: <span>{document.note}</span>
                        </p>
                    </div>
                </MainCardContent>
            </Box>
        </Box>
    );
}

export default VerdictFavoriteCard;