import React from 'react';
import {confirmable} from 'react-confirm';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const ConfirmDialog = ({show, proceed, confirmation, options}: any) => (
    <Dialog
        open={show}
        onClose={() => proceed(false)}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {options?.title ? options.title : (
                "Ju lutem konfirmo"
            )}
        </DialogTitle>
        {options?.descriptions && (
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {options?.descriptions}
                </DialogContentText>
            </DialogContent>
        )}
        <DialogActions>
            <Button onClick={() => proceed(false)}>{options?.cancelButton ? options.cancelButton : "Anulo" }</Button>
            <Button onClick={() => proceed(true)} autoFocus>{options?.approveButton ? options.approveButton : "Po" }</Button>
        </DialogActions>
    </Dialog>
)

export default confirmable(ConfirmDialog);
