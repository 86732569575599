import React from 'react';
import {Box, Card, CardContent, Container, Link, Typography} from "@mui/material";
import {comparativeLinks} from "../../../utils/constants";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useSelector} from "react-redux";
import FormCard from "../../../components/FormCard";
import {RootState} from "../../../redux/reducers/rootState";
import {baseUrl} from "../../../utils/api";

interface Props {
}

function JournalsTab(props: Props) {
    const journals = useSelector((state: RootState) => state.journals.journals);
    const accessToken = useSelector((state: RootState) => state.auth.accessToken);
    const handleDownload = (form: string) => {
        window.location.assign(baseUrl + "forms/folders/revistat/files?fileName=" + form + "&access_token=" + accessToken);
    }
    return (
        <>
            {
                journals.map(journal => {
                    return <FormCard form={journal} onDownloadClick={() => handleDownload(journal)} />
                })
            }
        </>
    );
}

export default JournalsTab;
