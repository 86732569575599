import {Action} from "../actions/app";
import * as constants from "../constants";
import {
    localStorageAccessTokenKey,
    localStorageEmailVerificationKey,
    localStorageRefreshTokenKey, localStorageUserEmail,
    localStorageUserFirstName,
    localStorageUserLastName
} from "../../utils/constants";
import {parseJwt} from "../../utils";

export interface AuthState {
    accessToken: null | string;
    refreshToken: null | string;
    isBusinessUser: boolean;
    isVerified: boolean;
    hasSubscriptions: null | boolean;
    firstName: null | string;
    lastName: null | string;
    email: null | string;
}

const getInitialState = () => {

    let isBusinessUser = false;
    let isVerified = false;
    let localStorageAccessToken = localStorage.getItem(localStorageAccessTokenKey) || null;
    if(localStorageAccessToken) {
        const parsedJwtContent = parseJwt(localStorageAccessToken);
        console.log(parsedJwtContent);
        if(parsedJwtContent?.authorities?.includes("ROLE_BUSINESS_ADMIN")) {
            isBusinessUser = true;
        }
    }

    let localStorageRefreshToken = localStorage.getItem(localStorageRefreshTokenKey) || null;
    let localStorageEmailVerification = localStorage.getItem(localStorageEmailVerificationKey);
    if(localStorageEmailVerification === "true") {
        isVerified = true;
    }

    let localStorageLoggedUserFirstName = localStorage.getItem(localStorageUserFirstName) || null;
    let localStorageLoggedUserLastName = localStorage.getItem(localStorageUserLastName) || null;
    let localStorageLoggedUserEmail = localStorage.getItem(localStorageUserEmail) || null;


    return {
        accessToken: localStorageAccessToken,
        refreshToken: localStorageRefreshToken,
        isBusinessUser: isBusinessUser,
        isVerified: true,
        hasSubscriptions: null,
        firstName: localStorageLoggedUserFirstName,
        lastName: localStorageLoggedUserLastName,
        email: localStorageLoggedUserEmail
    }
}

const auth = (state: AuthState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.AUTHENTICATED: {
            let isBusinessUser = false;
            let isVerified = true;
            localStorage.setItem(localStorageAccessTokenKey, action.payload.access_token);
            if(action.payload.refresh_token) {
                localStorage.setItem(localStorageRefreshTokenKey, action.payload.refresh_token);
            }
            const parsedJwtContent = parseJwt(action.payload.access_token);
            if(parsedJwtContent?.authorities?.includes("ROLE_BUSINESS_ADMIN")) {
                isBusinessUser = true;
            }
            if(action.payload.using_ip === false) {
                isVerified = false;
                localStorage.setItem(localStorageEmailVerificationKey, action.payload.using_ip);
            } else {
                localStorage.setItem(localStorageEmailVerificationKey, "true");
            }
            return {
                ...state,
                accessToken: action.payload.access_token,
                refreshToken: action.payload.refresh_token,
                isBusinessUser: isBusinessUser,
                isVerified: true
            }
        }

        case constants.USER_DETAILS: {
            localStorage.setItem(localStorageUserFirstName, action.payload.firstName);
            localStorage.setItem(localStorageUserLastName, action.payload.lastName);
            localStorage.setItem(localStorageUserEmail, action.payload.email);
            return {
                ...state, firstName: action.payload.firstName, lastName: action.payload.lastName,
                 email: action.payload.email
            }
        }

        case constants.LOGGED_OUT: {
            localStorage.removeItem(localStorageAccessTokenKey);
            localStorage.removeItem(localStorageRefreshTokenKey);
            localStorage.removeItem(localStorageEmailVerificationKey);
            localStorage.removeItem(localStorageUserFirstName);
            localStorage.removeItem(localStorageUserLastName);
            localStorage.removeItem(localStorageUserEmail);
            return {
                ...state,
                accessToken: null,
                refreshToken:  null,
                isVerified: true,
                isBusinessUser: false,
                firstName: null,
                lastName: null,
                email: null
            };
        }

        case constants.EMAIL_VERIFIED: {
            localStorage.setItem(localStorageEmailVerificationKey, "true");
            return { ...state, isVerified: true };
        }

        case constants.HAS_SUBSCRIPTIONS: {
            return { ...state, hasSubscriptions: action.payload }
        }

        default: {
            return {...state};
        }

    }
}

export default auth;
