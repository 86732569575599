import {Action} from "../actions/app";
import * as constants from "../constants";

export interface PlansState {
    plans: Array<any>
    plansLoading: boolean;
}

const getInitialState = () => {
    return {
        plans: [],
        plansLoading: false
    }
}

const plans = (state: PlansState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_PLANS: {
            return {...state, plans: action.payload, plansLoading: false};
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        default: {
            return {...state};
        }

    }
}

export default plans;
