import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Avatar,
    Box,
    Button,
    Divider,
    IconButton,
    Link,
    Menu,
    MenuItem,
    styled,
    SwipeableDrawer,
    Toolbar
} from "@mui/material";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import MuiAppBar from "@mui/material/AppBar";
import {RootState} from "../../../redux/reducers/rootState";
import {logout} from "../../../redux/actions/auth";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ChangePasswordDialog from "../../ChangePasswordDialog";
import MenuIcon from '@mui/icons-material/Menu';
import Logo from "../../../assets/images/logo-white.png";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    })
}));

const popupStylization = {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
    },
    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
    },
};

const Header = (props: AppBarProps) => {

    const dispatch: any = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const {isBusinessUser} = useSelector((state: RootState) => state.auth);
    const {firstName} = useSelector((state: RootState) => state.auth);
    const {lastName} = useSelector((state: RootState) => state.auth);


    const [changePasswordOpen, setChangePasswordOpen] = useState(false);

    const isMobile = window.innerWidth < 756;

    const handleLogout = async () => {

        dispatch(logout()).then((res: any) => {
            history.push("/");
        });
    }

    const goToProfile = () => history.push("/profile");
    const goToUsers = () => history.push("/users");
    const goToHome = () => history.push("/search");
    const goToSubscriptions = () => history.push("/subscriptions");
    const goToFavDocuments = () => history.push("/favorite-documents");

    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    const open = Boolean(anchorEl);

    const [openDesktopMenu, setOpenDesktopMenu] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [anchorElDesktop, setAnchorElDesktop] = React.useState<any>(null);

    const handleClickOpenDesktopMenu = (event: any) => {
        setOpenDesktopMenu(true);
        setAnchorElDesktop(event.currentTarget);
    };
    const handleCloseDesktopMenu = () => {
        setOpenDesktopMenu(false);
        setAnchorElDesktop(null);
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openDrawer = () => {
        setIsDrawerOpen(true);
    }

    const closeDrawer = () => {
        setIsDrawerOpen(false);
    }

    const renderMenu = () => {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                {!isMobile &&
                <>
                    <Button variant='text' color='info' onClick={goToHome}>
                        Kërko
                    </Button>
                        <Button variant='text' color='info' size='small' onClick={goToFavDocuments}>
                        Dokumentet e ruajtura
                        </Button>
                        <Button variant='text' color='info' size='small' onClick={goToSubscriptions}>
                        Abonimet
                        </Button>
                    {isBusinessUser &&
                        <Button variant='text' color='info' size='small' onClick={goToUsers}>
                        Pẽrdoruesit
                        </Button>}
                </>}
                <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ml: 2}}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}>
                        <Avatar sx={{
                            width: 32,
                            height: 32,
                            fontSize: '16px'
                        }}>{firstName?.substring(0, 1)}{lastName?.substring(0, 1)}</Avatar>
                    </IconButton>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: popupStylization,
                    }}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
                    <MenuItem onClick={goToProfile}>
                        <Avatar/> {firstName} {lastName}
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={handleLogout}>{t("Shkyçu")}</MenuItem>
                </Menu>
                {isMobile &&
                <IconButton
                    onClick={openDrawer}
                    edge="start">
                    <MenuIcon color='info' sx={{ ml: 0.8 }} />
                </IconButton>}
            </Box>
        );
    }

    return (
        <AppBar position="fixed">
            <Toolbar sx={{display: "flex", justifyContent: "space-between"}}>

                <Link href="/search" color="#fff" underline="none" sx={{height: "2.8rem"}}>
                    <img src={Logo} alt="logo" style={{height: "2.8rem"}}/>
                </Link>

                {renderMenu()}
            </Toolbar>


            <SwipeableDrawer
                anchor='right'
                open={isDrawerOpen}
                onClose={closeDrawer}
                onOpen={openDrawer}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', gap: '10px', p: 1 }}>
                    <Button variant='text' onClick={goToHome}>
                        Kërko
                    </Button>
                    <Button variant='text' onClick={goToFavDocuments}>
                        Dokumentet e ruajtura
                    </Button>
                    <Button variant='text' onClick={goToSubscriptions}>
                        Abonimet
                    </Button>
                    {isBusinessUser &&
                    <Button variant='text' onClick={goToUsers}>
                        Pẽrdoruesit
                    </Button>}
                </Box>
            </SwipeableDrawer>

            <ChangePasswordDialog
                open={changePasswordOpen}
                onClose={() => setChangePasswordOpen(false)}/>

        </AppBar>
    );
}

export default Header;
