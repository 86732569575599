import {Action} from "../actions/app";
import * as constants from "../constants";

export interface VerdictsNamesState {
    verdictsNames: Array<any>
    verdictsNamesLoading: boolean;
}

const getInitialState = () => {
    return {
        verdictsNames: [],
        verdictsNamesLoading: false,
    }
}

const verdictsNames = (state: VerdictsNamesState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_VERDICTS_NAMES: {
            const verdictsNames = action.payload.filter((verdictName: any) => verdictName.documentTitle);
            return {...state, verdictsNames: verdictsNames, verdictsNamesLoading: false};
        }

        case constants.SET_VERDICTS_NAMES_LOADING: {
            return {...state, verdictsNamesLoading: action.payload};
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        default: {
            return {...state};
        }

    }
}

export default verdictsNames;
