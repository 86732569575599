import API from "../../utils/api";
import {
    FETCHED_DOCUMENT,
    FETCHED_DOCUMENTS, FETCHED_DOCUMENTS_LAST_UPDATED, FETCHED_VERDICTS_LAST_UPDATED,
    SET_DOCUMENT_LOADING,
    SET_DOCUMENTS_LOADING, SET_DOCUMENTS_PAGE_SIZE,
} from "../constants";
import {Action} from "./app";
import {convertObjectToFormData} from "../../utils";
import {localStorageAccessTokenKey} from "../../utils/constants";
import {getLawsStatus} from "./status";

export const setDocumentsLoading = (value: boolean): Action => ({
    type: SET_DOCUMENTS_LOADING,
    payload: value
})

export const setDocumentLoading = (value: boolean): Action => ({
    type: SET_DOCUMENT_LOADING,
    payload: value
})

export const setDocumentsPageSize = (value: number): Action => ({
    type: SET_DOCUMENTS_PAGE_SIZE,
    payload: value
})

export const getDocuments = (payload: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("documents", {
            params: payload,
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCHED_DOCUMENTS, payload: response.data });
        dispatch(getLawsStatus());
        return response;
    } catch (err) {
        return err;
    }
};

export const getDocumentsWithFilters = (phrase: string, payload: any, maxResults: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    const formData = convertObjectToFormData(payload);
    try {
        const response = await API.post(`documents?phrase=${phrase}&maxResults=${maxResults}`, formData,{
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCHED_DOCUMENTS, payload: response.data });
        dispatch(getLawsStatus());
        return response;
    } catch (err) {
        return err;
    }
};

export const getDocumentById = (payload: any, keywordNotFound?: boolean) => async(dispatch: any, getState: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    if(keywordNotFound){
        payload.page = '';
    }
    try {
        const response = await API.get("documents",{
            params: payload,
            headers: {"Authorization": "Bearer " + token}
        });
        if(keywordNotFound) {
            dispatch({ type: FETCHED_DOCUMENT, payload: { ...response.data, keywordNotFound: true } });
        } else {
            dispatch({ type: FETCHED_DOCUMENT, payload: { ...response.data, keywordNotFound: false } });
        }
        return response;
    } catch (err) {
        return err;
    }
};

export const searchWithinDocument = (phrase: string, payload: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    const formData = convertObjectToFormData(payload);
    formData.delete("page");
    
    try {
        const response = await API.post(`documents?phrase=${phrase}&maxResults=1`, formData,{
            headers: {"Authorization": "Bearer " + token}
        });
        if(response.data.data?.[0]) {
            dispatch({ type: FETCHED_DOCUMENT, payload: response.data.data[0] });
        } else {
            dispatch(getDocumentById(payload, true));
        }
        return response;
    } catch (err) {
        return err;
    }
};

export const getDocumentsLastUpdated = () => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get(`/laws/last-updated`,{
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: FETCHED_DOCUMENTS_LAST_UPDATED, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};
