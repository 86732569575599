export const VERSION = "2.0.4";

export const localStorageAccessTokenKey = "lexdoks_access_token";
export const localStorageRefreshTokenKey = "lexdoks_refresh_token";
export const localStorageEmailVerificationKey = "lexdoks_email_verification";
export const localStorageUserFirstName = "lexdoks_logged_user_firstname";
export const localStorageUserLastName = "lexdoks_logged_user_lastname";
export const localStorageUserEmail = "lexdoks_logged_user_email";

export const paginationPageSize = 10;

export const plansMap: any = {
    INDIVIDUAL_MONTHLY: {
        name: "Qasje individuale mujore",
        duration: "1 muaj",
        description: "",
    },
    INDIVIDUAL_SEMIANNUAL: {
        name: "Qasje individuale 6 mujore",
        duration: "6 muaj",
        description: "Ju kurseni 10 euro",
    },
    INDIVIDUAL_YEARLY: {
        name: "Qasje individuale 12 mujore",
        duration: "12 muaj",
        description: "Ju kurseni 30 euro",
    },
}

export const SEARCH_PAGE_TABS = {
    DOCUMENTS: "documents",
    VERDICTS: "verdicts"
}

export const SearchTypes = {
    Phrase: "phrase",
    Name: "name"
}

export const professionOptions = [
    {label: "Gjyqtar", value: "Gjyqtar"},
    {label: "Prokuror", value: "Prokuror"},
    {label: "Avokat", value: "Avokat"},
    {label: "Jurist në sektorin privat", value: "Jurist në sektorin privat"},
    {label: "Jurist në sektorin publik", value: "Jurist në sektorin publik"},
    {label: "Tjetër", value: "Tjetër"},
];

export const courtFilters = [
    {
        label: "Të gjitha",
        value: "",
        children: []
    },
    {
        label: "Gjykata Kushtetuese",
        value: "Gjykata Kushtetuese",
        children: [
            { label: "Gjykata Kushtetuese", value: "Gjykata Kushtetuese" },
        ]
    },
    {
        label: "Gjykata Supreme",
        value: "Gjykata Supreme",
        children: [
            { label: "Dhoma e Posaçme", value: "Dhoma e Posaçme" },
            { label: "Gjykata Supreme", value: "Gjykata Supreme" },
            { label: "Kolegji i Apelit", value: "Kolegji i Apelit" },
        ]
    },
    {
        label: "Gjykata e Apelit",
        value: "Gjykata e Apelit",
        children: [
            { label: "Gjykata e Apelit", value: "Gjykata e Apelit" },
        ]
    },
    {
        label: "Gjykata Themelore e Prishtinës",
        value: "Gjykata Themelore e Prishtinës",
        children: [
            { label: "Dega në Podujevë", value: "Dega në Podujevë" },
            { label: "Gjykata Themelore në Prishtinë", value: "Gjykata Themelore në Prishtinë" },
            { label: "Dega në Lipjan", value: "Dega në Lipjan" },
            { label: "Dega në Graçanicë", value: "Dega në Graçanicë" },
        ]
    },
    {
        label: "Gjykata Themelore e Ferizajit",
        value: "Gjykata Themelore e Ferizajit",
        children: [
            { label: "Gjykata Themelore në Ferizaj", value: "Gjykata Themelore në Ferizaj" },
            { label: "Dega në Kaçanik", value: "Dega në Kaçanik" },
        ]
    },
    {
        label: "Gjykata Themelore e Mitrovicës",
        value: "Gjykata Themelore e Mitrovicës",
        children: [
            { label: "Gjykata Themelore në Mitrovicë", value: "Gjykata Themelore në Mitrovicë" },
            { label: "Dega në Skenderaj", value: "Dega në Skenderaj" },
            { label: "Dega në Vushtrri", value: "Dega në Vushtrri" },

        ]
    },
    {
        label: "Gjykata Themelore e Pejës",
        value: "Gjykata Themelore e Pejës",
        children: [
            { label: "Gjykata Themelore në Pejë", value: "Gjykata Themelore në Pejë" },
            { label: "Dega në Istog", value: "Dega në Istog" },
            { label: "Dega në Deçan", value: "Dega në Deçan" },
            { label: "Dega në Klinë", value: "Dega në Klinë" },
        ]
    },
    {
        label: "Gjykata Themelore e Gjilanit",
        value: "Gjykata Themelore e Gjilanit",
        children: [
            { label: "Gjykata Themelore në Gjilan", value: "Gjykata Themelore në Gjilan" },
            { label: "Dega në Viti", value: "Dega në Viti" },
            { label: "Dega në Novobërdë", value: "Dega në Novobërdë" },
            { label: "Dega në Kamenicë", value: "Dega në Kamenicë" },
        ]
    },
    {
        label: "Gjykata Themelore e Prizrenit",
        value: "Gjykata Themelore e Prizrenit",
        children: [
            { label: "Dega në Suharekë", value: "Dega në Suharekë" },
            { label: "Gjykata Themelore në Prizren", value: "Gjykata Themelore në Prizren" },
            { label: "Dega në Dragash", value: "Dega në Dragash" },
        ]
    },
    {
        label: "Gjykata Themelore e Gjakovës",
        value: "Gjykata Themelore e Gjakovës",
        children: [
            { label: "Gjykata Themelore në Gjakovë", value: "Gjykata Themelore në Gjakovë" },
            { label: "Dega në Rahovec", value: "Dega në Rahovec" },
            { label: "Dega në Malishevë", value: "Dega në Malishevë" },
        ]
    },
]

export const comparativeLinks = [
    {
        name: "EUR-Lex: Portali zyrtar ligjor i Bashkimit Evropian",
        primary: [
            { url: "https://eur-lex.europa.eu/homepage.html" },
        ],
        secondary: []
    },
    {
        name: "Belgjika",
        primary: [
            { url: "http://www.belgielex.be/en" },
        ],
        secondary: [
            { name: "JURA (private)", url: "http://www.jura.be", text: "(Kluwer)" },
            { name: "KMWEB (private)", url: "http://www.strada.be", text: "(Larcier, a division of Group De Boeck)" },
            { name: "STRAD@ (private)", url: "http://www.strada.be", text: "(Larcier, a division of Group De Boeck)" },
            { name: "Vlaamse Codex (public)", url: "http://www.codex.vlaanderen.be/", text: "(Flemish Government)" },
            { name: "vLex (private)", url: "http://vlex.be/libraries/legislation-moniteur-belge-137", text: "(vLex networks)" },
            { name: "Lex.be (private)", url: "https://lex.be/en/", text: "(data.be)" },
            { name: "Wallex (public)", url: "http://wallex.wallonie.be/", text: "(Walloon Government)" },
            { name: "Fisconet+ (public)", url: "https://financien.belgium.be/nl/E-services/fisconetplus", text: "(Federal Department of Finance)" },
        ],
    },
    {
        name: "Bullgaria",
        primary: [],
        secondary: [
            { name: "APIS", url: "http://www.apis.bg/", text: "(Apis Ltd)" },
            { name: "Bulgarian law portal", url: "http://www.lex.bg", text: "(Lex Bg Ltd)" },
            { name: "Ciela", url: "http://www.ciela.net/", text: "(Ciela Ltd)" },
        ],
    },
    {
        name: "Cekia",
        primary: [],
        secondary: [
            { name: "ASPI", url: "https://www.noveaspi.cz/", text: "(Wolters Kluwer ČR, a.s., a joint-stock company)" },
            { name: "Zákony pro lidi", url: "https://www.zakonyprolidi.cz/", text: "(AION CS, s.r.o., private joint-stock company)" },
            { name: "Beck Online", url: "https://www.beck-online.cz/", text: "(Nakladatelství C. H .Beck, s. r. o., a limited company)" },
            { name: "Codexis", url: "https://atlasconsulting.cz/software/codexis-online/", text: "(ATLAS CONSULTING spol. s r.o. a limited company)" },
            { name: "ÚZ — Úplné znění předpisů (UW — Unabridged wording of legislation)", url: "https://www.sagit.cz", text: "(Nakladatelství Sagit, a.s., private joint-stock company)" },
        ],
    },
    {
        name: "Gjermania",
        primary: [
            { url: "https://www.juris.de" },
        ],
        secondary: [
            { name: "Beck online", url: "https://beck-online.beck.de/", text: "(Verlag C. H. Beck oHG)" },
            { name: "Lexis-Nexis", url: "https://www.lexisnexis.de/", text: "(Lexis-Nexis Deutschland GmbH)" },
            { name: "Makrolog — Recht für Deutschland", url: "https://www1.recht.makrolog.de/", text: "(Recht für Deutschland GmbH)" }, //NB: This service also gives (fee-based) access to the historical legal gazettes (Reichsgesetzblatt 1871–1945; Gesetzblatt der DDR 1949–1990) as well as to the official gazettes of the regions.
        ],
    },
    {
        name: "Estonia",
        primary: [
            { url: "https://www.riigiteataja.ee/en/" },
        ],
        secondary: [],
    },
    {
        name: "Irlanda",
        primary: [
            { url: "https://www.bailii.org" },
            { url: "http://www.oireachtas.ie/" },
            { url: "http://www.irishstatutebook.ie/" },
        ],
        secondary: [],
    },
    {
        name: "Greqia",
        primary: [
            { url: "http://www.et.gr" },
        ],
        secondary: [
            { name: "NOMOS (private)", url: "http://lawdb.intrasoftnet.com/", text: "(Intrasoft International)" },
            { name: "Trapeza Nomikon Pliroforion (private)", url: "http://www.dsanet.gr/", text: "(Athens Bar Association)" },
        ],
    },
    {
        name: "Spanja",
        primary: [
            { url: "https://www.boe.es" },
        ],
        secondary: [
            { name: "Tribunal Constitucional (official)", url: "http://hj.tribunalconstitucional.es/", text: "Case law and other kind of decisions issued by the Constitutional Court" },
            { name: "Jurisprudencia (official)", url: "https://op.europa.eu/en/web/forum/www.poderjudicial.es/search/indexAN.jsp", text: "" },
            { name: "Private databases", url: "http://www.aranzadidigital.es/maf/app/authentication/signon?legacy", text: "" },
            { name: "Private databases", url: "https://online.elderecho.com/", text: "" },
            { name: "Private databases", url: "https://www.iustel.com/default.asp?", text: "" },
        ],
    },
    {
        name: "Franca",
        primary: [
            { url: "http://www.legifrance.gouv.fr/" },
        ],
        secondary: [],
    },
    {
        name: "Kroacia",
        primary: [
            { url: "https://narodne-novine.nn.hr/" },
        ],
        secondary: [],
    },
    {
        name: "Italia",
        primary: [
            { url: "https://www.gazzettaufficiale.it/" },
            { url: "http://www.normattiva.it/" },
        ],
        secondary: [
            { name: "Il foro italiano", url: "https://www.foroitaliano.it/", text: "(Il Foro Italiano)" },
            { name: "Infoleges", url: "http://www.infoleges.it/", text: "(infoLEGES)" },
            { name: "Jurisdata", url: "https://dejure.it/", text: "(Giuffré Francis Lefebvre)" },
            { name: "Leggi d’Italia", url: "http://www.leggiditaliaprofessionale.it/", text: "(Wolter Kluwers Group)" },
        ],
    },
    {
        name: "Qipro",
        primary: [
            { url: "https://www.leginetcy.com/" },
        ],
        secondary: [],
    },
    {
        name: "Latvia",
        primary: [
            { url: "https://www.latvija.lv/" },
            { url: "http://www.likumi.lv/" },
            { url: "http://www.mk.gov.lv/" },
            { url: "http://www.saeima.lv/" },
        ],
        secondary: [],
    },
    {
        name: "Lituania",
        primary: [
            { url: "https://e-seimas.lrs.lt/portal/documentSearch/lt/" },
            { url: "https://tar.e-tar.lt/" },
        ],
        secondary: [],
    },
    {
        name: "Luksemburgu",
        primary: [
            { url: "http://www.legilux.public.lu/" },
            { url: "http://data.legilux.public.lu/" },
        ],
        secondary: [],
    },
    {
        name: "Hungaria",
        primary: [
            { url: "https://www.njt.hu/" },
        ],
        secondary: [
            { name: "HVGORAC", url: "http://www.hvgorac.hu/", text: "(HVG-ORAC Lap- és Könyvkiadó Kft.)" },
            { name: "JOGI Fórum", url: "http://www.jogiforum.hu/", text: "(Jogászoknak Kft.)" },
            { name: "Magyar Hivatalos Jogszabálytár", url: "http://www.jogikonyvek.com/", text: "(Magyar Közlöny Lap- és Könyvkiadó)" },
            { name: "Opten Törvénytár", url: "http://www.opten.hu/", text: "(Opten Ltd)" },
        ],
    },
    {
        name: "Malta",
        primary: [
            { url: "http://www.justiceservices.gov.mt/LOM.aspx?pageid=27&mode=chrono/lom/home.asp" },
        ],
        secondary: [],
    },
    {
        name: "Holanda",
        primary: [
            { url: "https://wetten.overheid.nl/" },
        ],
        secondary: [
            { name: "OpMaat", url: "http://www.sneltotdekern.nl/", text: "(SDU Uitgevers BV)" },
            { name: "vLex", url: "http://www.nl.vlex.com/libraries/wetgeving-staatsblad-238", text: "(vLex networks)" },
            { name: "Legal Intelligence", url: "http://www.legalintelligence.com/", text: "" },
            { name: "Liigl", url: "http://www.liigl.nl/", text: "" },
        ],
    },
    {
        name: "Austria",
        primary: [
            { url: "https://www.ris.bka.gv.at/defaultEn.aspx" },
            { url: "http://www.lexisnexis.at/" },
            { url: "http://www.rdb.at/" },
            { url: "http://www.digitalegesetze.at/" },
            { url: "http://www.vlex.at/libraries/177" },
        ],
        secondary: [],
    },
    {
        name: "Polonia",
        primary: [
            { url: "https://legislacja.gov.pl/" },
            { url: "https://www.sejm.gov.pl/Sejm9.nsf/proces.xsp" },
        ],
        secondary: [
            { name: "Legalis", url: "https://www.legalis.pl/", text: "(Wydawnictwo C.H.Beck)" },
            { name: "Prawo.pl", url: "https://www.prawo.pl/akty/", text: "(Wolters Kluwer Polska Sp. z o.o.)" },
        ],
    },
    {
        name: "Portugalia",
        primary: [
            { url: "http://www.dre.pt/" },
        ],
        secondary: [
            { name: "GDL", url: "http://www.pgdlisboa.pt/leis/lei_main.php", text: "(Procuradoria-Geral Distrital de Lisboa/Attorney General's Office of the Lisbon District)" },
            { name: "IGFEJ", url: "http://www.gde.mj.pt/", text: "(Instituto de Gestão Financeira e Equipamentos da Justiça/Institute for Financial Management and Justice Equipment)" },
            { name: "CITIUS", url: "https://www.citius.mj.pt/portal/Artigos.aspx", text: "(Portal Citius do Ministério da Justiça/Citius Portal of the Ministry of Justice)" },
        ],
    },
    {
        name: "Rumania",
        primary: [
            { url: "http://www.monitoruloficial.ro/" },
        ],
        secondary: [
            { name: "CTCE PIATRA NEAMŢ", url: "http://www.ctce.ro/", text: "" },
            { name: "Indaco", url: "http://www.indaco.ro/", text: "" },
            { name: "LEX EXPERT", url: "http://www.lexexpert.ro/", text: "(Compania de Informatică Neamţ)" },
            { name: "Sintact", url: "http://www.sintact.ro", text: "(Wolters Kluwer srl)" },
        ],
    },
    {
        name: "Sllovenia",
        primary: [
            { url: "http://www.pisrs.si/" },
            { url: "https://www.rpls.si/" },
        ],
        secondary: [
            { name: "US SOFTWARE/IUS INFO", url: "http://www.ius-software.si/iusinfo.asp", text: "" },
            { name: "Tax-Fin-Lex", url: "http://www.tax-fin-lex.si/", text: "" },
        ],
    },
    {
        name: "Sllovakia",
        primary: [
            { url: "https://www.slov-lex.sk/" },
            { url: "http://wwwnoveaspi.sk/" },
        ],
        secondary: [],
    },
    {
        name: "Finlanda",
        primary: [
            { url: "http://www.finlex.fi/" },
            { url: "https://data.finlex.fi/" },
        ],
        secondary: [
            { name: "Edilex", url: "http://www.edilex.fi/", text: "(Edita Publishing Oy, Edita Ltd)" },
            { name: "Suomen laki", url: "http://www.suomenlaki.com/", text: "(Alma Talent Oy)" },
        ],
    },
    {
        name: "Suedia",
        primary: [
            { url: "http://www.lagrummet.se/" },
        ],
        secondary: [],
    },
    {
        name: "Islanda",
        primary: [
            { url: "http://www.rettarheimild.is/" },
            { url: "http://www.reglugerd.is/" },
            { url: "http://www.althingi.is/vefur/lagasafn.html" },
        ],
        secondary: [],
    },
    {
        name: "Lihtejnshtajni",
        primary: [
            { url: "https://www.gesetze.li/" },
            { url: "http://www.gerichtsentscheidungen.li/" },
        ],
        secondary: []
    },
    {
        name: "Norvegjia",
        primary: [
            { url: "http://www.lovdata.no/" },
        ],
        secondary: [
            { name: "Rettsdata", url: "http://www.rettsdata.no/", text: "(Gyldendal Norsk Forlag)" },
            { name: "Juridika", url: "http://www.juridika.no/", text: "" },
        ],
    },
    {
        name: "Zvicrra",
        primary: [
            { url: "https://www.fedlex.admin.ch/" },
            { url: "https://www.fedlex.admin.ch/en/home?news_period=last_day&news_pageNb=1&news_order=desc&news_itemsPerPage=10" },
        ],
        secondary: [
            { name: "LexFind", url: "http://www.lexfind.ch", text: "(Institute of Federalism — University of Fribourg)" },
            { name: "VLex", url: "http://vlex.ch/libraries/legislacion-suiza-158", text: "(vLex networks)" },
        ],
    },
    {
        name: "Shqipëria",
        primary: [
            { url: "" },
        ],
        secondary: [
            { name: "Ligjet", url: "https://qbz.gov.al/"},
            { name: "Jurisprudenca", url: "https://www.gjykataelarte.gov.al/"}
        ],
    },
    {
        name: "Turqia",
        primary: [
            { url: "http://www.mevzuat.gov.tr/" },
        ],
        secondary: [
            { name: "Archive of Resmî Gazete (Official Journal)", url: "http://www.resmigazete.gov.tr", text: "" },
            { name: "The Grand National Assembly of Turkey (TBMM)", url: "https://www.tbmm.gov.tr/kanunlarvekararlar.htm", text: "" },
            { name: "The Constitutional Court Decision Searching System", url: "http://kararlaryeni.anayasa.gov.tr", text: "" },
            { name: "Public Regulation System (KAYSIS)", url: "https://kms.kaysis.gov.tr/#dahafazla", text: "" },
        ],
    },
    {
        name: "Maqedonia",
        primary: [
            { url: "https://www.slvesnik.com.mk/home.nspx" },
        ],
        secondary: [],
    },
    {
        name: "Mali i Zi",
        primary: [
            { url: "http://www.sluzbenilist.me/" },
        ],
        secondary: [],
    },
    {
        name: "Serbia",
        primary: [
            { url: "http://www.pravno-informacioni-sistem.rs/slglrsNP-overview/all" },
        ],
        secondary: [],

    },
    {
        name: "Bosnja dhe Hercegovina",
        primary: [
            { url: "http://www.zakoni.ba/" },
            { url: "http://www.propisi.ba/" },
            { url: "http://www.glasila.ba/" },
        ],
        secondary: [],

    },
]
















