import React from 'react';
import {Button} from "@mui/material";
import {ReactComponent as GoogleLogo} from "../../assets/images/google-logo.svg";
import styles from "./LoginWithGoogle.module.scss";

export const baseUrl = process.env.REACT_APP_API_URL || "invalid";

interface Props {
    text: string;
}

const LoginWithGoogle = (props: Props) => {
    return (
        <Button
            fullWidth
            variant="outlined"
            color="primary"
            endIcon={<GoogleLogo width="28px" height="30px" />}
            href={`${baseUrl}oauth2/authorization/google`}
            className={styles.loginWithGoogleButton}>
            <span>{props.text}</span>
        </Button>
    );
}

export default LoginWithGoogle;
