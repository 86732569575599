import {Action} from "../actions/app";
import * as constants from "../constants";
import {parseFiltersFromBackend} from "../../utils";

export interface DocumentsNamesState {
    documentsNames: Array<any>
    documentsNamesLoading: boolean;
}

const getInitialState = () => {
    return {
        documentsNames: [],
        documentsNamesLoading: false,
    }
}

const documentsNames = (state: DocumentsNamesState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_DOCUMENTS_NAMES: {
            return {...state, documentsNames: action.payload, documentsNamesLoading: false};
        }

        case constants.SET_DOCUMENTS_NAMES_LOADING: {
            return {...state, documentsNamesLoading: action.payload};
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        default: {
            return {...state};
        }

    }
}

export default documentsNames;
