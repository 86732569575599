import React from 'react';
import {Paper, Skeleton} from "@mui/material";

interface Props {
}

function DocumentsSkeleton(props: Props) {

    return (
        <div>
            <Paper elevation={0} sx={{ background: "transparent", mb: 3 }}>
                <Skeleton variant="rectangular" width={500} height={32} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" width={150} height={20} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" height={60} sx={{ mb: 1 }} />
            </Paper>
            <Paper elevation={0} sx={{ background: "transparent", mb: 3 }}>
                <Skeleton variant="rectangular" width={500} height={32} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" width={150} height={20} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" height={60} sx={{ mb: 1 }} />
            </Paper>
            <Paper elevation={0} sx={{ background: "transparent", mb: 3 }}>
                <Skeleton variant="rectangular" width={500} height={32} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" width={150} height={20} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" height={60} sx={{ mb: 1 }} />
            </Paper>
            <Paper elevation={0} sx={{ background: "transparent", mb: 3 }}>
                <Skeleton variant="rectangular" width={500} height={32} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" width={150} height={20} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" height={60} sx={{ mb: 1 }} />
            </Paper>
        </div>
    );
}

export default DocumentsSkeleton;
