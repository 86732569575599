import {Action} from "../actions/app";
import * as constants from "../constants";

export interface JournalsState {
    journals: Array<any>
}

const getInitialState = () => {
    return {
        journals: [],
    }
}

const journals = (state: JournalsState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_JOURNALS: {
            return {...state, journals: action.payload};
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        default: {
            return {...state};
        }

    }
}

export default journals;
