import React, {useState} from 'react';
import {Avatar, Box, Container, Grid, Link, Paper, TextField, Typography} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useDispatch} from "react-redux";
import {authenticate, logout} from "../../redux/actions/auth";
import {useHistory} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {requestPasswordResetLink} from "../../redux/actions/password";
import {useFormik} from "formik";
import * as yup from "yup";
import {confirm} from "../../components/ConfirmDialog/confirm";
import {sendVerificationEmail, verifyEmailVerificationCode} from "../../redux/actions/email";
import {toast} from "react-hot-toast";

interface Props {

}

const validationSchema = yup.object({
    code: yup.string().required("Verification code is required"),
});

function VerifyEmail(props: Props) {

    const dispatch: any = useDispatch();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // console.log(formData.get("email"))
        // // await dispatch(authenticate(data));
    };

    const formik = useFormik({
        initialValues: {
            code: ""
        },
        validationSchema: validationSchema,
        onSubmit: async(values) => {
            setLoading(true);
            await dispatch(verifyEmailVerificationCode(values));
            toast.success("Email adresa juaj u konfirmua!")
            history.push("/search");
        },
    });

    const handleLogout = () => {
        dispatch(logout());
        history.push("/login");
    }

    const handleResend = () => {
        setLoading(true);
        dispatch(sendVerificationEmail())
            .then((res: any) => {
                toast.success("Kodi për verifikim është dërguar, ju lutemi kontrolloni emailin tuaj! (Shiko në: Inbox ose Promotions)");
                setLoading(false);
            })
            .catch((err: any) => setLoading(false));
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Kodi është derguar në email-in tuaj!
                </Typography>
                <Typography component="h1" variant="h6">
                    (Shiko në: Inbox ose Promotions)
                </Typography>
                <Box sx={{ mt: 3 }}>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            autoFocus
                            fullWidth
                            id="code"
                            name="code"
                            margin="normal"
                            label="Kodi i verifikimit"
                            autoComplete="email"
                            value={formik.values.code}
                            onChange={formik.handleChange}
                            error={formik.touched.code && Boolean(formik.errors.code)}
                            helperText={formik.touched.code && formik.errors.code} />

                        <LoadingButton
                            loading={loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}>
                            Vazhdo
                        </LoadingButton>
                    </form>
                    <Grid container>
                        <Grid item xs>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={handleLogout}>
                                Shkyçu
                            </Link>
                        </Grid>
                        <Grid item xs sx={{ textAlign: "right" }}>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={handleResend}>
                                Dergo kodin përseri
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>

    );
}

export default VerifyEmail;
