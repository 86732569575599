import React, {useState} from 'react';
import {confirmable} from 'react-confirm';
import {Dialog, DialogContent, DialogTitle, IconButton, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useFormik} from "formik";
import {changePassword} from "../../redux/actions/password";
import * as yup from "yup";
import {useDispatch} from "react-redux";
import {toast} from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";

const validationSchema = yup.object({
    oldPassword: yup.string().required("Current password is required"),
    newPassword: yup.string().required("New password is required"),
    confirmNewPassword: yup.string().required("Password confirmation is required").oneOf([yup.ref('newPassword'), null], 'Passwords must match')
});

interface Props {
    open: boolean;
    onClose: any;
}

const ChangePasswordDialog = (props: Props) => {

    const dispatch: any = useDispatch();

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: ""
        },
        validationSchema: validationSchema,
        onSubmit: async(values: any) => {
            setLoading(true);
            await dispatch(changePassword(values))
                .then((res: any) => {
                    toast.success("Fjalëkalimi juaj u ndërrua!")
                    props.onClose();
                })
                .catch((err: any) => setLoading(false));
        },
    });
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                Ndërro fjalëkalimin
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        autoFocus
                        fullWidth
                        margin="normal"
                        id="oldPassword"
                        label="Fjalëkalimi i vjetër"
                        name="oldPassword"
                        type="password"
                        value={formik.values.oldPassword}
                        onChange={formik.handleChange}
                        error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                        helperText={formik.touched.oldPassword && formik.errors.oldPassword}/>

                    <TextField
                        fullWidth
                        margin="normal"
                        id="newPassword"
                        label="Fjalëkalimi i ri"
                        name="newPassword"
                        type="password"
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                        helperText={formik.touched.newPassword && formik.errors.newPassword}/>

                    <TextField
                        fullWidth
                        margin="normal"
                        id="confirmNewPassword"
                        label="Konfirmo fjalëkalimin e ri"
                        name="confirmNewPassword"
                        type="password"
                        value={formik.values.confirmNewPassword}
                        onChange={formik.handleChange}
                        error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                        helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}/>

                    <LoadingButton
                        loading={loading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}>
                        Ndërro fjalëkalimin
                    </LoadingButton>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default confirmable(ChangePasswordDialog);
