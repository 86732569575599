import {Action} from "../actions/app";
import * as constants from "../constants";

export interface KeywordsState {
    keywords: Array<any>
}

const getInitialState = () => {
    return {
        keywords: [],
    }
}

const keywords = (state: KeywordsState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_KEYWORDS: {
            const keywords = Object.keys(action.payload).map((keyword) => ({
                text: keyword,
                possibility: action.payload[keyword]
            }));
            return {...state, keywords: keywords };
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        default: {
            return {...state};
        }

    }
}

export default keywords;
