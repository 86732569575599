import {Action} from "../actions/app";
import * as constants from "../constants";

export interface FormsState {
    forms: Array<any>
}

const getInitialState = () => {
    return {
        forms: [],
    }
}

const lawTerms = (state: FormsState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_FORMS: {
            return {...state, forms: action.payload};
        }

        case constants.LOGGED_OUT: {
            return { ...getInitialState() };
        }

        default: {
            return {...state};
        }

    }
}

export default lawTerms;
