import API from "../../utils/api";
import {AUTHENTICATED, LOGGED_OUT, REFRESH_TOKEN, HAS_SUBSCRIPTIONS, USER_DETAILS} from "../constants";
import axios from "axios";
import {localStorageAccessTokenKey} from "../../utils/constants";

export const username = process.env.REACT_APP_AUTH_USERNAME || "invalid";
export const password = process.env.REACT_APP_AUTH_PASSWORD || "invalid";

export const authenticate = (data: any): any => async(dispatch: any) => {
    try {
        const response = await API.post("oauth/token", data, {
            headers: {"Content-Type": "application/x-www-form-urlencoded",},
            auth: {
                username: username,
                password: password
            }
        });
        dispatch({ type: AUTHENTICATED, payload: response.data });
        return response;
    } catch (err) {
        throw err;
    }
};

export const getUserDetails = () => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("users/me", {
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: USER_DETAILS, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};

export const register = (data: any, token: any) => async(dispatch: any) => {
    try {
        const response = await API.post("register", data, {
            headers: {"Authorization": "Bearer " + token}
        });
        return response;
    } catch (err) {
        throw err;
    }
};

export const authenticateClient = () => async(dispatch: any) => {
    try {
        const data = `grant_type=client_credentials`;
        const response = await API.post("oauth/token", data, {
            headers: {"Content-Type": "application/x-www-form-urlencoded",},
            auth: {
                username: username,
                password: password
            }
        });
        return response;
    } catch (err) {
        throw err;
    }
};

export const getIpAddress = (): any => async(dispatch: any) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + "out/ip-addresses");
        return response;
    } catch (err) {
        throw err;
    }
};

export const logout = (): any => async(dispatch: any) => {
    dispatch({ type: LOGGED_OUT, payload: null });
};

export const checkSubscriptions = () => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("subscriptions/active", {
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: HAS_SUBSCRIPTIONS, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};

export const authenticateViaSocial = (token: any): any => async(dispatch: any) => {
    const payload = { access_token: token, refresh_token: null, using_ip: null };
    dispatch({ type: AUTHENTICATED, payload: payload });
};
