import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/reducers/rootState";
import {
    useHistory,
    useParams
} from "react-router-dom";
import {Box, Button, Container, IconButton, Link, Paper, Typography, styled, Card, Tooltip} from "@mui/material";
import {getQueryParameterByName, parsePageContent} from "../../utils";
import SearchInput from "../../components/SearchInput";
import {setSearchText} from "../../redux/actions/search";
import {useTranslation} from "react-i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getVerdictWithFilters, setVerdictsLoading, getVerdictById } from '../../redux/actions/verdicts';
import { deleteFavDocument } from '../../redux/actions/favorites';
import LabeledValue from '../../components/shared-components/LabeledValue';
import PageWithDescription from '../../components/shared-components/PageWithDescription';
import styles from "./Verdict.module.scss";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import FavoriteModal from '../../components/FavoriteModal';
import { ReactComponent as ArrowDown } from "../../assets/images/icons/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/icons/arrow-up.svg";
import { confirm } from '../../components/ConfirmDialog/confirm';

interface Props {

}

const MainCardContent = styled(Box)(({theme}) => ({
    backgroundColor: "rgba(223,231,238,0.5)",
    padding: theme.spacing(2, 2, 2, 2),
}));

const BodyCardContent = styled(Box)(({theme}) => ({
    padding: theme.spacing(2, 2, 2, 2),
}));

function Verdict(props: Props) {
    const history = useHistory();
    const {t} = useTranslation();

    const dispatch = useDispatch();
    let { verdictId }: any = useParams();
    const phrase = getQueryParameterByName("q");

    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const verdict = useSelector((state: RootState) => state.verdicts.verdict);
    const verdictLoading = useSelector((state: RootState) => state.verdicts.verdictsLoading);
    const favList = useSelector((state: RootState) => state.favorites.favoriteDocuments);
    const searchText = useSelector((state: RootState) => state.search.searchText);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [currentSearchText, setCurrentSearchText] = useState(searchText);

    const handleInputChange = (value: string) => {
        setCurrentSearchText(value);
    };

    const handleEnterPress = (value: string) => {
        setCurrentSearchText(value);
        console.log(value);
        handleSearch(value);
    };

    const handleSearch = (value?: any) => {
        const phrase = value || currentSearchText;
        const params = new URLSearchParams();

        if(phrase){
            params.append("q", phrase);
        }

        dispatch(setSearchText(phrase));
        history.push({search: "?" + params.toString()});
    };

    useEffect(() => {
        const phrase = getQueryParameterByName("q");
        const payload = {
            id: decodeURIComponent(verdictId),
        }
        if(phrase) {
            dispatch(setSearchText(phrase));
            setCurrentSearchText(phrase);
            dispatch(setVerdictsLoading(true))
            dispatch(getVerdictWithFilters(phrase, payload, 1));
        }else{
            dispatch(getVerdictById(payload, 1));
        }
    }, [searchText]);

    const toggleShowMoreInfo = () => {
        setShowMoreInfo(!showMoreInfo);
    }

    // const isFavorited = () => {
    //     const itemExists = favList.data.filter((item: any) => item.documentId.toString() === verdict.id.toString());
    //     if (itemExists.length > 0) {
    //         return itemExists[0].id;
    //     }else{
    //         return false;
    //     }
    // }

    // const onDeleteFavDocument = async(id: any) => {
    //     if(!await confirm(null, {title: "Dëshironi ta fshini dokumentin e ruajtur?", cancelButton: "Jo"})) {
    //         return;
    //     }
    //     dispatch(deleteFavDocument(id));
    // }

    const handleClickFavorite = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFavorite = () => {
        setAnchorEl(null);
    };

    return (
        <Box>

            <Link underline="none" onClick={() => history.goBack()}
                sx={{ display: "flex", alignItems: "center", mt: -1, mb: 3, cursor: 'pointer' }}>
                <ArrowBackIcon sx={{ mr: 2 }} />
                <Typography variant="subtitle1" color="text.link" sx={{ fontSize: "1.2rem" }}>
                    Kthehu
                </Typography>
            </Link>

            <Box
                sx={{
                    display: 'flex', alignItems: 'center', height: 50, mb: 3,
                    '@media (max-width:756px)': {
                        flexDirection: "column",
                        height: "auto"
                    }
                }}>

                <SearchInput
                    // showTerminology={currentTab === 0}
                    hideSuggestions
                    label={t("Kërko brenda dokumentit")}
                    value={currentSearchText}
                    onChange={handleInputChange}
                    onEnterPress={handleEnterPress}/>

                {/*<Button*/}
                {/*    size="large"*/}
                {/*    variant="contained"*/}
                {/*    sx={{*/}
                {/*        borderRadius: ".8rem",*/}
                {/*        ml: 2,*/}
                {/*        height: "100%",*/}
                {/*        width: "8rem",*/}
                {/*        '@media (max-width:756px)': {*/}
                {/*            width: "100%",*/}
                {/*            mt: 2,*/}
                {/*            ml: 0*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    onClick={() => handleSearch()}>*/}
                {/*    {t("Kërko")}*/}
                {/*</Button>*/}
            </Box>

            {(!verdictLoading && verdict && favList) && 
                <Card sx={{borderTopRightRadius: '10px', borderTopLeftRadius: '10px', paddingBottom: '30px'}}>

                    <MainCardContent>
                        <div className={styles.VerdictTitleWrapper}>
                            <Typography variant="h5" color="text.secondary">
                                {verdict.documentName}
                            </Typography>
                            {favList && favList.data && (
                                // isFavorited() ? (
                                //     <Tooltip title="Fshije dokumentin" placement="right" arrow>
                                //         <div>
                                //             <IconButton color="primary" onClick={() => onDeleteFavDocument(isFavorited())}>
                                //                 <BookmarkIcon />
                                //             </IconButton>
                                //         </div>
                                //     </Tooltip>
                                // ) : (
                                <Tooltip title="Ruaje dokumentin" placement="right" arrow>
                                    <div>
                                        <IconButton
                                            onClick={handleClickFavorite}
                                        >
                                            <BookmarkBorderIcon />
                                        </IconButton>
                                        <FavoriteModal 
                                            show={anchorEl} 
                                            handleClose={handleCloseFavorite} 
                                            typeOfDocument="VERDICT"
                                            document={verdict}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                        <div className={styles.VerdictDescriptionWrapper}>
                            <LabeledValue type="normal" label="Lloji" value={verdict.documentType} />
                            <LabeledValue type="normal" label="Gjykata" value={verdict.court} />
                            <LabeledValue type="normal" label="Dega" value={verdict.branch} />
                            <LabeledValue type="normal" label="Numri i rastit" value={verdict.caseNumber} />
                            <LabeledValue type="normal" label="Gjyqtar" value={verdict.judge} />
                            <LabeledValue type="link" label="Linku zyrtar" value={verdict.officialLink} />
                        </div>
                        
                        {showMoreInfo && (
                            <div className={styles.VerdictDescriptionWrapper}>
                                <LabeledValue type="date" label="Data e publikimit" value={verdict.publicationDate} />
                                <LabeledValue type="normal" label="Procedura" value={verdict.procedure} />
                                <LabeledValue type="normal" label="Departamenti" value={verdict.department} />
                                <LabeledValue type="normal" label="Aplikuesi" value={verdict.applicant} />
                                <div></div>
                                <div></div>
                            </div>
                        )}
                        <div style={{display: 'flex', justifyContent: 'end', color: '#1976D2', fontSize:'12px', cursor: 'pointer'}}>
                            <p style={{margin: '0'}} onClick={toggleShowMoreInfo}>
                                {showMoreInfo ? (
                                    <>Mbyll <span><ArrowUp width="15px" /></span></>
                                ) : (
                                    <>Shfaq më shumë <span><ArrowDown width="15px" /></span></>
                                )}
                            </p>

                        </div>
                    </MainCardContent>

                    {verdict.highlightedTexts.length === 0 && searchText &&
                    <Typography variant="h6" color="text.secondary" sx={{ mt: 5, ml: 2 }}>
                        Kërkimi juaj - <b>{searchText}</b> - nuk u gjet në këtë dokument.
                    </Typography>}

                    {!!verdict.highlightedTexts &&
                    verdict.highlightedTexts.map((text: any, index: number) => {
                        return (
                            <PageWithDescription page={text.page} text={text.highLightedText} />
                        )
                    })}

                </Card>
            }

        </Box>
    );
};

export default Verdict;
