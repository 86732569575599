import {
    SET_MY_FAVORITE_DOCUMENTS
} from "../constants";
import API from "../../utils/api";
import { localStorageAccessTokenKey } from "../../utils/constants";


export const saveFavDocument = (payload: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.post("favorites", payload, {
            headers: {"Authorization": "Bearer " + token}
        });
        return response;
    } catch (err) {
        throw err;
    }
};

export const getMyFavDocuments = () => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.get("favorites", {
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch({ type: SET_MY_FAVORITE_DOCUMENTS, payload: response.data})
        return response;
    } catch (err) {
        throw err;
    }
};

export const deleteFavDocument = (id: any) => async(dispatch: any) => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    try {
        const response = await API.delete(`favorites/${id}`, {
            headers: {"Authorization": "Bearer " + token}
        });
        dispatch(getMyFavDocuments());
        return response;
    } catch (err) {
        throw err;
    }
};